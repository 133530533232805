import React from "react";
import { Link } from "react-router-dom";
import FooterWhite from "../components/footerwhite";
import loadjs from "loadjs";
import MobileNav from "../components/mobilenav";
import axios from "axios";
import HeaderBlack from "../components/headerblack";


const delete_url = "https://api.holoapp.tech/accounts/delete-account";
const login_url = "https://api.holoapp.tech/accounts/login";

class Delete extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            confirmPassword: '',
            token: '',
            showLoginModal: false,
            showDeleteModal: false,
        };
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { username, password, confirmPassword } = this.state;

        // Check if passwords match
        if (password !== confirmPassword) {
            alert("Passwords do not match");
            return;
        }

        // Make a POST request to the login API to get a token
        axios.post(login_url, {
            username,
            password,
        })
            .then((response) => {
                console.log("token", response.data.access);
                this.setState({ token: response.data.access, showLoginModal: true });
            })
            .catch((error) => {
                console.error('Login Error:', error);
                alert('Login failed. Please check your credentials.');
            });
    }

    handleDeleteAccount = () => {
        if (this.state.token === '') {
            alert('Token is missing. Please log in and try again.');
            return;
        }

        // Make a DELETE request to delete the account using the token
        axios.delete(delete_url, {
            headers: {
                Authorization: `Bearer ${this.state.token}`,
            },
        })
            .then((response) => {
                console.log("Account Deletion Response:", response.data.status);
                // Show account deletion confirmation modal
                this.setState({ showLoginModal: false, showDeleteModal: true });
            })
            .catch((error) => {
                console.error('Account Deletion Error:', error);
                alert('Account deletion failed. Please try again later.');
            });
    }

    componentDidMount() {
        loadjs("./js/plugins.js");
        loadjs("./js/main.js");
    }



    render() {
        const {
            username,
            password,
            confirmPassword,
            showLoginModal,
            showDeleteModal,
        } = this.state;
        return (
            <div>
                <HeaderBlack />
                <MobileNav />
                <div className="ltn__utilize-overlay" />

                {/* BREADCRUMB AREA START */}
                <div
                    className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
                    data-bg="img/bg/dhaka.jpeg"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                                    <div className="section-title-area ltn__section-title-2">
                                        <h6 className="section-subtitle ltn__secondary-color">
                                            Welcome to Holo
                                        </h6>
                                        <h1 className="section-title white-color">
                                            Delete Account
                                        </h1>
                                    </div>
                                    <div className="ltn__breadcrumb-list">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>Delete Account</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BREADCRUMB AREA END */}
                <div className="ltn__login-area pb-110">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title-area text-center">
                                    <h1 className="section-title">
                                        Delete Your Account
                                    </h1>
                                    <div className="section-title-area ltn__section-title-2 text-center">
                                        <br />
                                        <h3 className="section-subtitle">
                                            Procedure
                                        </h3>
                                        <div className="by-agree text-center">
                                            <p>1. Enter your HOLO username number and password</p>
                                            <p>2. Press the submit button</p>
                                            <span style={{ textAlign: 'right', fontSize: 12, padding: -30 }}>
                                                <strong>
                                                    Note: According to the Ride Sharing Regulations by the government of
                                                    Bangladesh, we are obligated to keep the records of your personal
                                                    information and ride histories.
                                                    <br />
                                                    Your account will no longer be active once you follow these steps,
                                                    but the past ride records will be accessible by Holo
                                                    and will be handed over to the government of Bangladesh in case of any
                                                    emergency.
                                                </strong>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 offset-lg-3">
                                <div className="account-login-inner">
                                    <form action="#" className="ltn__form-box contact-form-box" onSubmit={this.handleSubmit}>
                                        <input
                                            type="text"
                                            name="username"
                                            value={username}
                                            onChange={this.handleInputChange}
                                            placeholder="Enter your HOLO username"
                                        />
                                        <input placeholder="Password*"
                                            type="password"
                                            name="password"
                                            value={password}
                                            onChange={this.handleInputChange} />
                                        <input
                                            placeholder="Confirm Password*"
                                            type="password"
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={this.handleInputChange}
                                        />
                                        <div className="btn-wrapper">
                                            <button
                                                className="theme-btn-1 btn reverse-color btn-block"
                                                type="submit"
                                            >
                                                Delete
                                            </button>

                                        </div>
                                    </form>
                                    <div>{showLoginModal ? "meow" : ""}</div>
                                    {showLoginModal && (
                                        <div className="modal">
                                            <div className="modal-content">
                                                <span className="close" onClick={() => this.setState({ showLoginModal: false })}>
                                                    &times;
                                                </span>
                                                <h2>Are you sure you want to delete your account?</h2>
                                                <div className="btn-wrapper animated" style={{ textAlign: "center" }}>
                                                    <button
                                                        className="theme-btn-1 btn btn-effect-1"
                                                        type="submit"
                                                        onClick={this.handleDeleteAccount}
                                                    >
                                                        Yes
                                                    </button>
                                                    <button onClick={() => this.setState({ showLoginModal: false })} className="btn btn-transparent btn-effect-3">
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {showDeleteModal && (
                                        <div className="modal">
                                            <span className="close" onClick={() => this.setState({ showDeleteModal: false })}>
                                                &times;
                                            </span>
                                            <div className="modal-content" style={{ textAlign: "center" }}>
                                                Account Deleted Successfully
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterWhite />
            </div>
        );
    }
}

export default Delete;
