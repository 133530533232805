import React, { Component } from "react";
import FooterBlack from "../components/footerblack";
import HeaderBlack from "../components/headerblack";
import loadjs from "loadjs";
import MobileNav from "../components/mobilenav";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const position = [23.740566561527984, 90.37567758347049];

class Map extends Component {
  componentDidMount() {
    loadjs("./js/main.js"
    //     , function () {
    //   console.log("load main");
    // }
    );

    loadjs("./js/plugins.js"
    //     , function () {
    //   console.log("load plugin");
    // }
    );
  }
  render() {
    return (
      <>
        <HeaderBlack />
        <MobileNav />
        <div className="ltn__utilize-overlay" />

        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      {" "}
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">
                      Location
                    </h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <a href="/">Home</a>
                      </li>
                      <li>Locations</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <MapContainer
          center={position}
          zoom={16}
          // scrollWheelZoom={false}
          style={{ height: "600px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            <Popup>
              <strong>
                2nd floor, 52/1, Dhanmondi 3/A, <br />
                Dhaka, Bangladesh
              </strong>
            </Popup>
          </Marker>
        </MapContainer>

        <div className="ltn__google-map-locations-list-area mt-115 mb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12"/>
            </div>
          </div>
        </div>

        <FooterBlack />
      </>
    );
  }
}

export default Map;
