import React from "react";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import FooterBlack from "../components/footerblack";

class PrivacyBangla extends React.Component {
  componentDidMount() {
    loadjs("./js/main.js");
    loadjs("./js/plugins.js");
  }

  render() {
    return (
      <>
        <HeaderBlack />
        <MobileNav />
        <div className="ltn__utilize-overlay" />

        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">
                    Privacy Policy
                    </h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Privacy Policy</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}

        {/* PAGE DETAILS AREA START (blog-details) */}
        <Link
                              to="/privacy"
                              className="theme-btn-1 btn btn-effect-1"
                              style= {{ marginTop: "-10%",marginLeft: "10%" }}
                            >
                            English
        </Link>

        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__blog-details-wrap">
                  <div className="ltn__page-details-inner ltn__blog-details-inner">
                  <strong>সর্বশেষ হালনাগাদ: ফেব্রুয়ারি ২০, ২০২৩</strong><br/>
                  <ol>
                      <li>
                      <strong>ভূমিকা</strong>
                        <div>                        
                        এই গোপনীয়তা নীতিটি বর্ণনা করে কিভাবে holoapp.tech ওয়েবসাইট এবং সম্পর্কিত মোবাইল 
                        অ্যাপ্লিকেশন "Holo" এবং "Holo Chalok" ("সাইট", "আমরা" বা "আমাদের") ব্যক্তিগত 
                        তথ্য সংগ্রহ করে, ব্যবহার করে, শেয়ার করে এবং সুরক্ষা করে। এই সাইট বা অ্যাপ্লিকেশন. হোলো 
                        টেক লিঃ এই সিস্টেমটি প্রতিষ্ঠা করেছে যেন, যে ব্যবহারকারীদের কোথাও যেতে হবে ("গ্রাহক") 
                        এমন ব্যক্তিদের সাথে যারা রাইড-শেয়ারিং পরিষেবা ("ড্রাইভার") প্রদান করবে তাদের সাথে সংযুক্ত 
                        করতে। আমাদের তথ্য অনুশীলন সম্পর্কে আরও জানতে অনুগ্রহ করে নীচে পড়ুন। এই সাইট বা 
                        অ্যাপ ব্যবহার করে, আপনি এই অনুশীলনের সাথে সম্মত হন।
                        </div>
                      </li>
                      <li>
                      <strong>তথ্য সংগ্রহ প্রক্রিয়া</strong>
                        <div>
                        আমাদেরকে আপনার ব্যক্তিগত তথ্য যেমন আপনার নাম, যোগাযোগের নম্বর, মেইলিং ঠিকানা 
                        এবং ইমেল আইডি প্রদান করতে হবে, আপনাকে সেরা পরিষেবা দেওয়ার জন্য আমাদের অ্যাপটি 
                        আপনার অবস্থানের তথ্যও সংগ্রহ করবে। অন্যান্য অনেক ওয়েবসাইটের মতো, আমরা আপনার 
                        ওয়েব ব্রাউজার নিয়মিতভাবে শেয়ার করে এমন তথ্য রেকর্ড করতে পারি, যেমন আপনার 
                        ব্রাউজারের ধরন, ব্রাউজারের ভাষা, সফ্টওয়্যার এবং হার্ডওয়্যার বৈশিষ্ট্য, আপনার দেখার তারিখ 
                        এবং সময়, আপনি যে ওয়েব পৃষ্ঠা থেকে এসেছেন, আপনার ইন্টারনেট প্রোটোকল ঠিকানা এবং 
                        সেই ঠিকানার সাথে যুক্ত ভৌগলিক অবস্থান, এই সাইটের যে পৃষ্ঠাগুলি আপনি পরিদর্শন করেন 
                        এবং সেই পৃষ্ঠাগুলিতে আপনি যে সময় ব্যয় করেছেন। এটি সাধারণত বেনামী তথ্য হবে যা আমরা 
                        সামগ্রিক ভিত্তিতে সংগ্রহ করি। এই সাইটের ভিজিটরদের সম্পর্কে পরিসংখ্যানগত তথ্য সংগ্রহ করতে 
                        এবং তারা কীভাবে সাইটটি ব্যবহার করে তা জানতে আমরা Google Analytics বা অনুরূপ 
                        পরিষেবাও ব্যবহার করতে পারি। এটিও বেনামী ভিত্তিতে করা হয় । আমরা আপনার ব্যক্তিগতভাবে 
                        শনাক্তযোগ্য তথ্যের সাথে বেনামী তথ্য যুক্ত করার চেষ্টা করব না।
                        </div>
                      </li>
                      <li>
                      <strong>ব্যক্তিগত তথ্য</strong>
                        <div>
                        আপনি যদি আমাদের পরিষেবা ব্যবহার করতে চান বা হোলো টেক লিঃ এর সদস্যের সাথে 
                        যোগাযোগ করতে চান, তাহলে আপনাকে অবশ্যই আমাদের অ্যাপে একটি অ্যাকাউন্ট তৈরি 
                        করতে হবে। আপনার অ্যাকাউন্ট প্রতিষ্ঠা করতে, আমরা ব্যক্তিগতভাবে শনাক্তযোগ্য তথ্য চাইব 
                        যা আপনার সাথে যোগাযোগ বা সনাক্ত করতে ব্যবহার করা যেতে পারে, যার মধ্যে আপনার 
                        নাম, ফোন নম্বর এবং ই-মেইল ঠিকানা অন্তর্ভুক্ত থাকতে পারে। আমরা আপনার সম্পর্কে 
                        জনসংখ্যাতাত্ত্বিক তথ্যও সংগ্রহ করতে পারি এবং আপনাকে অতিরিক্ত তথ্য জমা দেওয়ার অনুমতি 
                        দিতে পারি যা আপনার হোলো টেক লিঃ প্রোফাইলের অংশ হবে। <br/>

                        আপনার অ্যাকাউন্ট প্রতিষ্ঠা করার জন্য আমাদের যে প্রাথমিক তথ্য প্রয়োজন, তা ছাড়া, আপনার 
                        প্রোফাইলের অংশ হিসাবে কত তথ্য আপনি দিতে চান তা আপনার উপর নির্ভর করবে। আমরা 
                        আপনাকে আপনার শেয়ার করা তথ্য সম্পর্কে সাবধানে চিন্তা করার জন্য উত্সাহিত করি এবং 
                        আমরা সুপারিশ করি যে আপনি আপনার পরিচয় এবং আপনার সংবেদনশীল তথ্য রক্ষা করুন৷ 
                        অবশ্যই, আপনি যেকোনো সময় আপনার প্রোফাইল পর্যালোচনা এবং সংশোধন করতে পারেন। <br/>

                        সময়ে সময়ে, আমরা প্রতিযোগিতা বা প্রচার চালাতে পারি এবং একটি পোস্টাল ঠিকানা এবং 
                        প্রতিযোগিতা বা প্রচার সম্পর্কিত অন্যান্য ব্যক্তিগত তথ্য চাইতে পারি। এই ইভেন্টগুলিতে অংশগ্রহণ 
                        করার জন্য আপনার ব্যক্তিগত তথ্য প্রদান করবেন কিনা তা সর্বদা আপনার উপর নির্ভর করবে।
                        </div>
                      </li>
                      <li>
                      <strong>পেমেন্ট তথ্য</strong>
                        <div>
                        আমাদের একাধিক পেমেন্ট সিস্টেম আছে, কিন্তু আপনি যদি একজন গ্রাহক হন, তাহলে আপনাকে 
                        সরাসরি ড্রাইভারকে ভাড়া দিতে হবে, আপনারা উভয়েই যে পদ্ধতি ব্যবহার করতে সম্মত হন না 
                        কেন। এবং আপনি যদি একজন নিবন্ধিত ড্রাইভার হয়ে থাকেন, তাহলে আপনি অ্যাপে ইন্টিগ্রেটেড 
                        পেমেন্ট সিস্টেমের মাধ্যমে আমাদের পেমেন্ট করতে পারেন।                         
                        </div>
                      </li>
                      <li>
                      <strong>সংগৃহীত তথ্যের ব্যবহার</strong>
                        <div>
                        আমরা সাধারণত যে তথ্য সংগ্রহ করি তা আমাদের পরিষেবা প্রদানের জন্য, আমাদের ওয়েবসাইটে 
                        দর্শকদের কার্যকলাপ নিরীক্ষণ ও বিশ্লেষণ করতে, আমাদের পরিষেবার প্রচার ও সমর্থন করতে এবং 
                        আমাদের ওয়েবসাইট এবং অ্যাপস ব্যবহারকারীদের সম্পর্কে একটি জ্ঞানের ভিত্তি তৈরি করতে ব্যবহার 
                        করব । নীচে বিস্তারিত হিসাবে, আপনি যে তথ্য প্রদান করেন তা অ্যাপের দর্শকদের কাছে উপলব্ধ 
                        হতে পারে এবং কিছু তথ্য গ্রাহক এবং হোলো টেক লিঃ এর কর্মকর্তা/কর্মচারী এবং/অথবা 
                        ড্রাইভারদের মধ্যে শেয়ার করা হবে।                        
                        </div>
                      </li>
                      <li>
                      <strong>নিবন্ধিত হোলো টেক লিমিটেড ব্যবহারকারীরা</strong>
                        <div>
                        আপনি যখন আমাদের সাইট বা অ্যাপে নিবন্ধন করবেন, আপনি একটি ব্যবহারকারীর নাম এবং 
                        প্রোফাইল তৈরি করবেন। আপনার ব্যবহারকারীর নাম এবং প্রোফাইল আমাদের অ্যাপের ব্যবহারকারীদের 
                        দ্বারা অ্যাক্সেসযোগ্য হবে। আপনার পূর্বানুমতি সহ, আমরা তৃতীয় পক্ষের সাইটে আপনার পরিষেবার 
                        ব্যবহার সম্পর্কে তথ্যও সরবরাহ করতে পারি। <br/>

                        আপনি যদি একজন গ্রাহক হিসাবে একটি চাকরি পোস্ট করেন, আমরা অ্যাপে যাত্রা শুরু এবং 
                        গন্তব্য অবস্থানের ঠিকানা প্রকাশ করতে পারি, যা সকল হোলো টেক লিঃ বা ড্রাইভার দ্বারা দেখা 
                        যায়। উদাহরণস্বরূপ, যদি আপনি একটি নির্দিষ্ট ড্রাইভারের জন্য অ্যাপে একটি ট্রিপ পোস্ট করতে 
                        চান, আমরা অ্যাপে পিকআপ এবং গন্তব্য অবস্থানের ঠিকানা প্রকাশ করব, সেই নির্দিষ্ট ড্রাইভারের 
                        কাছে দৃশ্যমান হবে ।                        
                        </div>
                      </li>
                      <li>
                      <strong>যোগাযোগের তথ্য</strong>
                        <div>
                        আপনি যখন আমাদের আপনার যোগাযোগের তথ্য প্রদান করেন, তখন আমরা সেই তথ্যটি 
                        ব্যবহার করে আপনার সাথে আমাদের পরিষেবার ব্যবহার সম্পর্কে যোগাযোগ করব। আমরা আপনার 
                        যোগাযোগের তথ্য ড্রাইভারদের সাথে শেয়ার করব যাতে আপনারা একে অপরের সাথে যোগাযোগ 
                        করতে পারেন।                        
                        </div>
                      </li>
                      <li>
                      <strong>প্রশংসাপত্র</strong>
                        <div>
                        আমরা আপনাকে আমাদের অ্যাপগুলির সাথে আপনার অভিজ্ঞতা সম্পর্কে প্রশংসাপত্র জমা দেওয়ার 
                        অনুমতি দিতে পারি। আপনি একটি প্রশংসাপত্র প্রদান করলে, আমরা আপনার নামের সাথে এই 
                        ওয়েবসাইটে পোস্ট করতে পারি। আপনি যদি আপনার প্রশংসাপত্র সরাতে চান, তাহলে অনুগ্রহ 
                        করে holotechlimited@gmail.com এ আমাদের সাথে যোগাযোগ করুন।                        
                        </div>
                      </li>
                      <li>
                      <strong>রেটিং এবং পর্যালোচনা</strong>
                        <div>
                        আপনি যদি একজন গ্রাহক হন, তাহলে আপনি একজন ড্রাইভারকে রেট দিতে এবং পর্যালোচনা 
                        করতে পারবেন এবং এর বিপরীতে আপনি যদি ড্রাইভার হন, তাহলে আপনি একজন গ্রাহককে 
                        রেট দিতে এবং পর্যালোচনা করতে পারবেন । আপনি যদি একটি রেটিং জমা দিতে চান তবে এটি 
                        অন্যান্য রেটিংগুলির সাথে একত্রিত হবে এবং অ্যাপগুলির অন্যান্য নিবন্ধিত ব্যবহারকারীদের জন্য 
                        উপলব্ধ হবে৷ আপনি একটি পর্যালোচনা জমা দিলে, আপনার ব্যবহারকারীর নাম সহ আপনার 
                        পর্যালোচনাটি সবার দেখার জন্য পোস্ট করা হবে ৷                        
                        </div>
                      </li>
                      <li>
                      <strong>বেনামী তথ্য</strong>
                        <div>
                        আমরা আমাদের অ্যাপের ব্যবহারকারীদের আরও ভালভাবে বোঝার জন্য এবং আমাদের পরিষেবার 
                        মান উন্নত করতে সামগ্রিক ভিত্তিতে যে বেনামী তথ্য সংগ্রহ করি তা ব্যবহার করি। আমরা শিল্প 
                        বিশ্লেষণ, জনসংখ্যার প্রোফাইলিং এবং অন্যান্য উদ্দেশ্যে তৃতীয় পক্ষের কাছে এই সমষ্টিগত তথ্য 
                        লাইসেন্স বা বিক্রি করার অধিকার সংরক্ষণ করি, তবে এই তথ্যে আপনার স্বতন্ত্রভাবে সনাক্তযোগ্য 
                        ব্যক্তিগত তথ্য অন্তর্ভুক্ত থাকবে না।                        
                        </div>
                      </li>
                      <li>
                      <strong>প্রচার</strong>
                        <div>
                        আমরা আপনাকে একজন সম্ভাব্য গ্রাহককে আমাদের হোলো টেক লিঃ পরিষেবাগুলিতে রেফার 
                        করার সুযোগ দিতে পারি এবং আপনি রেফারের কমিশন পেতে পারেন ।                        
                        </div>
                      </li>
                      <li>
                      <strong>আমাদের ব্যবসায়িক অংশীদারদের সাথে শেয়ার করা তথ্য</strong>
                        <div>
                        আমরা আমাদের অ্যাপ ডিজাইন ও পরিচালনা করতে এবং অ্যাপগুলিকে সমর্থন করার জন্য 
                        পরিষেবা প্রদান করতে সাহায্য করার জন্য ব্যবসায়িক অংশীদারদের ব্যবহার করতে পারি। আমরা 
                        নির্দিষ্ট অ্যাপ্লিকেশনগুলি চালানোর জন্য, তথ্য সঞ্চয়স্থান এবং প্রক্রিয়াকরণ পরিষেবাগুলি প্রদান 
                        করতে বা আমাদের তথ্য বিশ্লেষণ করতে সাহায্য করার জন্য একটি কোম্পানিকে নিয়োগ করতে 
                        পারি। এই ব্যবসায়িক অংশীদারদের ব্যক্তিগত তথ্যে অ্যাক্সেস থাকতে পারে যা আমরা রাখি, কিন্তু 
                        শুধুমাত্র যাতে তারা আমাদের পক্ষে এই কাজগুলি সম্পাদন করতে পারে। আমরা এই ব্যবসায়িক 
                        অংশীদারদের ব্যক্তিগতভাবে শনাক্তকরণযোগ্য তথ্যের, যা আমরা সংরক্ষণ করি, কোনো স্বাধীন 
                        বাণিজ্যিক ব্যবহার করার,তৃতীয় পক্ষকে এই ধরনের তথ্য দেয়ার বা তথ্য সর্বজনীনভাবে উপলব্ধ 
                        করার অনুমতি দিই না । অনুগ্রহ করে মনে রাখবেন, যাইহোক, যদি আপনি এই ব্যবসায়িক 
                        অংশীদারদের মধ্যে একটির সাথে একটি পৃথক সম্পর্ক স্থাপন করেন, তাহলে আপনি সরাসরি সেই 
                        সংস্থাকে যে তথ্য প্রদান করবেন তা সেই সংস্থার ব্যবহারের শর্তাবলী এবং এর গোপনীয়তা 
                        অনুশীলনের সাপেক্ষে হবে। <br/>

                        এছাড়াও আমরা আপনার ব্যক্তিগত তথ্য আমাদের ব্যবসায়িক অংশীদার বা অন্যান্য বিশ্বস্ত সত্ত্বাকে 
                        সরবরাহ করতে পারি যাতে আপনাকে পণ্য বা পরিষেবাগুলির তথ্য প্রদানের উদ্দেশ্যে আমরা বিশ্বাস 
                        করি যেগুলি আপনার আগ্রহের বিষয় হবে। আপনি, যে কোনো সময়, এই ধরনের যোগাযোগ 
                        প্রাপ্তি বন্ধ করতে পারেন৷ Google সহ তৃতীয় পক্ষের বিক্রেতারা হোলো টেক লিঃ-তে ব্যবহারকারীর 
                        পূর্বে ভিজিটের ভিত্তিতে বিজ্ঞাপন পরিবেশন করতে কুকিজ ব্যবহার করে। www.google.com/privacy_ads.html।                        
                        </div>
                      </li>
                      <li>
                      <strong>আইন প্রয়োগকারী এবং আইনি পদক্ষেপ</strong>
                        <div>
                        যখন আমরা বিশ্বাস করি যে এটি জালিয়াতি রোধ করার জন্য বা ক্রিয়াকলাপ প্রতিরোধ বা বন্ধ 
                        করার জন্য উপযুক্ত যা আমরা জানি বা সন্দেহ করি যেটি বেআইনি, অনৈতিক বা আইনগতভাবে 
                        পদক্ষেপযোগ্য হতে পারে তখন আমরা পূর্ব বিজ্ঞপ্তি ছাড়াই আপনার তথ্য প্রকাশ করতে পারি; 
                        আইন মেনে চলা বা আইন প্রয়োগকারী কার্যকলাপ বা অন্যান্য সরকারী অনুরোধে সহযোগিতা করা; 
                        কোনও সমন, আদালতের আদেশ বা তথ্যের জন্য প্রশাসনিক সংস্থার অনুরোধের প্রতিক্রিয়া জানাতে; 
                        আমাদের নীতি প্রয়োগ করতে; আমাদের ব্যবসা এবং অন্যদের অধিকার, সম্পত্তি এবং নিরাপত্তা 
                        রক্ষা করতে; অথবা যখন অন্যথায় আইন দ্বারা প্রয়োজন হয়। যদি এই সাইটটি এবং অ্যাপগুলো 
                        পরিচালনা করে এমন কোম্পানি, বিভাগ বা ব্যবসায়িক ইউনিটের বিক্রয় বা একীভূত হয়, তাহলে 
                        আমরা এই ধরনের বিক্রয় বা একীভূতকরণের সাথে সম্পর্কিত তথ্য সংগৃহীত ও স্থানান্তর করতে 
                        পারি। <br/>

                        আমরা আমাদের সংগ্রহ করা তথ্য ব্যবহার করব ক্রমাগত আমাদের ব্যবসা এবং আমাদের 
                        ওয়েবসাইট উন্নয়নের জন্য। আপনার মন্তব্য এবং পরামর্শ সবসময় সমাদর করা হয়. আপনার 
                        কোন মন্তব্য বা পরামর্শ থাকলে অনুগ্রহ করে আমাদের সাথে holotechlimited@gmail.com এ 
                        যোগাযোগ করুন।                        
                        </div>
                      </li>
                      <li>
                      <strong>তৃতীয় পক্ষের ওয়েবসাইট</strong>
                        <div>
                        এই সাইটে এবং অ্যাপগুলোতে আমাদের সাথে অধিভুক্ত নয় এমন কোম্পানি দ্বারা পরিচালিত 
                        অন্যান্য ওয়েবসাইটের লিঙ্ক থাকতে পারে। এছাড়াও, আপনি এমন একটি ওয়েবসাইট থেকে এই 
                        ওয়েবসাইটে এসেছেন যা আমাদের দ্বারা পরিচালিত নয়। আমরা এই অন্যান্য সাইটের অপারেশন বা 
                        তারা তাদের দর্শকদের কাছ থেকে সংগ্রহ করা তথ্যের জন্য দায়ী নই। আপনি যদি জানতে চান 
                        কিভাবে অন্য সাইট আপনার তথ্য সংগ্রহ করে এবং ব্যবহার করে, অনুগ্রহ করে সেই সাইটের 
                        গোপনীয়তা নীতি পর্যালোচনা করুন।                        
                        </div>
                      </li>
                      <li>
                      <strong>তথ্য পরিবর্তন</strong>
                        <div>
                        আপনি আমাদের প্রদান করা তথ্য স্থায়ী নয়. আপনি যেকোনো সময় আপনার প্রোফাইলের 
                        ব্যক্তিগত তথ্য পর্যালোচনা, আপডেট, সংশোধন বা মুছে ফেলতে পারেন। আপনি যদি আমাদের 
                        রেকর্ড থেকে আপনার তথ্য মুছে দিতে চান, অনুগ্রহ করে আমাদের সাথে 
                        holotechlimited@gmail.com এ যোগাযোগ করুন। রেকর্ডটি ধরে রাখার আইনগত 
                        বাধ্যবাধকতা না থাকলে আমরা আপনার অনুরোধটি সম্পন্ন করার চেষ্টা করব।                        
                        </div>
                      </li>
                      <li>
                      <strong>ডিভাইসের তথ্য</strong>
                        <div>
                        আপনি যখন আমাদের পরিষেবা অ্যাক্সেস করতে আপনার মোবাইল ডিভাইস ব্যবহার করে 
                        মিথষ্ক্রিয়া এবং তথ্যের জন্য আমাদের সাইটে বা অ্যাপগুলোতে যান, তখন আমরা হার্ডওয়্যার 
                        মডেল, ডিভাইসের আইপি ঠিকানা, SDK সংস্করণ, অপারেটিং সিস্টেম এবং সংস্করণ, সফ্টওয়্যার, 
                        ভাষা এবং দেশের সাইট, ইন্টারনেট ব্রাউজার, অনন্য ডিভাইস শনাক্তকারী, বিজ্ঞাপন সনাক্তকারী, 
                        সিরিয়াল নম্বর এবং মোবাইল নেটওয়ার্ক তথ্যসহ আপনার মোবাইল ডিভাইস সম্পর্কে তথ্য পেতে 
                        পারি । <br/>

                        বিজ্ঞাপন নেটওয়ার্ক সহ আমরা এবং আমাদের পরিষেবা প্রদানকারী এবং যে তৃতীয় পক্ষের সাথে 
                        আমরা সহযোগিতা করি, ক্রস-ডিভাইস/ক্রস-প্রসঙ্গ ট্র্যাকিং ব্যবহার করতে পারি। উদাহরণস্বরূপ, 
                        আপনি একক ডিভাইসে একাধিক ব্রাউজার ব্যবহার করতে পারেন বা বিভিন্ন ডিভাইস 
                        (যেমন ডেস্কটপ, স্মার্টফোন এবং ট্যাবলেট) ব্যবহার করতে পারেন, যার ফলে এই বিভিন্ন প্রসঙ্গ 
                        এবং ডিভাইস জুড়ে আপনার একাধিক অ্যাকাউন্ট বা প্রোফাইল থাকতে পারে। 
                        ক্রস-ডিভাইস/ক্রস-প্রসঙ্গ প্রযুক্তি এই বিভিন্ন অ্যাকাউন্ট বা প্রোফাইল এবং বিভিন্ন প্রসঙ্গ এবং 
                        ডিভাইস থেকে সংশ্লিষ্ট তথ্য সংযোগ করতে ব্যবহার করা যেতে পারে। ডিভাইসের তথ্য যেমন 
                        হার্ডওয়্যার মডেল, অপারেশন সিস্টেমের তথ্য, সফ্টওয়্যার তথ্য এবং সংস্করণ, ফাইলের নাম, 
                        ভাষা পছন্দ, IP ঠিকানা, কুকি তথ্য, বিজ্ঞাপন শনাক্তকারীর মতো আমাদের পরিষেবাগুলি 
                        অ্যাক্সেস করতে আপনি যে ডিভাইসগুলি ব্যবহার করেন (মোবাইল ফোন, কম্পিউটার, ট্যাবলেট 
                        ইত্যাদি) , ব্রাউজার সংস্করণ, ডিভাইস সেটিংস, এবং মোবাইল নেটওয়ার্ক তথ্য। আপনার ব্যবহার 
                        করা পরিষেবা জুড়ে আপনাকে ব্যক্তিগতকৃত অভিজ্ঞতা এবং বিজ্ঞাপন দেওয়ার জন্য আমরা আপনার 
                        ডিভাইসগুলিকে চিনতে পারি।                        
                        </div>
                      </li>
                      <li>
                      <strong>তথ্য সুরক্ষা</strong>
                        <div>
                        আপনি যদি এই সাইট বা অ্যাপে নিবন্ধন করে থাকেন, তাহলে আপনার ব্যবহারকারী আইডি এবং 
                        পাসওয়ার্ড সুরক্ষিত রাখতে হবে যাতে অন্যরা আপনার নামে সাইট বা অ্যাপ অ্যাক্সেস করতে না 
                        পারে। আপনার "ফিশিং" স্ক্যাম এবং একই ধরনের পরিচয় চুরির স্কিমগুলির জন্যও সতর্ক থাকা 
                        উচিত৷ আমরা যকোন সময়ে আপনার ক্রেডিট কার্ডের তথ্য, আপনার অ্যাকাউন্ট আইডি, লগইন 
                        পাসওয়ার্ড, বা জাতীয় শনাক্তকরণ নম্বরের জন্য একটি অ-সুরক্ষিত বা অযাচিত ই-মেইল বা 
                        টেলিফোন যোগাযোগের মাধ্যমে অনুরোধ করি না এবং করব না। <br/>

                        যদি আপনার ব্যক্তিগত তথ্যর সাথে নিরাপত্তার লঙ্ঘন হয় যার জন্য বিজ্ঞপ্তির প্রয়োজন হয়, 
                        আপনি সম্মত হন যে আমরা লঙ্ঘন সম্পর্কে ইমেলের মাধ্যমে বা এই সাইটে বা অ্যাপে একটি 
                        সুস্পষ্ট পোস্টিংয়ের মাধ্যমে আপনাকে অবহিত করতে পারি। আমরা অযৌক্তিক বিলম্ব ছাড়াই বিজ্ঞপ্তিটি 
                        তৈরি করব, আইন প্রয়োগকারী সংস্থার বৈধ চাহিদার সাথে সামঞ্জস্যপূর্ণ এবং লঙ্ঘনের সুযোগ 
                        নির্ধারণ এবং তথ্য সিস্টেমের যুক্তিসঙ্গত অখণ্ডতা পুনরুদ্ধার করার জন্য প্রয়োজনীয় যে কোনও 
                        ব্যবস্থা নিব । <br/>

                        আমাদের ওয়েবসাইটে বা অ্যাপে নিরাপত্তা সম্পর্কে আপনার কোনো প্রশ্ন থাকলে, আপনি আমাদের 
                        সাথে holotechlimited@gmail.com এ যোগাযোগ করতে পারেন।                        
                        </div>
                      </li>
                      <li>
                      <strong>তথ্য অখণ্ডতা</strong>
                        <div>
                        আমরা আপনার সম্পর্কে যে তথ্য সংগ্রহ করি তা শুধুমাত্র সেই উদ্দেশ্যে ব্যবহার করি যার জন্য 
                        এটি সংগ্রহ করা হয় এবং এই নীতির সাথে সামঞ্জস্যপূর্ণ। যতক্ষণ আমরা আমাদের ব্যবসায়িক 
                        উদ্দেশ্যে প্রয়োজনীয় এবং প্রযোজ্য আইন দ্বারা অনুমোদিত বলে মনে করি ততক্ষণ আমরা 
                        আমাদের কাছে সরবরাহ করা তথ্য সংরক্ষন করি ।                        
                        </div>
                      </li>
                      <li>
                      <strong>এই নীতি পরিবর্তন</strong>
                        <div>
                        আমাদের ব্যবসা এবং আইন যা আমাদের নিয়ন্ত্রিত করে তা সময়ে সময়ে পরিবর্তিত হয় এবং 
                        আমরা এই নীতি পরিবর্তন করার অধিকার সংরক্ষণ করি। যদি আমরা এই নীতি পরিবর্তন করি, 
                        আমরা এই সাইটে সংশোধিত সংস্করণ পোস্ট করব। যদি আমরা এমনভাবে আমাদের নীতি 
                        পরিবর্তন করার প্রস্তাব করি যা আমাদের আপনার সম্পর্কে পূর্বে সংগ্রহ করা তথ্যের অতিরিক্ত 
                        ব্যবহার করার অনুমতি দেয়, আমরা আপনাকে সেই অতিরিক্ত ব্যবহারগুলি থেকে বেছে নেয়ার 
                        একটি অর্থপূর্ণ উপায় প্রদান করব ৷                        
                        </div>
                      </li>
                      <li>
                      <strong>তথ্য বাংলাদেশ আইন সাপেক্ষে</strong>
                        <div>
                        হোলো টেক লিঃ ঢাকা, বাংলাদেশে অবস্থিত এবং এর ওয়েবসাইট এবং অ্যাপগুলো পরিচালনা 
                        করে। আপনি কোথায় থাকেন তার উপর নির্ভর করে, আপনি যে তথ্য প্রদান করেন এবং এই 
                        সাইটটি বা অ্যাপগুলো যে তথ্য সংগ্রহ করে তা আপনার দেশের বা অন্যান্য সরকারী এখতিয়ারের 
                        বাইরের সার্ভারগুলিতে সংরক্ষণ করা যেতে পারে এবং প্রযোজ্য গোপনীয়তা আইনগুলি আপনার 
                        এখতিয়ারের অধিক্ষেত্রের মতো সুরক্ষামূলক নাও হতে পারে। আপনি যদি বাংলাদেশের বাইরে থাকেন 
                        এবং আমাদের কাছে তথ্য প্রদান করতে চান, বাংলাদেশ ব্যক্তিগত তথ্য অন্য দেশে স্থানান্তর করে 
                        এবং সেখানে এটি প্রক্রিয়া করে। এই ওয়েবসাইট এবং অ্যাপগুলো ব্যবহার করে, আপনি এই 
                        স্থানান্তর এবং তথ্য প্রক্রিয়াকরণে সম্মত হন।                        
                        </div>
                      </li>
                      <li>
                      <strong>শিশু নীতি</strong>
                        <div>
                        যদিও আমাদের সাইটটি সকলের দেখার জন্য উপলব্ধ, আমাদের ওয়েবসাইটে বা অ্যাপগুলোতে 
                        নিবন্ধন করতে এবং আমাদের পরিষেবাগুলি ব্যবহার করার জন্য আপনাকে অবশ্যই একজন 
                        প্রাপ্তবয়স্ক হতে হবে। আমরা জেনেশুনে ১৮ বছরের কম বয়সী শিশুদের সম্পর্কে তথ্য সংগ্রহ 
                        করব না৷ আপনি যদি একজন অভিভাবক হন যিনি বিশ্বাস করেন যে আমরা ১৮ বছরের কম 
                        বয়সী একটি শিশুর সম্পর্কে তথ্য সংগ্রহ করেছি, অনুগ্রহ করে আপনার সন্তানের নাম এবং 
                        ঠিকানা সহ holotechlimited@gmail.com এ আমাদের সাথে যোগাযোগ করুন এবং 
                        আমরা শিশু আইন 2013 অনুযায়ী আমাদের রেকর্ড থেকে আপনার সন্তানের সম্পর্কে আমাদের 
                        কাছে থাকা তথ্য মুছে দিতে পেরে খুশি হব।                        
                        </div>
                      </li>
                      <li>
                      <strong>এই নীতি একটি চুক্তি</strong>
                        <div>
                        আপনি যখন এই সাইটে বা অ্যাপগুলোতে যান, আপনি এই গোপনীয়তা নীতিতে বর্ণিত 
                        অনুশীলনগুলি গ্রহণ করছেন।                        
                        </div>
                      </li>
                      <li>
                      <strong>আমাদের যোগাযোগ</strong>
                        <div>
                        holotechlimited@gmail.com এ আমাদের সাথে যোগাযোগ করুন এবং আমাদের নীতি 
                        বা অনুশীলন সম্পর্কে আপনার কোন প্রশ্ন বা মন্তব্য থাকলে আমাদের জানান ।                        
                        </div>
                      </li>
                    </ol>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBlack />
      </>
    );
  }
}

export default PrivacyBangla;
