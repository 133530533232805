import * as React from "react";
import { Link } from "react-router-dom";
import FooterBlack from "../components/footerblack";
import HeaderBlack from "../components/headerblack";
import loadjs from "loadjs";
import MobileNav from "../components/mobilenav";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

const position = [23.740566561527984, 90.37567758347049];

class Contact extends React.Component {
  componentDidMount() {
    loadjs(
      "./js/main.js"
      //     , function () {
      //   console.log("load main");
      // }
    );

    loadjs(
      "./js/plugins.js"
      //     , function () {
      //   console.log("load plugin");
      // }
    );
    // loadjs("./js/google-map.js");
    // loadjs("./js/maplace-active.js");
  }

  render() {
    return (
      <>
        <HeaderBlack />
        <MobileNav />
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">Contact Us</h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Contact</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}

        {/* CONTACT ADDRESS AREA START */}
        <div className="ltn__contact-address-area mb-90">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-lg-12" >
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow h-75">
                  {/*<div className="ltn__contact-address-icon">*/}
                  {/*  <img src="img/icons/6.png" alt="Icon" />*/}
                  {/*</div>*/}
                  <h3>MD Abdullah Al Yasin</h3>
                  <p>Chief Communication Officer <br/>09638-987711<br/>yasin.cco.holo@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow h-75">
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/10.png" alt="Icon" />
                  </div>
                  <h3>Email Address</h3>
                  <p>holotechlimited@gmail.com</p>
                </div>
              </div>
              <br/>
              <div className="col-sm-12 col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow h-75">
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/11.png" alt="Icon" />
                  </div>
                  <h3>Phone Number</h3>
                  <p>09638-991110</p>
                  <br/>
                </div>
              </div>
              <br/>
              <div className="col-sm-12 col-lg-4">
                <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow h-75">
                  <div className="ltn__contact-address-icon">
                    <img src="img/icons/12.png" alt="Icon" />
                  </div>
                  <h3>Office Address</h3>
                  <p>
                    2nd floor, 52/1, Dhanmondi 3/A, Dhaka
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* CONTACT ADDRESS AREA END */}

        {/* GOOGLE MAP AREA START */}

        {/*<iframe*/}
        {/*  title="holo office location"*/}
        {/*  src="https://maps.google.com/maps?q=road%203/a,%2052/1&t=&z=13&ie=UTF8&iwloc=&output=embed"*/}
        {/*  width="100%"*/}
        {/*  height="100%"*/}
        {/*  frameBorder={0}*/}
        {/*  allowFullScreen*/}
        {/*  aria-hidden="false"*/}
        {/*  tabIndex={0}*/}
        {/*/>*/}

        <MapContainer
          center={position}
          zoom={16}
          // scrollWheelZoom={false}
          style={{ height: "400px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={position}>
            <Popup>
              <strong>
                2nd floor, 52/1, Dhanmondi 3/A, <br />
                Dhaka, Bangladesh
              </strong>
            </Popup>
          </Marker>
        </MapContainer>
        <br />
        <br />
        <br />
        <br />
        {/* GOOGLE MAP AREA END */}

        <FooterBlack />
      </>
    );
  }
}

export default Contact;
