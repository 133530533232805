import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, {Component} from "react";
import {Link} from "react-router-dom";
import FooterBlack from "../components/footerblack";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import loadjs from "loadjs";
import splashScreenRider from "../instructions/images/riderSignUp/Rider Front page.jpg";
import signUp1Rider from "../instructions/images/riderSignUp/1.Rider sign up 1 eng.jpg";
import signUp2Rider from "../instructions/images/riderSignUp/2.Rider sign up 2 eng.jpg";
import riderComplete3 from "../instructions/images/riderSignUp/Rider complete 3.jpg";
import riderComplete4 from "../instructions/images/riderSignUp/Rider complete 4.jpg";
import riderComplete5 from "../instructions/images/riderSignUp/Rider Complete 5.jpg";
import riderWaitingApproval from "../instructions/images/riderSignUp/19.Rider waiting approval eng.jpg";
import riderEditDashboard from "../instructions/images/riderSignUp/22.Rider edit photo button eng.jpg";
import riderLandingPage from "../instructions/images/riderOnline/2.Landing page eng.jpg";
import riderSetRoute from "../instructions/images/riderOnline/4. Set route eng.jpg";
import riderLocationNotFound from "../instructions/images/riderOnline/6.Not found eng.jpg";
import riderAddDestination from "../instructions/images/riderOnline/8.Add first destination eng.jpg";
import riderSetDestination from "../instructions/images/riderOnline/9. Set first destination price eng.jpg";
import riderset2ndDestination from "../instructions/images/riderOnline/12. Set 2nd destination eng.jpg";
import riderLandingPageWithRoute from "../instructions/images/riderOnline/13. Landing page with route eng.jpg";
import riderSeeRoute from "../instructions/images/riderOnline/16. See route eng.jpg";
import riderGetNotification from "../instructions/images/rideRider/1. get ride notifications eng.jpg";
import riderAcceptRide from "../instructions/images/rideRider/4. accept ride eng.jpg";
import riderRidePage from "../instructions/images/rideRider/5. ride page eng.jpg";
import riderReceipt from "../instructions/images/rideRider/8. receipt eng.jpg";
import ins2_3 from "../instructions/images/image45.jpg";
import ins2_4 from "../instructions/images/image6.jpg";
import ins2_5 from "../instructions/images/image8.jpg";
import ins2_6 from "../instructions/images/image39.jpg";
import ins2_7 from "../instructions/images/image1.jpg";
import ins2_13 from "../instructions/images/image42.jpg";
import ins2_14 from "../instructions/images/image35.jpg";

class Instructions extends Component {
    componentDidMount() {
        loadjs("./js/plugins.js");
        loadjs("./js/main.js");
    }

    render() {
        return (
            <div>
                <HeaderBlack/>
                <MobileNav/>

                <div className="ltn__utilize-overlay"/>
                {/* BREADCRUMB AREA START */}
                <div
                    className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
                    data-bg="img/bg/dhaka.jpeg"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                                    <div className="section-title-area ltn__section-title-2">
                                        <h6 className="section-subtitle ltn__secondary-color">
                                            Welcome to Holo
                                        </h6>
                                        <h1 className="section-title white-color">Instructions</h1>
                                    </div>
                                    <div className="ltn__breadcrumb-list">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>Instructions</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BREADCRUMB AREA END */}
                <div className="ltn__contact-address-area mb-90">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-lg-3">
                                <div
                                    className="ltn_contact-address-item ltn_contact-address-item-3 box-shadow h-100 "
                                    style={{textAlign: 'center'}}>
                                    <h3 className="section-title">Chalok Instruction Bangla</h3>

                                    <a href="img/instructions/Chalok Instruction Bangla.pdf"
                                       download="Chalok Instruction Bangla.pdf"><img
                                        src="img/service/file.png" width="60" height="60" style={{marginBottom: '20px'}}
                                    /></a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <div
                                    className="ltn_contact-address-item ltn_contact-address-item-3 box-shadow h-100 "
                                    style={{textAlign: 'center'}}>
                                    <h3 className="section-title">Chalok Instruction English</h3>

                                    <a href="img/instructions/Chalok Instruction English.pdf"
                                       download="Chalok Instruction English.pdf"><img
                                        src="img/service/file.png" width="60" height="60" style={{marginBottom: '20px'}}
                                    /></a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <div
                                    className="ltn_contact-address-item ltn_contact-address-item-3 box-shadow h-100 "
                                    style={{textAlign: 'center'}}>
                                    <h3 className="section-title">&nbsp;  Jatri Instruction Bangla</h3>

                                    <a href="img/instructions/Jatri Instruction Bangla.pdf"
                                       download="Jatri Instruction Bangla.pdf"><img
                                        src="img/service/file.png" width="60" height="60" style={{marginBottom: '20px'}}
                                    /></a>
                                </div>
                            </div>
                            <div className="col-sm-12 col-lg-3">
                                <div
                                    className="ltn_contact-address-item ltn_contact-address-item-3 box-shadow h-100 "
                                    style={{textAlign: 'center'}}>
                                    <h3 className="section-title">&nbsp;  Jatri Instruction English</h3>

                                    <a href="img/instructions/Jatri Instruction English.pdf"
                                       download="Jatri Instruction English.pdf"><img
                                        src="img/service/file.png" width="60" height="60" style={{marginBottom: '20px'}}
                                    /></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                 <div className="col-lg-12">
                            <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
                                {/* Author Widget */}
                                <div className="widget ltn__author-widget">

                                    <div className="section-title-area ltn__section-title-2 text-center">
                                        {/*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*/}
                                        <h3 className="section-title">Holo Chalok Enlistment Procedure</h3>
                                    </div>

                                    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>
                                        <img src="img/instructions/bangla.jpeg" alt="Banner"/>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <img src="img/instructions/english.jpeg" alt="Banner"/>
                                    </div>
                                </div>
                                <div className="widget ltn__author-widget" style={{ textAlign: 'center'}}>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/wYSTa9J6fHk"
                                            title="YouTube video player" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen></iframe>
                                    {/*<div className="section-title-area ltn__section-title-2 text-center">*/}
                                    {/*    /!*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*!/*/}
                                    {/*    <h3 className="section-title">Covid-19</h3>*/}
                                    {/*</div>*/}

                                    {/*<div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>*/}
                                    {/*    <img src="img/service/corona.jpeg" alt="Banner"/>*/}

                                    {/*</div>*/}
                                </div>
                                {/*<div className="widget ltn__author-widget">*/}
                                {/*    <div className="section-title-area ltn__section-title-2 text-center">*/}
                                {/*        /!*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*!/*/}
                                {/*        <h3 className="section-title">Enlistment Flowchart</h3>*/}
                                {/*    </div>*/}

                                {/*    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>*/}
                                {/*        <img src="img/service/enlistment flowchart-bangla.drawio.png" alt="Banner"/>*/}
                                {/*        <br/>*/}
                                {/*        <br/>*/}
                                {/*        <br/>*/}
                                {/*        <img src="img/service/enlistment flowchart-english.drawio.png" alt="Banner"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="widget ltn__author-widget">*/}
                                {/*    <div className="section-title-area ltn__section-title-2 text-center">*/}
                                {/*        /!*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*!/*/}
                                {/*        <h3 className="section-title">Ride Sharing Service Guideline 2017*/}
                                {/*            (Gazette)</h3>*/}
                                {/*    </div>*/}

                                {/*    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>*/}


                                {/*        <a href="img/service/Ride Sharing Service Guideline 2017 (Gazette).pdf"*/}
                                {/*           download="Ride Sharing Service Guideline 2017 (Gazette).pdf"><img*/}
                                {/*            src="img/service/file.png" width="100" height="100"/></a>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </aside>
                        </div>
                {/*<Accordion defaultActiveKey="0" flush>*/}
                {/*    /!*{Object.values(data).map((item, id) => {*!/*/}
                {/*    /!*  return (*!/*/}
                {/*    /!*    <Accordion.Item eventKey={JSON.stringify(id)}>*!/*/}
                {/*    /!*        {Object.values(item).map((i)=>{*!/*/}
                {/*    /!*          return <div>*!/*/}
                {/*    /!*              <Accordion.Header>{i.i_english}</Accordion.Header>*!/*/}
                {/*    /!*              <Accordion.Body>meow</Accordion.Body>*!/*/}
                {/*    /!*          </div>*!/*/}

                {/*    /!*      })}*!/*/}

                {/*    /!*    </Accordion.Item>*!/*/}
                {/*    /!*  );*!/*/}
                {/*    /!*})}*!/*/}



                {/*    <Link*/}
                {/*        to="/instructionsBangla"*/}
                {/*        className="theme-btn-1 btn btn-effect-1"*/}
                {/*        style={{marginTop: "-15%"}}*/}
                {/*    >*/}
                {/*        বাংলা*/}
                {/*    </Link>*/}

                {/*    <Accordion.Item eventKey="0">*/}
                {/*        <Accordion.Header>How to Sign Up as a Chalok</Accordion.Header>*/}
                {/*        <Accordion.Body>*/}
                {/*            <ol>*/}
                {/*                <li>*/}
                {/*                    {" "}*/}
                {/*                    Open the app. You will be on the opening page. Click on the*/}
                {/*                    “Sign up” button to go to the Sign up page.*/}
                {/*                </li>*/}
                {/*                <div className="instructionImage">*/}
                {/*                    <img src={splashScreenRider} alt="i21"/>*/}
                {/*                </div>*/}
                {/*                <li>*/}
                {/*                    This is the “Sign up” page. The infos with stars are*/}
                {/*                    mandatory. The optional infos are not mandatory, but those are*/}
                {/*                    also important for us and also for you to avail all the*/}
                {/*                    benefits.If you do not ride the vehicle, use a Chalok to*/}
                {/*                    operate, then you will be considered as the owner. Below is an*/}
                {/*                    explanation of the infos -*/}
                {/*                    <ul>*/}
                {/*                        <li>*/}
                {/*                            <strong>Name -</strong> Full Name of the Chalok, who will operate the*/}
                {/*                            vehicle.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Address -</strong> Full address with format like - Floor,House no. ,*/}
                {/*                            Road no., Area, City. This should be of the owner of the vehicle.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Phone Number - </strong>The format is - 01xxxxxxxxx.*/}
                {/*                            This number will be your identity for Holo. So be very careful and put the*/}
                {/*                            number you intend to*/}
                {/*                            use for your ride sharing service.The number should be used by the Chalok,*/}
                {/*                            but the sim should be owned by*/}
                {/*                            the owner, so that even if the Chalok changes, the operational number*/}
                {/*                            remains the same.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Email -</strong> You will get the notifications and offers through*/}
                {/*                            your mail,*/}
                {/*                            so it is also important. This should be of the owner.*/}

                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Emergency Contact Number - </strong>This is the number we will*/}
                {/*                            contact in case of an*/}
                {/*                            emergency or if you’re in some kind of danger or something like that. So put*/}
                {/*                            the phone number of the person*/}
                {/*                            you believe the most to come to your aid if you are in some kind of danger*/}
                {/*                            or accident.*/}
                {/*                            If the vehicle is operated by a Chalok, this number should be the personal*/}
                {/*                            number of the owner.*/}

                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Relationship with User - </strong> Relation with the person of the*/}
                {/*                            emergency contact number you have put*/}
                {/*                            above.Put “Owner” in case of a Chalok operating vehicle.*/}

                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Gender - </strong> Male, Female or Other*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Date of birth - </strong> You can select the date.*/}
                {/*                            Put the date you have on NID.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Blood group - </strong>This can be helpful in case of an emergency.*/}
                {/*                            This should be of the Chalok.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Password - </strong>You need to put and memorize*/}
                {/*                            the password.*/}
                {/*                        </li>*/}
                {/*                    </ul>*/}
                {/*                    <Container>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={signUp1Rider} alt="i22"/>*/}
                {/*                            </Col>*/}
                {/*                            <Col>*/}
                {/*                                <img src={ins2_3} alt="i23"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Container>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    After submitting the sign up info, you will be on this*/}
                {/*                    page.You can log in here.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={ins2_4} alt="i24"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You will get to this page. You need to complete your profile*/}
                {/*                    to be able to use the app.Start the process by hitting the*/}
                {/*                    “Complete your profile” button.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={ins2_5} alt="i25"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    We need these info to operate.*/}
                {/*                    <ul>*/}
                {/*                        <li>*/}
                {/*                            <strong>Your Vehicle - </strong> Select Your vehicle.*/}
                {/*                            Right now we have only two options - Motorcycle and*/}
                {/*                            Scooty. But we will expand as soon as possible and add*/}
                {/*                            other vehicles.{" "}*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Vehicle Registration Number - </strong>The format*/}
                {/*                            is City/District-Serial. This should be on the number*/}
                {/*                            plate of the vehicle. e.g - Dhaka Metro-LA-xx-xxxx*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Vehicle Model - </strong>The model name of the*/}
                {/*                            vehicle.The format is “Name CC Color” e.g - Bajaj Discover*/}
                {/*                            125 Black*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Vehicle Model Year - </strong> The year of the*/}
                {/*                            manufacture of the vehicle. e.g - 2021*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>National ID Number - </strong>The NID No. on your NID. This should*/}
                {/*                            be the*/}
                {/*                            NID of the Chalok who will operate the vehicle.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload National ID - </strong>Upload the picture of the front side*/}
                {/*                            and back side of the NID of the Chalok.*/}
                {/*                            It would be helpful if the pictures are scanned copies and clearly visible.*/}

                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Driving License - </strong>Upload the picture of front side*/}
                {/*                            and back side of the driving*/}
                {/*                            license of the Chalok. It would be helpful if the pictures are scanned*/}
                {/*                            copies and clearly visible.*/}

                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Profile Photo - </strong>Upload a clear and presentable*/}
                {/*                            picture of the Chalok.*/}
                {/*                            So that User can recognize the Chalok with the profile photo.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Utility Bill - </strong>Upload the picture of a recent bill*/}
                {/*                            copy of any utility of the address given above.*/}
                {/*                            It would be helpful if the picture is scanned and clearly visible and the*/}
                {/*                            address is seen in the bill.*/}

                {/*                        </li>*/}
                {/*                        <Container>*/}
                {/*                            <Row>*/}
                {/*                                <Col>*/}
                {/*                                    <img src={ins2_6} alt="i26"/>*/}
                {/*                                </Col>*/}
                {/*                                <Col>*/}
                {/*                                    <img src={ins2_7} alt="i27"/>*/}
                {/*                                </Col>*/}
                {/*                            </Row>*/}
                {/*                        </Container>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Registration Paper - </strong>Upload the picture of the*/}
                {/*                            Registration Paper of the Vehicle.*/}
                {/*                            It would be helpful if the pictures are scanned copies and clearly visible.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Vehicle Fitness Paper (For Cars) - </strong>Upload the*/}
                {/*                            picture of the Registration Paper of the Vehicle.*/}
                {/*                            It would be helpful if the pictures are scanned copies and clearly visible.*/}
                {/*                            Fitness papers are not necessary for motorcycles.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Tax Token - </strong>Upload the picture of the Tax Token of*/}
                {/*                            the Vehicle.*/}
                {/*                            It would be helpful if the pictures are scanned copies and clearly visible.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Insurance Paper - </strong>Upload the picture of the*/}
                {/*                            Insurance of the Vehicle.*/}
                {/*                            It would be helpful if the pictures are scanned copies and clearly visible.*/}

                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            <strong>Upload Owner’s NID - </strong>Upload the picture of the front side*/}
                {/*                            and back side of the NID of the Owner*/}
                {/*                            of the vehicle. It would be helpful if the pictures are scanned copies and*/}
                {/*                            clearly visible.*/}

                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            Check the format you will ride. If you are a professional, meaning you*/}
                {/*                            depend on the ride sharing for your livelihood,*/}
                {/*                            then check “Professionally”.If this is just a temporary thing, check “Non*/}
                {/*                            professionally”.*/}
                {/*                        </li>*/}
                {/*                        <Container>*/}
                {/*                            <Row>*/}
                {/*                                <Col>*/}
                {/*                                    <img src={riderComplete3} alt="i28"/>*/}
                {/*                                </Col>*/}
                {/*                                <Col>*/}
                {/*                                    <img src={riderComplete4} alt="i29"/>*/}
                {/*                                </Col>*/}
                {/*                            </Row>*/}
                {/*                        </Container>*/}
                {/*                    </ul>*/}
                {/*                    <ul>*/}
                {/*                        <li>*/}
                {/*                            Check the method the Chalok prefers to get the payment from the Jatri. As*/}
                {/*                            the money will be sent directly to your wallet,*/}
                {/*                            it would be appreciated if you prefer the digital payment methods with cash.*/}
                {/*                            Then you can get more users and can earn more.*/}
                {/*                        </li>*/}
                {/*                        <li>*/}
                {/*                            This is the number needed for your cashout option.*/}
                {/*                            Put the number and the method you want to get the cashout. If it is Bkash,*/}
                {/*                            then only the registered bkash number*/}
                {/*                            will be fine.But if you want to use any other method, the format is*/}
                {/*                            Name-Number. e.g. - “Rocket - 01xxxxxxxxx”.*/}
                {/*                        </li>*/}
                {/*                        <div className="instructionImage">*/}
                {/*                            <img src={riderComplete5} alt="i21"/>*/}
                {/*                        </div>*/}
                {/*                    </ul>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You need to press the button “Complete Profile” to proceed. Check all the info*/}
                {/*                    before hitting the button.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderComplete5} alt="i21"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    After you successfully complete your profile, you will be on this page. Holo will*/}
                {/*                    check all the info and if there is a problem ,*/}
                {/*                    will contact you ( The Owner ). If all the info is ok, then your ID will be*/}
                {/*                    verified and you will be able to earn through Holo. You can contact us in any way -*/}
                {/*                    through Phone, Messenger or What’s app, if necessary.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderWaitingApproval} alt="i210"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    If you want to edit any info, you need to send the info through What’s app. And if*/}
                {/*                    you need to reupload any photo,*/}
                {/*                    you can go to the profile page by clicking the button on the left below corner.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderWaitingApproval} alt="i211"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You can edit any photo before the verification. Press on the button beside the name*/}
                {/*                    to go to the edit page.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderEditDashboard} alt="i212"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You will get to the edit photo page. You can upload any photo you want to change*/}
                {/*                    from before. Just upload and wait for the text to change from “not selected” to*/}
                {/*                    “uploaded”.*/}
                {/*                    <Container>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={ins2_13} alt="i213"/>*/}
                {/*                            </Col>*/}
                {/*                            <Col>*/}
                {/*                                <img src={ins2_14} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Container>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    Once you get the “uploaded”, you can get back to the profile page by clicking the*/}
                {/*                    arrow on the upper left corner.*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    Now please patiently wait for the verification. After the verification is done, you*/}
                {/*                    will be on the landing page like below when you open the app. Enjoy the service.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLandingPage} alt="i215"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*            </ol>*/}
                {/*        </Accordion.Body>*/}
                {/*    </Accordion.Item>*/}
                {/*    <Accordion.Item eventKey="1">*/}
                {/*        <Accordion.Header>*/}
                {/*            How to Set Route and Go Online*/}
                {/*        </Accordion.Header>*/}
                {/*        <Accordion.Body>*/}
                {/*            <ol>*/}
                {/*                <li>*/}
                {/*                    {" "}*/}
                {/*                    Open the app and login. You will be on the landing page.*/}
                {/*                    Click on the arrow on the top to set the routes. You can get the status by only*/}
                {/*                    looking at the text below the arrow.*/}
                {/*                    The word “set” indicates that you need to set the route.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLandingPage} alt="i21"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You will get a marker where your current location will be shown. HOLO zone is your*/}
                {/*                    nearest area*/}
                {/*                    from where the probability of getting a passenger is the highest.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderSetRoute} alt="i21"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    If for any reason the current location is shown “not found”, you can select your*/}
                {/*                    current location in the field.*/}
                {/*                    Click on the field and you will get a dropdown list, type and select your location*/}
                {/*                    from the list to set the location.*/}
                {/*                    Please keep in mind that you have to select the location from the list.*/}

                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLocationNotFound} alt="i210"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You need to click on the “Your Destination” button to get the field to add*/}
                {/*                    destination.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderSetRoute} alt="i211"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    Select the area you prefer to go and the estimated fare to go to the area. You can*/}
                {/*                    add multiple preferred destinations,*/}
                {/*                    up to five destinations at a time.So that the chances of finding a ride increases.*/}
                {/*                    <Container>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderAddDestination} alt="i213"/>*/}
                {/*                            </Col>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderSetDestination} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderset2ndDestination} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Container>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    After you set the destinations, you can avail yourself on the Chalok list by going*/}
                {/*                    online.*/}
                {/*                    Click on the “Go online” button and set yourself as an available Chalok.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderset2ndDestination} alt="i215"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You will be on the landing page again, after successfully setting the route. You can*/}
                {/*                    get the status from the text below the arrow.*/}
                {/*                    The word “see” indicates that you have the routes set and you are available. Click*/}
                {/*                    on the arrow to get the routes.*/}
                {/*                    Please wait a few moments if the routes are not shown immediately, it may take some*/}
                {/*                    time to load the routes.*/}
                {/*                    <Container>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderLandingPageWithRoute} alt="i213"/>*/}
                {/*                            </Col>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderSeeRoute} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Container>*/}
                {/*                </li>*/}
                {/*            </ol>*/}
                {/*        </Accordion.Body>*/}
                {/*    </Accordion.Item>*/}
                {/*    <Accordion.Item eventKey="2">*/}
                {/*        <Accordion.Header>*/}
                {/*            How to go offline to make yourself unavailable*/}
                {/*        </Accordion.Header>*/}
                {/*        <Accordion.Body>*/}
                {/*            <ol>*/}
                {/*                <li>*/}
                {/*                    {" "}*/}
                {/*                    Open the app , you will get to the landing page. You can get the status from the*/}
                {/*                    text below the arrow.*/}
                {/*                    The word “see” indicates that you have the routes set and you are available. Click*/}
                {/*                    on the arrow to get the routes.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLandingPageWithRoute} alt="i21"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You need to click on the “Go offline” button. You will be unavailable.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderSeeRoute} alt="i21"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    To make sure that you are unavailable , check the text below the arrow. The word*/}
                {/*                    “set” indicates that you are unavailable.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLandingPage} alt="i210"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*            </ol>*/}
                {/*        </Accordion.Body>*/}
                {/*    </Accordion.Item>*/}
                {/*    <Accordion.Item eventKey="3">*/}
                {/*        <Accordion.Header>*/}
                {/*            How to Ride as a Chalok*/}
                {/*        </Accordion.Header>*/}
                {/*        <Accordion.Body>*/}
                {/*            <ol>*/}
                {/*                <li>*/}
                {/*                    {" "}*/}
                {/*                    Open the app and login. You will be on the landing page.*/}
                {/*                    Click on the arrow on the top to set the routes. You can get the status by only*/}
                {/*                    looking at the text below the arrow.*/}
                {/*                    The word “set” indicates that you need to set the route.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLandingPage} alt="i21"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You will get a marker where your current location will be shown. HOLO zone is your*/}
                {/*                    nearest area*/}
                {/*                    from where the probability of getting a passenger is the highest.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderSetRoute} alt="i21"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    If for any reason the current location is shown “not found”, you can select your*/}
                {/*                    current location in the field.*/}
                {/*                    Click on the field and you will get a dropdown list, type and select your location*/}
                {/*                    from the list to set the location.*/}
                {/*                    Please keep in mind that you have to select the location from the list.*/}

                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLocationNotFound} alt="i210"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You need to click on the “Your Destination” button to get the field to add*/}
                {/*                    destination.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderSetRoute} alt="i211"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    Select the area you prefer to go and the estimated fare to go to the area. You can*/}
                {/*                    add multiple preferred destinations,*/}
                {/*                    up to five destinations at a time.So that the chances of finding a ride increases.*/}
                {/*                    <Container>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderAddDestination} alt="i213"/>*/}
                {/*                            </Col>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderSetDestination} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderset2ndDestination} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Container>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    After you set the destinations, you can avail yourself on the Chalok list by going*/}
                {/*                    online.*/}
                {/*                    Click on the “Go online” button and set yourself as an available Chalok.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderset2ndDestination} alt="i215"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You will be on the landing page again, after successfully setting the route. You can*/}
                {/*                    get the status from the text below the arrow.*/}
                {/*                    The word “see” indicates that you have the routes set and you are available. Click*/}
                {/*                    on the arrow to get the routes.*/}
                {/*                    Please wait a few moments if the routes are not shown immediately, it may take some*/}
                {/*                    time to load the routes.*/}
                {/*                    <Container>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderLandingPageWithRoute} alt="i213"/>*/}
                {/*                            </Col>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderSeeRoute} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Container>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    Now wait for a user to call. Fix the price and you will get a notification on the*/}
                {/*                    bottom right corner.*/}
                {/*                    You may not always get the light after a call due to a networking issue,*/}
                {/*                    so please check the notification section by clicking the notification icon on the*/}
                {/*                    bottom right corner sometimes.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderLandingPageWithRoute} alt="i215"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    After you click on the “Accept” button, you will get a pop-up like the picture below*/}
                {/*                    to confirm the acceptance.*/}
                {/*                    If you are sure to take the ride, click “Yes”.*/}
                {/*                    <Container>*/}
                {/*                        <Row>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderGetNotification} alt="i213"/>*/}
                {/*                            </Col>*/}
                {/*                            <Col>*/}
                {/*                                <img src={riderAcceptRide} alt="i214"/>*/}
                {/*                            </Col>*/}
                {/*                        </Row>*/}
                {/*                    </Container>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    You will be on the ride details page. After you reach the destination, click the*/}
                {/*                    “End of ride” button to End the ride.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderRidePage} alt="i215"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*                <li>*/}
                {/*                    After you click the “View Receipt” button, you will be on this page. Here you can*/}
                {/*                    rate the user. Your ride has ended successfully.*/}
                {/*                    <div className="instructionImage">*/}
                {/*                        <img src={riderReceipt} alt="i215"/>*/}
                {/*                    </div>*/}
                {/*                </li>*/}
                {/*            </ol>*/}
                {/*        </Accordion.Body>*/}
                {/*    </Accordion.Item>*/}
                {/*</Accordion>*/}
                <FooterBlack/>
            </div>
        );
    }
}

export default Instructions;
