import React, {Component} from "react";
import {Link} from "react-router-dom";
import FooterBlack from "../components/footerblack";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import loadjs from "loadjs";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class Faq extends Component {
    componentDidMount() {
        loadjs("./js/plugins.js");
        loadjs("./js/main.js");
    }

    render() {
        return (
            <>
                <HeaderBlack/>
                <MobileNav/>
                <div className="ltn__utilize-overlay"/>
                {/* BREADCRUMB AREA START */}
                <div
                    className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
                    data-bg="img/bg/dhaka.jpeg"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                                    <div className="section-title-area ltn__section-title-2">
                                        <h6 className="section-subtitle ltn__secondary-color">
                                            Welcome to Holo
                                        </h6>
                                        <h1 className="section-title white-color">
                                            Frequently asked questions
                                        </h1>
                                    </div>
                                    <div className="ltn__breadcrumb-list">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>Frequently Asked Question</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BREADCRUMB AREA END */}
                {/* FAQ AREA START (faq-2) (ID > accordion_2) */}
                <div className="ltn__faq-area mb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="ltn__faq-inner ltn__faq-inner-2">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                How to request a ride? <br/>
                                                কীভাবে রাইড রিকোয়েস্ট করবেন?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <div className="ltn__video-img alignleft">
                                                    <img
                                                        src="img/Holo-Logo-Icon.png"
                                                        height="120"
                                                        width="120"
                                                        alt="video popup bg image"
                                                    />
                                                    <a
                                                        className="ltn__video-icon-2 ltn__video-icon-2-small ltn__video-icon-2-border----"
                                                        href="https://www.youtube.com/embed/LjCzPp-MK48?autoplay=1&showinfo=0"
                                                        data-rel="lightcase:myCollection"
                                                    >
                                                        <i className="fa fa-play"/>
                                                    </a>
                                                </div>
                                                You have to download the app from google playstore, sign
                                                up using your mobile number and log in to the app to use
                                                it. You can search a ride from the app in two modes,
                                                regular and quick rides.
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>
                                                What is the cancellation fee? <br/>
                                                ক্যান্সেলেশন ফি আছে কি না?
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                There is no cancellation fee, but we need to know the
                                                cause of the cancellation, so that we can solve the
                                                problem and provide better service for the user the next
                                                time. If you cancel, please let us know.
                                                <br/>
                                                কোনও বাতিলকরণ ফি নেই, তবে আমাদের বাতিলের কারণ জানতে হবে,
                                                যাতে আমরা সমস্যাটি সমাধান করতে পারি এবং পরবর্তী সময়ে
                                                ব্যবহারকারীর জন্য আরও ভাল পরিষেবা সরবরাহ করতে পারি।আপনি
                                                যদি রাইড বাতিল করেন, বাতিল করার কারণটি আমাদেরকে জানানোর
                                                অনুরোধ করা হচ্ছে।
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="2">
                                            <Accordion.Header>
                                            What to do if the map takes time to load or the nearest holo zone is empty? <br/>
                                                
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            Refresh the map using the refresh button on the nearest holo zone field. If still map or holo zone doesn't load, please restart the app and try again.
                                                <br/>
                                               
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3">
                                            <Accordion.Header>
                                            What to do if the app get hanged or takes too much time to load or the display can turn to blank? <br/>
                                                
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            Please restart the app and logout , login and try again.
                                                <br/>
                                                
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="4">
                                            <Accordion.Header>
                                            The messenger doesn't load, what to do? <br/>
                                                
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            The messenger and what's app takes time to redirect, please wait some time after pressing the button and you will be taken to the messenger/what's app.
                                                <br/>

                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="5">
                                            <Accordion.Header>
                                            Who will pay the tolls of roads/flyovers/tunnels? <br/>
                                                
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            Jatri will pay.
                                                <br/>
                                                
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="6">
                                            <Accordion.Header>
                                            The referral point/chalok point doesn't add up although the chalok put my number in reference when buying the package. <br/>
                                                
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            The chalok may buy the package with chalok points he had. In that case the reference will not generate any point. Points will be given only in the case of purchase with payment. 
                                                <br/>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    </Accordion>
                                </div>
                                <div className="need-support text-center mt-100">
                                    <div className="section-title-area ltn__section-title-2 text-center">


                                    </div>
                                    <h4 className="section-subtitle ltn__secondary-color">If You Want to Delete Your
                                        Account,
                                        Click&nbsp;
                                        <Link to="/delete-account">
                                            <em>Here.</em>
                                        </Link>
                                    </h4>
                                    <h2>Still need help? Reach out to support 24/7:</h2>
                                    <div className="btn-wrapper mb-30">
                                        <Link to="/contact" className="theme-btn-1 btn">
                                            Contact Us
                                        </Link>
                                    </div>
                                    <h3>
                                        <i className="fas fa-phone"/> 09638-991110
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterBlack/>
            </>
        );
    }
}

export default Faq;
