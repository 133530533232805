import React from "react";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import FooterBlack from "../components/footerblack";

class TermsBangla extends React.Component {
  componentDidMount() {
    loadjs("./js/main.js");
    loadjs("./js/plugins.js");
  }

  render() {
    return (
      <>
        <HeaderBlack />
        <MobileNav />
        <div className="ltn__utilize-overlay" />

        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">
                      Terms of Service
                    </h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Terms of Service</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}

        {/* PAGE DETAILS AREA START (blog-details) */}
        <Link
                              to="/terms"
                              className="theme-btn-1 btn btn-effect-1"
                              style= {{ marginTop: "-10%",marginLeft: "10%" }}
                            >
                            English
        </Link>

        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__blog-details-wrap">
                  <div className="ltn__page-details-inner ltn__blog-details-inner">
                    <ol>
                      <li>
                        <strong>ভূমিকা</strong>
                        <div>                        
                        গুরুত্বপূর্ণ - এই শর্তাবলী সাবধানে পড়ুন। এই পরিষেবাটি ব্যবহার করে, আপনি সম্মত হন যে আপনি নিয়ম ও শর্তাবলী এবং আচরণবিধিতে বর্ণিত শর্তাবলী পড়েছেন, বুঝেছেন, 
                        করেছেন এবং সম্মত হয়েছেন৷ আপনি নীচে নিজের দ্বারা করা উপস্থাপনের দ্বারা আরও সম্মত হন। আপনি যদি সম্মত না হন বা পরিষেবার শর্তাবলীর মধ্যে না পড়েন 
                        (নিচে সংজ্ঞায়িত করা হয়েছে) এবং পরিষেবা ব্যবহার বন্ধ করতে চান, দয়া করে এই অ্যাপ্লিকেশন বা পরিষেবাটি ব্যবহার করা চালিয়ে যাবেন না। এখানে উল্লিখিত শর্তাবলী 
                        (সম্মিলিতভাবে, "নিয়ম ও শর্তাবলী" বা এই "চুক্তি") আপনার এবং "HOLO TECH Ltd"-এর মধ্যে একটি আইনি চুক্তি গঠন করে৷ <br/>
                        পরিষেবাটি ব্যবহার করার জন্য (নিচে সংজ্ঞায়িত প্রতিটি) আপনাকে অবশ্যই নিয়ম ও শর্তাবলীতে সম্মত হতে হবে যা নীচে সেট করা হয়েছে। HOLO TECH Ltd ("অ্যাপ্লিকেশন") দ্বারা 
                        আপনাকে সরবরাহ করা মোবাইল অ্যাপ্লিকেশন ব্যবহার করে, এবং HOLO TECH Ltd ("সফ্টওয়্যার") দ্বারা সরবরাহ করা কোনও সংশ্লিষ্ট সফ্টওয়্যার ডাউনলোড, ইনস্টল বা ব্যবহার করে 
                        যার সামগ্রিক উদ্দেশ্য পরিষেবাটি সক্ষম করা ( প্রত্যেকটি নীচে সংজ্ঞায়িত করা হয়েছে), আপনি এতদ্বারা স্পষ্টভাবে স্বীকার করছেন এবং HOLO TECH Ltd বা অ্যাপ্লিকেশনের মাধ্যমে 
                        সময়ে সময়ে প্রকাশিত এই শর্তাবলী এবং শর্তাবলীতে ভবিষ্যতের যেকোনো সংশোধন ও সংযোজন শর্তাবলী দ্বারা আবদ্ধ হতে সম্মত হন।
                        </div>
                      </li>

                      <li>
                        <strong>পরিষেবা</strong>
                        <div>
                        HOLO TECH Ltd. পরিবহন প্রদানকারী, চালক এবং যানবাহন অপারেটরদের সাথে যাত্রী বা গ্রাহকদের যোগাযোগ স্থাপনের জন্য তথ্য, পদ্ধতি এবং প্ল্যাটফর্ম অফার করে, 
                        কিন্তু পরিবহন পরিষেবা প্রদান বা ট্যাক্সি অপারেটর হিসাবে কোনোভাবেই কাজ করার ইচ্ছা পোষণ করে না, এবং আপনার দ্বারা যাত্রী বা গ্রাহকদের প্রদান করা কোনো পরিবহন 
                        পরিষেবার জন্য কোন দায়িত্ব বা দায় HOLO TECH Ltd. এর নেই। <br/>
                        HOLO TECH Ltd এই চুক্তির শর্তাদি এবং পরিষেবার সাথে সম্পর্কিত নীতিগুলি যে কোন সময় যেভাবে উপযুক্ত মনে করে সেভাবে পরিবর্তন,পরিমার্জন, অথবা সংশোধন করার অধিকার 
                        সংরক্ষণ করে । পরিষেবা সম্পর্কিত শর্তাদি নীতিতে এই ধরনের পরিবর্তন, পরিবর্তন বা পরিবর্তনগুলি HOLO TECH Ltd. এ একটি আপডেট সংস্করণ পোস্ট করার পরে কার্যকর হবে৷ 
                        আপনি সম্মত হন যে এই চুক্তিটি নিয়মিত পর্যালোচনা করা আপনার দায়িত্ব হবে যেখানে এই ধরনের পরিবর্তনের পরে পরিষেবার অব্যাহত ব্যবহার, আপনার দ্বারা পর্যালোচনা করা হোক 
                        বা না হোক, এই ধরনের পরিবর্তনগুলিতে আপনার সম্মতি গঠন করবে।<br/>
                        যেহেতু, এই রাইড শেয়ারিং ব্যবসা একটি ফ্রিল্যান্সিং মডেলের উপর ভিত্তি করে পরিচালিত হয়, ড্রাইভারগন HOLO TECH Ltd. এর কর্মচারী নয়। সংশ্লিষ্ট স্বতন্ত্র পক্ষগুলি, 
                        HOLO TECH Ltd. নয়, যাত্রী পরিবহনের ফলে ঘটিত ব্যক্তিগত আঘাত, মৃত্যু,ক্ষয়ক্ষতি এবং সম্পত্তির ক্ষতিসহ যে কোনো দুর্ঘটনা, ক্ষতি বা ক্ষতির ফলে যে কোনো এবং সমস্ত দাবি, 
                        রায় এবং দায়-দায়িত্বের জন্য সম্পূর্ণরূপে দায়ী থাকবে।
                        </div>
                      </li>

                      <li>
                        <strong>পরিবহন সরবরাহকারী নয়</strong>
                        <div>
                        HOLO TECH Ltd. হল একটি টেকনোলজি কোম্পানি যা পরিবহন পরিষেবা প্রদান করে না বা এর সাথে যুক্ত হয় না এবং HOLO TECH Ltd. পরিবহন প্রদানকারী নয় ৷ সফ্টওয়্যার 
                        এবং অ্যাপ্লিকেশনটির উদ্দেশ্য হল আপনার যাত্রী বা গ্রাহককে আপনার(পরিবহন প্রদানকারী হিসাবে) পরিবহন পরিষেবাগুলি অফার করার জন্য ব্যাবহৃত হওয়া। HOLO TECH Ltd. 
                        আপনার যাত্রীদের প্রদান করা কোনো পরিষেবার জন্য এবং/অথবা পরিষেবার কিছু বাদ দেওয়ার জন্য এবং আপনার দ্বারা সংঘটিত কোনো বেআইনি পদক্ষেপের জন্য দায়ী বা দায়বদ্ধ নয়৷ 
                        আপনি কখনই কোনো ব্যক্তির কাছে নিজেকে HOLO TECH Ltd এর এজেন্ট, কর্মচারী বা স্টাফ বলে দাবি করবেন না অথবা ভুল বোঝাবেন না, এবং আপনার দ্বারা প্রদত্ত পরিষেবাগুলি, 
                        HOLO TECH Ltd এর পরিষেবা বলে গন্য হবে না।
                        </div>
                      </li>
                      <li>
                        <strong>প্রত্যাখ্যান এবং কালো তালিকাভুক্ত করার অধিকার</strong>
                        <div>
                        HOLO TECH Ltd.-এর গ্রাহক বা কর্মচারীদের কাছ থেকে আপনার আচরণ বা অন্য কারো(গ্রাহক, HOLO TECH Ltd.-এর কর্মচারী, আইন প্রয়োগকারী, সরকারী কর্তৃপক্ষ সহ) সাথে 
                        মিথস্ক্রিয়া সম্পর্কে অভিযোগ বা যুক্তিসঙ্গতভাবে প্রত্যাশার তুলনায় কম দক্ষতার সাথে গাড়ি চালানোর জন্য, এখানে লেখা যাই হোক না কেন, HOLO TECH Ltd, তার একক এবং পরম 
                        বিবেচনার ভিত্তিতে, আপনাকে স্থায়ীভাবে বা অস্থায়ীভাবে অ্যাপ্লিকেশনটির ব্যাবহার এর ক্ষেত্রে কালো তালিকাভুক্ত করতে পারে এবং/অথবা পরিষেবা বা এর যে কোনও অংশ ব্যবহার করার 
                        জন্য আপনার অনুরোধ প্রত্যাখ্যান করতে পারে যেটি উপযুক্ত মনে হয়। 
                        </div>
                      </li>
                      <li>
                        <strong>প্রতিনিধিত্ব এবং ওয়্যারেন্টি</strong>
                        <div>
                        পরিষেবাটি ব্যবহার করে, আপনি স্পষ্টভাবে প্রতিনিধিত্ব করেন এবং ওয়ারেন্টি দেন যে আপনি নিয়ম ও শর্তাবলী মেনে নেওয়ার এবং তাতে সম্মত হওয়ার আইনত যোগ্য এবং আপনার বয়স 
                        কমপক্ষে আঠারো (18) বছর। পূর্বোক্ত সাধারণতা সীমাবদ্ধ না করে, পরিষেবাটি আঠারো (18) বছরের কম বয়সী ব্যক্তিদের জন্য অথবা যার কোনো কারণে চুক্তিবদ্ধ সম্পর্কে প্রবেশ করা 
                        নিষিদ্ধ এই ধরনের ব্যক্তিদের জন্য উপলব্ধ নয়। পরিষেবাটি ব্যবহার করে, আপনি আরও প্রতিনিধিত্ব করেন এবং ওয়ারেন্টি দেন যে আপনার কাছে পরিষেবাটি ব্যবহার করার এবং শর্তাবলী 
                        মেনে চলার অধিকার, কর্তৃত্ব এবং ক্ষমতা রয়েছে ৷ আপনি আরও নিশ্চিত করেন যে আপনার দেওয়া সমস্ত তথ্য সত্য এবং নির্ভুল হবে। আপনার পরিষেবার ব্যবহার আপনার নিজের, 
                        ব্যক্তিগত ব্যবহারের জন্য। আপনি আপনার পরিচয় বা স্থিতি ব্যবহার করার জন্য অন্যদের অনুমোদন না করার অঙ্গীকার করেন এবং আপনি অন্য কোনো ব্যক্তি বা সত্তাকে আপনার 
                        অ্যাকাউন্ট বরাদ্দ বা স্থানান্তর করতে পারবেন না। পরিষেবাটি ব্যবহার করার সময় আপনি আপনার দেশের সমস্ত প্রযোজ্য আইন মেনে চলতে সম্মত হন। এছাড়াও, HOLO TECH Ltd যে 
                        দেশে শুরু হয়েছে এবং নিবন্ধিত হয়েছে সেই দেশের প্রযোজ্য আইন, বিধি ও প্রবিধান যা প্রযোজ্য এবং আপনার স্থানীয় আঞ্চলিক অধিক্ষেত্রের আইন ও প্রবিধানের সাথে সামঞ্জস্যপূর্ণ এবং 
                        সময়ে সময়ে প্রণীত দ্বিপাক্ষিক চুক্তি, আন্তর্জাতিক ব্যবসা এবং বাণিজ্যিক আইনের সাথে সামঞ্জস্যপূর্ণ, মেনে চলতে আপনি অঙ্গীকারাবদ্ধ। <br/>
                        আপনি শুধুমাত্র অনুমোদিত এবং আইনি উপায় ব্যবহার করে পরিষেবা অ্যাক্সেস করতে পারেন। আপনি আপনার ডিভাইসের জন্য সঠিক সফ্টওয়্যার ডাউনলোড করেছেন তা পরীক্ষা করা এবং 
                        নিশ্চিত করা আপনার দায়িত্ব৷ আপনার যদি সামঞ্জস্যপূর্ণ হ্যান্ডসেট না থাকে বা আপনি যদি আপনার হ্যান্ডসেটের জন্য সফ্টওয়্যারটির ভুল সংস্করণ ডাউনলোড করে থাকেন তবে 
                        HOLO TECH Ltd. দায়ী নয়৷ আপনি যদি একটি বেমানান বা অননুমোদিত ডিভাইসের সাথে পরিষেবাটি ব্যবহার করেন বা অ্যাপ্লিকেশন বা সফ্টওয়্যারটি ব্যবহার করার উদ্দেশ্যে অন্য উদ্দেশ্যে 
                        ব্যবহার করেন তবে HOLO TECH Ltd. এই চুক্তিটি বাতিল করার অধিকার সংরক্ষণ করে৷
                          <ul>
                          <b>পরিষেবাটি ব্যবহার করে, আপনি প্রতিনিধিত্ব করেন, পরোয়ানা দেন, অঙ্গীকার করেন এবং সম্মত হন যে:</b>
                            <li>
                            আপনার কাছে একটি বৈধ ড্রাইভিং লাইসেন্স রয়েছে এবং আপনি একটি মোটর গাড়ি চালানোর জন্য অনুমোদিত এবং আপনি যে এখতিয়ারে পরিষেবাটি ব্যবহার করেন সেখানে 
                            তৃতীয় পক্ষকে ভাড়ার জন্য পরিবহন সরবরাহ করার জন্য সমস্ত উপযুক্ত লাইসেন্স, অনুমোদন এবং কর্তৃত্ব রয়েছে;
                            </li>
                            <li>
                            আপনি যাত্রী বা গ্রাহকদের গ্রহণ করার সময় যে গাড়িটি ব্যবহার করতে চান সেটির মালিক বা পরিচালনার আইনগত অধিকার এবং কর্তৃত্ব আপনার আছে, 
                            এবং এই ধরনের যানবাহন ভাল অপারেটিং অবস্থায় রয়েছে এবং এই ধরণের যানবাহনের জন্য শিল্প সুরক্ষা মান পূরণ করে;
                            </li>
                            <li>
                            আপনার মোটর গাড়ি/যাত্রীবাহী যান এবং/অথবা ব্যবসায়িক বীমা পরিচালনার জন্য দায় বীমার একটি বৈধ পলিসি রয়েছে (শিল্প-মান কভারেজ পরিমাণে) একটি ট্যাক্সি/যাত্রী বিতরণ 
                            পরিষেবা পরিচালনার সাথে সম্পর্কিত যে কোনও প্রত্যাশিত ক্ষতি কভার করার জন্য;
                            </li>
                            <li>
                            আপনি অবিলম্বে HOLO TECH Ltd. কে এই ধরনের তথ্য এবং পরিচয় নথি প্রদান করবেন, যার মধ্যে HOLO TECH Ltd. দ্বারা যুক্তিসঙ্গতভাবে অনুরোধ করা জাতীয় আইডি 
                            কার্ড এবং ড্রাইভার্স লাইসেন্স থাকবে;
                            </li>
                            <li>
                            যাত্রী পরিবহনের ফলে ঘটিত ব্যক্তিগত আঘাত, মৃত্যু,ক্ষয়ক্ষতি এবং সম্পত্তির ক্ষতিসহ যে কোনো দুর্ঘটনা, ক্ষতি বা ক্ষতির ফলে যে কোনো এবং সমস্ত দাবি, রায় এবং দায়-দায়িত্বের জন্য 
                            আপনি সম্পূর্ণরূপে দায়ী থাকবেন, যাত্রী পরিবহন এবং/অথবা বিতরণ পরিষেবা যেভাবেই পরিচালিত হোক না কেন;
                            </li>
                            <li>
                            আপনি যাত্রী পরিবহন এবং/অথবা ডেলিভারি পরিষেবাগুলির পরিচালনার সাথে সম্পর্কিত সমস্ত স্থানীয় আইন মেনে চলবেন এবং এই জাতীয় আইনগুলির লঙ্ঘনের জন্য সম্পূর্ণরূপে 
                            দায়ী থাকবেন;
                            </li>
                            <li>
                            আপনি শুধুমাত্র বৈধ উদ্দেশ্যে পরিষেবা ব্যবহার করবেন;
                            </li>
                            <li>
                            আপনি শুধুমাত্র সেই উদ্দেশ্যেই পরিষেবাটি ব্যবহার করবেন যার জন্য এটি ব্যবহার করার উদ্দেশ্যে করা হয়েছে;
                            </li>
                            <li>
                            আপনি কোনো বেআইনি উপাদান পাঠানো বা সংরক্ষণের জন্য বা প্রতারণামূলক উদ্দেশ্যে অ্যাপ্লিকেশন ব্যবহার করবেন না;
                            </li>
                            <li>
                            আপনি উপদ্রব, বিরক্তি, অসুবিধা বা জাল বুকিং করতে অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার ব্যবহার করবেন না;
                            </li>
                            <li>
                            আপনি পরিষেবা, অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার পরিষেবাটি গ্রহন করা ছাড়া অন্য উদ্দেশ্যে ব্যবহার করবেন না;
                            </li>
                            <li>
                            আপনি পরিষেবা ছাড়া অন্য উদ্দেশ্যে গ্রাহকদের সাথে যোগাযোগ করবেন না;
                            </li>
                            <li>
                            আপনি নেটওয়ার্কের সঠিক ক্রিয়াকলাপকে ক্ষতিগ্রস্ত করবেন না;
                            </li>
                            <li>
                            আপনি পরিষেবা, অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার যে কোনও উপায়ে ক্ষতি করার চেষ্টা করবেন না;
                            </li>
                            <li>
                            আপনি শুধুমাত্র আপনার নিজের ব্যবহারের জন্য সফ্টওয়্যার এবং/অথবা অ্যাপ্লিকেশন ব্যবহার করবেন এবং তৃতীয় পক্ষের কাছে এটি পুনরায় বিক্রি করবেন না;
                            </li>
                            <li>
                            আপনি আপনার অ্যাকাউন্টের পাসওয়ার্ড বা আমরা আপনাকে প্রদান করি এমন কোনো শনাক্তকরণ যা পরিষেবাটিতে অ্যাক্সেসের অনুমতি দেয় আপনি সুরক্ষিত এবং গোপন রাখবেন;
                            </li>
                            <li>
                            HOLO TECH Ltd যুক্তিসঙ্গতভাবে অনুরোধ করতে পারে বা প্রয়োজন হতে পারে এমন পরিচয়ের প্রমাণ আপনি আমাদের সরবরাহ করবেন;
                            </li>
                            <li>
                            আপনি পরিষেবার জন্য প্রয়োজনীয় সঠিক, বর্তমান এবং সম্পূর্ণ তথ্য প্রদান করতে সম্মত হন এবং চুক্তির মেয়াদ চলাকালীন সর্বদা সঠিক, বর্তমান এবং সম্পূর্ণ রাখার জন্য একটি 
                            সময়মত আপনার তথ্য বজায় রাখার এবং আপডেট করার দায়িত্ব গ্রহণ করেন। আপনি সম্মত হন যে HOLO TECH Ltd. সঠিক, বর্তমান এবং সম্পূর্ণ হিসাবে আপনার তথ্যের উপর 
                            নির্ভর করতে পারে। আপনি স্বীকার করেন যে যদি আপনার তথ্য অসত্য, ভুল, বর্তমান বা কোনো ক্ষেত্রে অসম্পূর্ণ না হয়, তাহলে HOLO TECH Ltd.-এর যে কোনো সময় বিজ্ঞপ্তি 
                            সহ বা ছাড়াই আপনার পরিষেবার ব্যবহার এবং এই চুক্তিটি বাতিল করার অধিকার আছে কিন্তু বাধ্যবাধকতা নেই;
                            </li>
                            <li>
                            আপনি শুধুমাত্র একটি অ্যাক্সেস পয়েন্ট বা ডেটা অ্যাকাউন্ট (AP) ব্যবহার করবেন যা ব্যবহার করার জন্য আপনি অনুমোদিত;
                            </li>
                            <li>
                            HOLO TECH Ltd. দ্বারা নতুন সদস্যতা বা পরিষেবার ব্যবহারকে উত্সাহিত করার জন্য যে কোনও ইভেন্ট, প্রচার বা প্রচারণাকে আপনি HOLO TECH Ltdকে প্রতারণা করার 
                            জন্য বা নিজেকে সমৃদ্ধ করার জন্য প্রতারণামূলক অথবা অন্য কোন উপায় ব্যবহার করবেন না;
                            </li>
                            <li>
                            আপনি পরিষেবা বা এর কোনও অংশ ব্যবহার করবেন না নিষিদ্ধ আইটেম বহন করার জন্য যা সর্বজনীনভাবে তালিকাভুক্ত করা যেতে পারে এবং সময়ে সময়ে HOLO TECH Ltd. 
                            দ্বারা আপডেট করা হতে পারে এবং যদি আপনি সন্দেহজনক আচরণ প্রদর্শন করেন তবে তৃতীয় পক্ষের পরিষেবা প্রদানকারী, যেকোনো সরকারি কর্তৃপক্ষ এবং/অথবা আইন প্রয়োগকারী, 
                            আপনি আপনার সাথে বহন করছেন এমন কোনো ব্যাগ এবং/অথবা আইটেম যা সহজে দৃশ্যমান হতে পারে বা নাও হতে পারে তা আপনি পরিদর্শন করার  অনুরোধ সম্পূর্ণরূপে মেনে 
                            চলবেন।;
                            </li>
                            <li>
                            আপনি সচেতন যে পরিবহন পরিষেবার জন্য যাত্রীদের বা গ্রাহকদের অনুরোধে সাড়া দেওয়ার সময়, স্ট্যান্ডার্ড টেলিকমিউনিকেশন চার্জ প্রযোজ্য হবে এবং যা শুধুমাত্র আপনার দ্বারা 
                            বহন করা হবে;
                            </li>
                            <li>
                            পরিষেবাটি যে নেটওয়ার্কে কাজ করে তার সঠিক ক্রিয়াকলাপকে আপনি ক্ষতিগ্রস্থ বা বাধা দেবেন না;
                            </li>
                            <li>
                            আপনি সম্মত হন যে পরিষেবাটি যুক্তিসঙ্গত প্রচেষ্টার ভিত্তিতে প্রদান করা হয়;
                            </li>
                            <li>
                            আপনি নিঃশর্তভাবে আপনার দ্বারা যে কোনো নিয়ম ও শর্তাবলী লঙ্ঘনের ফলে আপনার, গ্রাহক বা যাত্রী, HOLO TECH Ltd, বণিক এবং/অথবা কোনো তৃতীয় পক্ষের সমস্ত 
                            ক্ষতি বা ক্ষতির জন্য সম্পূর্ণ দায়বদ্ধতা এবং দায় নিতে সম্মত হন।
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <strong>লাইসেন্স মঞ্জুর এবং সীমাবদ্ধতা</strong>
                        <div>
                        HOLO TECH Ltd. এবং এর লাইসেন্সদাতারা, যেখানে প্রযোজ্য, এইভাবে আপনাকে একটি প্রত্যাহারযোগ্য, অ-এক্সক্লুসিভ, অ-হস্তান্তরযোগ্য, অ-অর্পণযোগ্য, ব্যক্তিগত, সীমিত লাইসেন্স 
                        প্রদান করে শুধুমাত্র আপনার নিজের ব্যক্তিগত উদ্দেশ্যে, অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার ব্যবহার করার জন্য, এই চুক্তির শর্তাবলী সাপেক্ষে। HOLO TECH Ltd. এবং এর 
                        লাইসেন্সদাতারা আপনাকে স্পষ্টভাবে প্রদত্ত নয় এমন সমস্ত অধিকার সংরক্ষন করে।
                        <ul>
                            <b>আপনি করবেন না:</b>
                            <li>লাইসেন্স, সাবলাইসেন্স, বিক্রয়, পুনঃবিক্রয়, স্থানান্তর, বরাদ্দ, বিতরণ বা অন্যথায় বাণিজ্যিকভাবে কোনো তৃতীয় পক্ষের কাছে অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার যে কোনো 
                          উপায়ে শোষণ বা উপলব্ধ করা;</li>
                            <li>অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যারের উপর ভিত্তি করে ডেরিভেটিভ কাজগুলি সংশোধন বা তৈরি করা;</li>
                            <li>
                            অন্য কোনো সার্ভার বা ওয়্যারলেস বা ইন্টারনেট-ভিত্তিক ডিভাইসে অ্যাপ্লিকেশন বা "ফ্রেম" বা "মিরর" সফ্টওয়্যারের সাথে ইন্টারনেট "লিঙ্ক" তৈরি করা;
                            </li>
                            <li>
                              <ul>
                                বিপরীত প্রকৌশলী বা সফ্টওয়্যার অ্যাক্সেস করার জন্য -
                                <li>একটি প্রতিযোগিতামূলক পণ্য বা পরিষেবা তৈরি করতে</li>
                                <li>অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যারের অনুরূপ ধারণা, বৈশিষ্ট্য, ফাংশন বা গ্রাফিক্স ব্যবহার করে একটি পণ্য তৈরি করতে</li>
                                <li>কোনও ধারণা অনুলিপি করতে,অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যারের বৈশিষ্ট্য, ফাংশন বা গ্রাফিক্স</li>
                              </ul>                            
                            </li>
                          <li>
                            ওয়েব স্পাইডার, ওয়েব ক্রলার, ওয়েব রোবট, ওয়েব পিঁপড়া, ওয়েব ইনডেক্সার, বট, ভাইরাস বা ওয়ার্ম, অথবা প্রতি সেকেন্ডে একাধিক সার্ভারের অনুরোধ করতে পারে এমন 
                            কোনো প্রোগ্রাম, বা অযথা বোঝা সহ একটি স্বয়ংক্রিয় প্রোগ্রাম বা স্ক্রিপ্ট চালু করা অথবা অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যারের অপারেশন এবং/অথবা কার্য সম্পাদনে বাধা দেয়;
                          </li>
                          <li>কোনো রোবট, ওয়েব স্পাইডার, সাইট অনুসন্ধান/পুনরুদ্ধার অ্যাপ্লিকেশন, বা অন্যান্য ম্যানুয়াল বা স্বয়ংক্রিয় ডিভাইস বা প্রক্রিয়া পুনরুদ্ধার, সূচী, "ডেটা মাইন" 
                            ব্যবহার করা, বা পরিষেবা বা এর বিষয়বস্তুগুলির ন্যাভিগেশনাল কাঠামো বা উপস্থাপনা যে কোনও উপায়ে পুনরুত্পাদন বা বাধা দিতে পারে;
                          </li>এই ধরনের মালিকানা অধিকারের মালিকের পূর্ব সম্মতি না নিয়ে যেকোনওভাবে কপিরাইটযুক্ত উপাদান, ট্রেডমার্ক বা অন্যান্য মালিকানা সংক্রান্ত তথ্য পোস্ট, বিতরণ বা 
                          পুনরুৎপাদন করা;
                          <li>পরিষেবাতে থাকা কোনও কপিরাইট, ট্রেডমার্ক বা অন্যান্য মালিকানা অধিকার বিজ্ঞপ্তিগুলি সরানো।
                          </li>
                        </ul>
                        <ul>
                          <b>আপনি সফ্টওয়্যার এবং/অথবা অ্যাপ্লিকেশনটি শুধুমাত্র আপনার ব্যক্তিগত, অ-বাণিজ্যিক উদ্দেশ্যে ব্যবহার করতে পারেন এবং সফ্টওয়্যার এবং/অথবা অ্যাপ্লিকেশনটি ব্যবহার করবেন না:</b>
                          <li>স্প্যাম বা অন্যথায় সদৃশ বা অযাচিত বার্তা পাঠানো;</li>
                          <li>শিশুদের জন্য ক্ষতিকারক বা তৃতীয় পক্ষের গোপনীয়তার অধিকার লঙ্ঘন করা সামগ্রী সহ অশ্লীল, হুমকি, মানহানিকর, বা অন্যথায় বেআইনি বা নির্যাতনমূলক সামগ্রী পাঠানো বা 
                            সঞ্চয় করা;</li>
                          <li>সফ্টওয়্যার ভাইরাস, ওয়ার্ম, ট্রোজান হর্স বা অন্যান্য ক্ষতিকারক কম্পিউটার কোড, ফাইল, স্ক্রিপ্ট, এজেন্ট বা প্রোগ্রাম ধারণকারী উপাদান পাঠানো;</li>
                          <li>সফ্টওয়্যার এবং/অথবা অ্যাপ্লিকেশন বা এতে থাকা ডেটার অখণ্ডতা বা কার্যকারিতা হস্তক্ষেপ বা ব্যাহত করা;</li>
                          <li>সফ্টওয়্যার এবং/অথবা অ্যাপ্লিকেশন বা এর সম্পর্কিত সিস্টেম বা নেটওয়ার্কগুলিতে অননুমোদিত অ্যাক্সেস লাভের প্রচেষ্টা; বা</li>
                          <li>যেকোন ব্যক্তি বা সত্তার ছদ্মবেশ ধারণ করা বা অন্যথায় HOLO TECH Ltd.-এর খ্যাতি বা অসম্মানজনক পরিমাণে ক্ষতি করতে পারে 
                            এমন কোনো আচরণ থেকে বিরত থাকার জন্য কোনো ব্যক্তি বা সত্তার সাথে আপনার সম্পর্ককে ভুলভাবে উপস্থাপন করা।</li>
                        </ul>
                        </div>
                      </li>
                      <li>
                        <strong>পেমেন্ট শর্তাবলী</strong>
                        <div>
                        পরিষেবার জন্য HOLO TECH Ltd আপনাকে চার্জ করতে পারে এমন কোনও ফি অবিলম্বে বকেয়া হবে এবং তা ফেরতযোগ্য নয় ("পরিষেবা ফি")৷ আপনার ব্যবহার বন্ধ করার সিদ্ধান্ত, 
                        আপনার ব্যবহার বন্ধ করার বা স্থগিত করার আমাদের সিদ্ধান্ত, পরিকল্পিত, দুর্ঘটনাজনিত বা ইচ্ছাকৃতভাবে, বা যে কোনও কারণে পরিষেবাতে সৃষ্ট ব্যাঘাত নির্বিশেষে এই নো-রিফান্ড নীতিটি 
                        সর্বদা প্রযোজ্য হবে। <br/>
                        প্রিপেইড প্যাকেজগুলির জন্য, আপনি সফ্টওয়্যার ব্যবহার করার আগে অর্থ প্রদান করবেন। পোস্টপেইড প্যাকেজগুলির জন্য, আপনি পরবর্তী প্যাকেজ কেনার আগে বকেয়া পরিশোধ করবেন। 
                        আপনি এই প্রয়োজনীয়তার তাৎপর্য বোঝেন এবং আপনি স্বীকার করেন যে পূর্বোক্ত প্রয়োজনীয়তা মেনে চলতে ব্যর্থতার জন্য আপনাকে কালো তালিকাভুক্ত করা হতে পারে এবং এই বিষয়ে 
                        যথাযথ সম্মতি না হওয়া পর্যন্ত আপনার পরিষেবা ব্যবহার করার ক্ষমতা নিষিদ্ধ করা হবে। <br/>
                        HOLO TECH Ltd. তার নিজস্ব বিবেচনার ভিত্তিতে যেকোনও যাত্রী বা গ্রাহকের কাছে বিভিন্ন বৈশিষ্ট্য এবং বিভিন্ন রেট সহ প্রচারমূলক অফার দিতে পারে যার ফলে এই প্রচারমূলক 
                        অফারগুলি আপনার দ্বারা সম্মানিত হবে৷ HOLO TECH Ltd. পরিষেবা ফি নির্ধারণ বা পরিবর্তন করতে পারে কারণ HOLO TECH Ltd. তার সম্পূর্ণ বিবেচনার ভিত্তিতে ব্যবসার জন্য প্
                        রয়োজনীয় বা উপযুক্ত বলে মনে করে৷
                        </div>
                      </li>
                      <li>
                        <strong>যাত্রী দ্বারা অর্থ প্রদান</strong>
                        <div>
                        যাত্রী বা গ্রাহক নগদ দ্বারা পরিষেবার জন্য অর্থ প্রদান করতে বেছে নিতে পারেন। <br/>
                        ড্রাইভার দ্বারা প্রদত্ত পরিবহন সম্পর্কে যাত্রী বা গ্রাহকের যে কোন অভিযোগ থাকলে তা যাত্রী বা গ্রাহক সরাসরি ড্রাইভারের কাছে প্রদান করবেন। <br/>
                        HOLO TECH Ltd. যেকোন লেনদেনের প্রক্রিয়াকরণ স্থগিত করার অধিকার ধরে রাখে যেখানে এটি যুক্তিসঙ্গতভাবে বিশ্বাস করে যে লেনদেনটি প্রতারণামূলক, অবৈধ বা কোনো অপরাধমূলক 
                        কার্যকলাপের সাথে জড়িত বা যেখানে এটি যুক্তিসঙ্গতভাবে বিশ্বাস করে যে যাত্রী বা গ্রাহকের মধ্যে শর্তাবলী লঙ্ঘন করা হয়েছে। যাত্রী বা গ্রাহক এবং HOLO TECH Ltd এই ধরনের 
                        ইভেন্টে, ড্রাইভারকে কোনো অর্থ প্রদানের জন্য HOLO TECH Ltd কে দায়বদ্ধ রাখা, বিলম্ব, স্থগিত বা বাতিল করার জন্য দায়ী করা হবে না। <br/>
                        আপনি (হয় ব্যবহারকারী বা ড্রাইভার হিসাবে), সম্মত হন যে আপনি যেকোন অপরাধ তদন্তের ক্ষেত্রে সহযোগিতা করবেন এবং HOLO TECH Ltd কে যেকোনো অভ্যন্তরীণ তদন্ত, 
                        কর্তৃপক্ষের নির্দেশাবলী বা প্রচলিত আইন বা প্রবিধানের প্রয়োজনীয়তা মেনে চলতে সহায়তা করবেন।
                        </div>
                      </li>
                      <li>
                        <strong>ট্যাক্স</strong>
                        <div>
                        আপনি সম্মত হন যে এই চুক্তিটি সমস্ত প্রচলিত বিধিবদ্ধ কর, শুল্ক, ফি, ​​চার্জ এবং/অথবা খরচের সাপেক্ষে হবে, যাইহোক নামকরণ করা হয়, যেমনটি বলবৎ হতে পারে এবং যে কোনো 
                        সময়ে প্রবর্তিত হতে পারে এমন ভবিষ্যতের করের সাথে সম্পর্কিত। আপনি আরও সম্মত হন এই চুক্তির অধীনে সরবরাহ করা পরিষেবাগুলির সাথে প্রদত্ত বা প্রদেয় যে কোন ইনপুট ট্যাক্স 
                        ক্রেডিট দাবি বা যাচাই করতে, সেট অফ, রিবেট বা যে কোনও করের ক্ষেত্রে ফেরত দেওয়ার জন্য HOLO TECH Ltdকে সক্ষম, সহায়তা এবং/অথবা রক্ষা করতে প্রাসঙ্গিক আইন দ্বারা 
                        প্রয়োজনীয় এবং প্রয়োজনীয় সবকিছু করার জন্য আপনার সর্বোত্তম প্রচেষ্টা ব্যবহার করতে। 
                        </div>
                      </li>
                      <li>
                        <strong>গোপনীয়তা</strong>
                        <div>
                        আপনি HOLO TECH Ltd., এর পরিষেবা, পণ্য, ব্যবসায়িক বিষয়, বিপণন এবং প্রচার পরিকল্পনা বা HOLO TECH Ltd. এবং এর সাথে সম্পর্কিত সংস্থাগুলির অন্যান্য ক্রিয়াকলাপগুলির 
                        সাথে সম্পর্কিত সমস্ত তথ্য এবং ডেটা আস্থার সাথে বজায় রাখবেন যা আপনার কাছে বা এর পক্ষে প্রকাশ করা হয়েছে HOLO TECH Ltd. (মৌখিকভাবে হোক বা লিখিতভাবে হোক এবং 
                        এই চুক্তির তারিখের আগে হোক বা পরে হোক) অথবা যা অন্যথায় প্রত্যক্ষ বা পরোক্ষভাবে আপনার দ্বারা HOLO TECH Ltd., বা এর কোনো অনুমোদিত কোম্পানি থেকে অর্জিত, 
                        অথবা এই চুক্তির কোর্স। আপনি আরও নিশ্চিত করবেন যে আপনি শুধুমাত্র পরিষেবাগুলি সম্পাদন করার জন্য এই ধরনের গোপনীয় তথ্য ব্যবহার করবেন এবং HOLO TECH Ltd. এর পূর্ব 
                        লিখিত সম্মতি ব্যতীত, এই ধরনের তথ্য কোনো তৃতীয় পক্ষের কাছে প্রকাশ করবেন না বা পরিষেবা প্রদান করা ছাড়া অন্য কোনো উদ্দেশ্যে ব্যবহার করবেন না। এই চুক্তির অধীনে তার 
                        বাধ্যবাধকতাগুলি পূরণ করার জন্য আপনি শুধুমাত্র এই ধরনের তথ্য এই ধরনের কর্মকর্তা, কর্মচারী এবং এজেন্টদের কাছে প্রকাশ করবেন। <br/>
                        <ul>
                        <b>গোপনীয়তার উপরোক্ত বাধ্যবাধকতাগুলি নিম্নোক্ত তথ্যগুলির ক্ষেত্রে প্রযোজ্য হবে না, আপনি প্রাসঙ্গিক তথ্যগুলি দেখাতে পারেন:</b>
                          <li>যা রিসিপ্ট প্রাপ্তির সময় ইতিমধ্যেই প্রাপকের দখলে ছিল;</li>
                          <li>যা জনসাধারন বর্তমান বা ভবিষ্যতে জানতে পারে;</li>
                          <li>এটি প্রকাশ করার অধিকার থাকা তৃতীয় পক্ষের কাছ থেকে প্রাপ্ত হয়েছিল; বা
                          আইন দ্বারা প্রকাশ করা আবশ্যক।</li>
                        </ul>
                        </div>
                      </li>
                      <li>
                        <strong>ব্যক্তিগত তথ্য সুরক্ষা</strong>
                        <div>
                        আপনি সম্মত এবং স্বেচ্ছায় সম্মতি দিচ্ছেন যে এখানে যেভাবে চিহ্নিত করা হয়েছে সেই উদ্দেশ্যগুলির জন্য HOLO TECH Ltd আপনার ব্যক্তিগত ডেটা ব্যবহার এবং প্রক্রিয়াকরণ করতে 
                        পারবে। <br/>
                        এই চুক্তির উদ্দেশ্যে, "ব্যক্তিগত ডেটা" মানে আপনার সম্পর্কে তথ্য, যেখান থেকে আপনি শনাক্তযোগ্য, আপনার নাম, শনাক্তকরণ কার্ড নম্বর, জন্ম নিবন্ধন নম্বর, পাসপোর্ট নম্বর, 
                        জাতীয়তা, ঠিকানা, টেলিফোন নম্বর, ক্রেডিট বা ডেবিট কার্ডের বিশদ বিবরণ, জাতি, লিঙ্গ, জন্ম তারিখ, ইমেল ঠিকানা, আপনার সম্পর্কে যেকোন তথ্য এবং এতে স্বাস্থ্য, ধর্মীয় বা 
                        অন্যান্য অনুরূপ বিশ্বাস সম্পর্কিত ডেটার মতো সংবেদনশীল ব্যক্তিগত ডেটা অন্তর্ভুক্ত থাকে, যা আপনি রেজিস্ট্রেশন ফর্ম, আবেদনপত্র বা অন্য কোন অনুরূপ ফর্মগুলিতে HOLO TECH Ltd. 
                        কে প্রদান করেছেন এবং/অথবা আপনার সম্পর্কে যে কোন তথ্য সময়ে সময়ে HOLO TECH Ltd দ্বারা সংগ্রহ, সংরক্ষণ, ব্যবহার এবং প্রক্রিয়া করা হয়েছে। <br/>
                        যাইহোক, যদি আপনি HOLO TECH Ltd. কে আপনার ব্যক্তিগত ডেটা প্রদান না করেন, তাহলে আবেদনের জন্য আপনার অনুরোধটি অসম্পূর্ণ হতে পারে এবং HOLO TECH Ltd. নীচে 
                        বর্ণিত উদ্দেশ্যগুলির জন্য আপনার ব্যক্তিগত ডেটা প্রক্রিয়া করতে সক্ষম হবে না এবং HOLO TECH Ltd. আপনাকে পরিষেবা ব্যবহার করার অনুমতি দিতে অক্ষম।
                          <ul>
                            <b>HOLO TECH Ltd.- এর ব্যবসা এবং ক্রিয়াকলাপের জন্য আপনার ব্যক্তিগত ডেটা ব্যবহার এবং প্রক্রিয়া করতে পারে যার মধ্যে নিম্নলিখিতগুলি ("উদ্দেশ্য") অন্তর্ভুক্ত থাকবে:</b>
                            <li>
                            আপনার সাথে সম্পাদিত যেকোনো চুক্তির ক্ষেত্রে HOLO TECH Ltd. এর দায়িত্ব পালন করা;
                            </li>
                            <li>
                            এখানকার নিয়ম ও শর্তাবলী অনুসারে আপনাকে যেকোনো পরিষেবা প্রদান করা;
                            </li>
                            <li>
                            যেকোনো ইভেন্ট, প্রচার, কার্যক্রম, ফোকাস গ্রুপ, গবেষণা অধ্যয়ন, প্রতিযোগিতা, প্রচার, পোল, জরিপ বা যে কোনো প্রোডাকশনে আপনার অংশগ্রহণ প্রক্রিয়াকরণ 
                            এবং আপনার উপস্থিতি সম্পর্কে আপনার সাথে যোগাযোগ করা;
                            </li>
                            <li>
                            এখানে দেওয়া শর্তাবলী অনুসারে পরিষেবার জন্য আপনার আবেদন প্রক্রিয়া, পরিচালনা বা যাচাই করা;
                            </li>
                            <li>
                            এখানে দেওয়া শর্তাবলী অনুসারে অর্থপ্রদান যাচাই এবং/অথবা প্রক্রিয়া করা;
                            </li>
                            <li>
                            আপনার প্রয়োজন মেটানোর জন্য এখানে শর্তাবলী অনুসারে যা প্রয়োজন তা বিকাশ, উন্নত এবং প্রদান করা;
                            </li>
                            <li>
                            এখানকার নিয়ম ও শর্তাবলী অনুযায়ী কোনো ফেরত, রিবেট এবং/অথবা চার্জ প্রক্রিয়া করা;
                            </li>
                            <li>
                            এখানকার নিয়ম ও শর্তাবলী অনুসারে প্রয়োজনীয় যেকোন পরীক্ষা সহজতর করা বা সক্ষম করা;
                            </li>
                            <li>
                            আপনার কাছ থেকে প্রশ্ন, মন্তব্য এবং প্রতিক্রিয়ার উত্তর দেয়া;
                            </li>
                            <li>
                            এখানে তালিকাভুক্ত যেকোনো উদ্দেশ্যে আপনার সাথে যোগাযোগ করা;
                            </li>
                            <li>
                            অভ্যন্তরীণ প্রশাসনিক উদ্দেশ্যে, যেমন অডিটিং, ডেটা বিশ্লেষণ, ডাটাবেস রেকর্ড;
                            </li>
                            <li>
                            অপরাধ সনাক্তকরণ, প্রতিরোধ এবং বিচারের উদ্দেশ্যে;
                            </li>
                            <li>
                            HOLO TECH Ltd. আইনের অধীনে তার বাধ্যবাধকতা মেনে চলার জন্য;
                            </li>
                            <li>
                            HOLO TECH Ltd., এর অংশীদার, বিজ্ঞাপনদাতা এবং/অথবা স্পনসরদের কাছ থেকে আপনাকে সতর্কতা, নিউজলেটার, আপডেট, মেইলার, 
                            প্রচারমূলক সামগ্রী, বিশেষ সুবিধা, উৎসবের শুভেচ্ছা পাঠানোর জন্য;
                            </li>
                            <li>
                            HOLO TECH Ltd., এর অংশীদার, বিজ্ঞাপনদাতা এবং/অথবা স্পনসর দ্বারা সংগঠিত ইভেন্ট বা কার্যকলাপে আপনাকে অবহিত করতে এবং আমন্ত্রণ জানাতে;
                            </li>
                            <li>
                            HOLO TECH Ltd.-এর কোম্পানীর অন্তর্গত কোম্পানীর মধ্যে আপনার ব্যক্তিগত ডেটা ভাগ করে নেওয়ার জন্য যেগুলি হল গ্রুপের (“দ্য গ্রুপ”) হোল্ডিং HOLO TECH Ltd এর 
                            সহযোগী সংস্থা, সহযোগী কোম্পানি এবং/অথবা যৌথভাবে নিয়ন্ত্রিত সত্তা এবং HOLO TECH Ltd এর সাথে এবং গ্রুপের এজেন্ট, তৃতীয় পক্ষের প্রদানকারী, বিকাশকারী, 
                            বিজ্ঞাপনদাতা, অংশীদার, ইভেন্ট কোম্পানি বা স্পনসর যারা যেকোনো কারণে আপনার সাথে যোগাযোগ করতে পারে।
                            </li>
                          </ul>
                          আপনি যদি HOLO TECH Ltd.-কে কোনো উদ্দেশ্যের জন্য আপনার ব্যক্তিগত ডেটা প্রক্রিয়াকরণে সম্মত না হন, তাহলে অনুগ্রহ করে HOLO TECH Ltd.-কে অবহিত করুন। <br/>
                          যদি আপনার আমাদের প্রদান করা ব্যক্তিগত ডেটার কোনো পরিবর্তন হয়, উদাহরণস্বরূপ, আপনি যদি আপনার ই-মেইল ঠিকানা, টেলিফোন নম্বর, অর্থপ্রদানের বিবরণ পরিবর্তন করেন 
                          বা আপনি যদি আপনার অ্যাকাউন্ট বাতিল করতে চান, তাহলে অনুগ্রহ করে আপনার অনুরোধ অ্যাপ্লিকেশনে প্রদত্ত ঠিকানায় পাঠিয়ে আপনার বিশদ আপডেট করুন । HOLO TECH Ltd. 
                          আমাদের সামর্থ্য অনুযায়ী, পরিবর্তনের নোটিশ প্রাপ্তির চৌদ্দ (14) কার্যদিবসের মধ্যে অনুরোধ করা পরিবর্তনগুলিকে কার্যকর করবে৷<br/>
                          আপনার তথ্য জমা দেওয়ার মাধ্যমে, আপনি জমা দেওয়ার ফর্মে এবং এই চুক্তিতে সেট করা তথ্যের ব্যবহারে সম্মতি দিচ্ছেন।
                        </div>
                      </li>
                      <li>
                      <strong>তৃতীয় পক্ষের মিথস্ক্রিয়া</strong>
                        <div>
                        পরিষেবার মেয়াদকালে, আপনি পরিষেবা, সফ্টওয়্যার এবং/অথবা অ্যাপ্লিকেশনের মাধ্যমে তৃতীয় পক্ষের প্রদানকারী, বিজ্ঞাপনদাতা বা স্পনসরদের তাদের পণ্য এবং/অথবা পরিষেবাগুলি 
                        দেখানোর সাথে চিঠিপত্রে প্রবেশ করতে, পরিষেবা প্রদান করতে বা প্রচারে অংশগ্রহণ করতে পারেন৷ এই ধরনের যেকোন কার্যকলাপ, এবং এই ধরনের কার্যকলাপের সাথে সম্পর্কিত যে 
                        কোন শর্ত, শর্ত, ওয়ারেন্টি বা উপস্থাপনা শুধুমাত্র আপনার এবং তৃতীয় পক্ষের মধ্যে প্রযোজ্য। HOLO TECH Ltd. এবং এর লাইসেন্সদাতাদের আপনার এবং এই জাতীয় কোনও 
                        তৃতীয়-পক্ষের মধ্যে এই জাতীয় কোনও চিঠিপত্র, ক্রয়, লেনদেন বা প্রচারের জন্য কোনও দায়, বাধ্যবাধকতা বা দায়িত্ব থাকবে না। HOLO গ্রুপ ইন্টারনেটে পরিষেবা, অ্যাপ্লিকেশন 
                        এবং/অথবা সফ্টওয়্যারের মাধ্যমে লিঙ্কযুক্ত কোনও অ্যাপ্লিকেশন বা সাইটকে সমর্থন করে না এবং কোনও ক্ষেত্রেই HOLO TECH Ltd., এর লাইসেন্সদাতা বা গোষ্ঠী কোনও সামগ্রী, পণ্য, 
                        পরিষেবার জন্য দায়ী থাকবে না বা অন্যান্য উপকরণ এই ধরনের সাইট বা তৃতীয় পক্ষ প্রদানকারীদের থেকে পাওয়া যায়। HOLO TECH Ltd. আপনাকে শর্তাবলী মেনে পরিষেবা প্রদান 
                        করে। যাইহোক, আপনি স্বীকার করেছেন যে পরিবহন, পণ্য এবং/অথবা পরিষেবাগুলির নির্দিষ্ট তৃতীয়-পক্ষ প্রদানকারীরা আপনার এই ধরনের পণ্য বা পরিষেবাগুলি ব্যবহার বা অ্যাক্সেস 
                        করার আগে অতিরিক্ত বা ভিন্ন শর্তাবলীতে আপনার চুক্তির প্রয়োজন হতে পারে এবং HOLO TECH Ltd. এখানে কোনো পক্ষ নয়, HOLO TECH Ltd. আপনার এবং তৃতীয় পক্ষের 
                        প্রদানকারীদের মধ্যে এই ধরনের চুক্তি থেকে উদ্ভূত কোনো এবং সমস্ত দায়িত্ব এবং/অথবা দায়বদ্ধতা অস্বীকার করে।
                        </div>
                      </li>
                      <li>
                        <strong>ক্ষতিপূরণ</strong>
                        <div>
                          {" "}
                          পরিষেবা প্রদানের জন্য এই চুক্তিতে সম্মত হয়ে, আপনি সম্মত হন যে আপনি HOLO TECH Ltd., এর লাইসেন্সদাতাদের এবং এই জাতীয় প্রতিটি পক্ষের মূল সংস্থা, সহায়ক, 
                          সহযোগী, কর্মকর্তা, পরিচালক, সদস্য, কর্মচারী, অ্যাটর্নি এবং এজেন্টদের যে কোন এবং সমস্ত দাবি, খরচ, ক্ষয়ক্ষতি, ক্ষতি, দায় এবং খরচ (অ্যাটর্নিদের ফি এবং খরচ সহ) থেকে 
                          এবং এর বিরুদ্ধে বা এর সাথে সম্পর্কিত, রক্ষা করবেন, ক্ষতিপূরণ দেবেন এবং ধরে রাখবেন এবং:
                        </div>
                        <ul>
                          <li dir="ltr">
                          এখানে উল্লেখ করা হোক বা না হোক, এই শর্তাবলীর কোনো শর্ত বা কোনো প্রযোজ্য আইন বা প্রবিধান, কোনো স্থানীয় আইন বা অধ্যাদেশ সহ যে কোন শর্ত আপনার লঙ্ঘন বা 
                          অমান্য করার ক্ষেত্রে;
                          </li>
                          <li dir="ltr">
                          যে কোনো তৃতীয় পক্ষের কোনো অধিকারের লঙ্ঘন, যার মধ্যে আপনার গাড়ির যাত্রী বা আপনার নিয়ন্ত্রণে থাকা যান, অন্যান্য মোটরচালক এবং পথচারীদের মধ্যে সীমাবদ্ধ নয়, 
                          যে কোনো তৃতীয় পক্ষের সাথে আপনার নিজের মিথস্ক্রিয়ার ফলে;
                          </li>
                          <li dir="ltr">
                          আপনার অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার ব্যবহার (বা অপব্যবহার); এবং
                          </li>
                          <li dir="ltr">
                          আপনার মালিকানা, একটি মোটর গাড়ি বা যাত্রীবাহী যানের ব্যবহার বা পরিচালনা, আপনার বহনকারী যাত্রী বা গ্রাহক যারা পরিষেবার মাধ্যমে আপনার পরিবহন পরিষেবাগুলি সংগ্রহ 
                          করেছেন, বা তাদের পণ্যগুলি সহ।
                          </li>
                        </ul>
                      </li>
                      <li>
                        <strong>ওয়্যারেন্টি অস্বীকৃতি</strong>
                        <div>
                       <ul>   
                        THE HOLO TECH Ltd. পরিষেবার নির্ভরযোগ্যতা, সময়ানুবর্তিতা, গুণমান, উপযোগীতা, প্রাপ্যতা, নির্ভুলতা বা পরিপূর্ণতা, পরিষেবা/অ্যাপ্লিকেশন সম্পর্কে কোনও প্রতিনিধিত্ব, ওয়্যারেন্টি, 
                        বা গ্যারান্টি দেয় না৷ HOLO TECH Ltd. প্রতিনিধিত্ব করে না বা ওয়ারেন্টি দেয় না যে:
                        <li>পরিষেবার ব্যবহার, অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার নিরাপদ, সময়মত, নিরবচ্ছিন্ন বা ত্রুটি-মুক্ত বা অন্যরকমভাবে পরিচালনা করা হবে </li>                         
                        <li>পরিষেবাটি আপনার প্রয়োজনীয়তা বা প্রত্যাশা পূরণ করবে </li>                         
                        <li>যেকোনও সংরক্ষিত ডেটা সঠিক বা নির্ভরযোগ্য হবে </li>                         
                        <li>যেকোন পণ্যের গুণমান, পরিষেবা, তথ্য, বা অন্যান্য তথ্য-উপাত্তের সাথে সম্পর্কিত তথ্য আপনার প্রয়োজনীয়তা বা প্রত্যাশা পূরণ করবে </li>                         
                        <li>অ্যাপ্লিকেশনে ত্রুটি বা ত্রুটিগুলি এবং/অথবা সফ্টওয়্যার সংশোধন করা হবে অথবা </li>                         
                        <li>অ্যাপ্লিকেশন বা সার্ভার(গুলি) যেগুলি অনুগ্রহ করে তা অনুমোদন করা হয়েছে অথবা </li>                         
                        <li>অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার আপনার বা পরিবহন প্রদানকারীর দ্বারা ব্যবহৃত যানবাহন ট্র্যাক করে। </li>                         
                        </ul>
                        পরিষেবাটি আপনাকে "যেমন আছে" ভিত্তিতে কঠোরভাবে প্রদান করা হয়েছে৷ সমস্ত শর্ত, উপস্থাপনা এবং ওয়্যারেন্টি, এক্সপ্রেস, নিহিত, বিধিবদ্ধ বা অন্যথায়, সীমাবদ্ধতা ছাড়াই, 
                        বণিকতার কোনও অন্তর্নিহিত ওয়ারেন্টি, কোনও নির্দিষ্ট উদ্দেশ্যে ফিটনেস, বা তৃতীয় পক্ষের অধিকারের অ-ইনফ্রিজিং, এর দ্বারা এখানে বাদ দেওয়া হয়েছে এবং সর্বোচ্চ সীমা পর্যন্ত 
                        অস্বীকার করা হয়েছে । <br/>
                        THE HOLO TECH Ltd. পরিষেবা, অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার এর মাধ্যমে আপনার দ্বারা গ্রাহকরা বা যাত্রীদের সরবরাহকৃত পরিবহন পরিষেবাসহ কোনও পরিষেবাগুলির 
                        নির্ভরযোগ্যতা, সুরক্ষা, সময়োপযোগীতা, গুণমান, উপযুক্ততা বা প্রাপ্যতা সম্পর্কে কোনও প্রতিনিধিত্ব, ওয়্যারেন্টি বা গ্যারান্টি দেয় না। আপনি স্বীকার করেন এবং সম্মত হন যে 
                        পরিষেবাগুলির এই ধরনের ব্যবহারের ফলে উদ্ভূত সমস্ত ঝুঁকি শুধুমাত্র এবং সম্পূর্ণরূপে আপনার সাথেই রয়ে যায় এবং আপনার THE HOLO TECH Ltd. বিরুদ্ধে যাওয়ার কোনো 
                        সুযোগ থাকবে না৷
                        </div>
                      </li>
                      <li>
                        <strong>ইন্টারনেট বিলম্ব</strong>
                        <div>
                        পরিষেবা, অ্যাপ্লিকেশন এবং/অথবা সফ্টওয়্যার সীমাবদ্ধতা, বিলম্ব, এবং অন্যান্য সমস্যাগুলির সাপেক্ষে অন্তর্নিহিত ইন্টারনেট এবং ইলেকট্রনিক যোগাযোগের অন্তর্নিহিত সমস্যাগুলির 
                        সাথে যুক্ত হতে পারে, বা আপনার ব্যাবহৃত ত্রুটিপূর্ণ যন্ত্রের জন্য হতে পারে। এই ধরনের সমস্যার ফলে যেকোন বিলম্ব, ডেলিভারী ব্যর্থতা, ক্ষয়ক্ষতি বা ক্ষতির জন্য HOLO TECH Ltd. 
                        দায়ী নয়।
                        </div>
                      </li>
                      <li>
                        <strong>দায়বদ্ধতার সীমাবদ্ধতা</strong>
                        <div>
                        আপনার দ্বারা HOLO TECH Ltd এর বিরুদ্ধে যে কোনও দাবি যে কোনও ক্ষেত্রে পরিষেবাটি চালু করার সময় আপনার দ্বারা প্রদত্ত এবং/অথবা বকেয়া সমস্ত পরিমাণের মোট পরিমাণের 
                        মধ্যে সীমাবদ্ধ থাকবে ৷ কোনো অবস্থাতেই HOLO TECH Ltd. এবং/অথবা এর লাইসেন্সদাতারা জীবন বা অঙ্গ-প্রত্যঙ্গের ক্ষয়ক্ষতি সহ যেকোন ধরনের আঘাত, মানসিক যন্ত্রণা এবং ডেটা, 
                        পণ্য, রাজস্ব, লাভ, অন্যান্য অর্থনৈতিক ক্ষতি),কোনো প্রত্যক্ষ, পরোক্ষ, শাস্তিমূলক, অর্থনৈতিক, ভবিষ্যত বিশেষ, দৃষ্টান্তমূলক, আনুষঙ্গিক অন্য কোনো ঘটনার জন্য আপনার বা কারও 
                        কাছে দায়বদ্ধ হবে না। HOLO TECH Ltd এবং/অথবা এর লাইসেন্সদাতারা আপনার বা যাদের জন্য আপনি বুকিং করে রেখেছেন বা যাদের জন্য বুক করা হয়েছে, সেগুলির জন্য 
                        আপনার বা যে কোনও ব্যক্তির দ্বারা হতে পারে এমন কোনও ক্ষতি, ক্ষতি বা আঘাতের জন্য দায়বদ্ধ থাকবে না অথবা পরিষেবা, আবেদন এবং/অথবা সফ্টওয়্যারের সাথে সংযুক্ত 
                        যেকোন উপায়ে উদ্ভূত আঘাত, কিন্তু পরিষেবাটি ব্যবহার বা ব্যবহারে অক্ষমতার মধ্যে সীমাবদ্ধ নয়, অ্যাপ্লিকেশন এবং অনুমোদন কোনো বিজ্ঞাপনের সম্পূর্ণতা, নির্ভুলতা বা অস্তিত্ব, 
                        অথবা আপনার এবং যে কোনো গ্রাহক, যাত্রী বা তৃতীয় পক্ষের আবেদন এবং/অথবা স্বয়ংসম্পূর্ণ প্রাপ্তবয়স্ক, লিমিটেডের মধ্যে কোনো সম্পর্ক বা লেনদেনের ফলস্বরূপ এই ধরনের 
                        ক্ষতির সম্ভাবনার যে কোনও ইভেন্টে, HOLO TECH Ltd এর কোনও ক্ষতি, আঘাত, গ্রাহক বা যাত্রীর দ্বারা যে কোনও ধরণের ক্ষতি, প্রত্যক্ষ বা অপ্রত্যক্ষভাবে যে কোনও ধরণের 
                        ক্ষতি, আপনার কাজ বা বাদ দেওয়া বা নিয়ন্ত্রণের অভাবের কারণে কোনও ধরণের ক্ষতি, কোনও ক্ষতি বা দায়বদ্ধতা থাকবে না যানবাহন বা রাস্তা সম্পর্কে সচেতনতা বা অন্য কোনো 
                        কারণ যা পরিষেবা চলাকালীন আপনার জন্য দায়ী ৷ <br/> 
                        একইভাবে, এবং যে কোনো ক্ষেত্রে, HOLO TECH Ltd.কে কোনো প্রকার ক্ষতি, আঘাত, যেকোনো ধরনের ক্ষতিসাধনের জন্য দায়ী বা দায়বদ্ধ করা হবে না অথবা একজন গ্রাহক কোনও 
                        ইভেন্টে, HOLO TECH Ltd.কে পুলিশ বা অন্যান্য আইন প্রয়োগকারী কর্তৃপক্ষের দ্বারা ফৌজদারি তদন্তের জন্য, আপনার ক্রিয়াকলাপ বা নিষ্ক্রিয়তার জন্য বা কোনও যাত্রী বা গ্রাহকের 
                        ক্রিয়াকলাপ বা নিষ্ক্রিয়তার জন্য, তবে কোনওভাবেই দায়বদ্ধ বা দায়বদ্ধ হবে না, এই শর্তাবলী এবং শর্তাবলী এবং বিশেষভাবে এই প্রতিনিধিত্ব এবং ওয়্যারেন্টিসমূহের মাধ্যমে তৈরি 
                        আপনার প্রতিনিধিত্ব, ওয়্যারেন্টি এবং স্বীকৃতির লঙ্ঘনের জন্য।
                        </div>
                      </li>
                      <li>
                        <strong>ঐচ্ছিক বীমা প্রিমিয়াম</strong>
                        <div>
                        আপনার নিজস্ব বিবেচনার ভিত্তিতে, আপনি বীমা কোম্পানিগুলির সাথে HOLO TECH Ltd. এর সুসম্পর্কের সুবিধাগুলি বেছে নিতে পারেন এবং আপনার গাড়ির ক্ষতি বা নিজের এবং 
                        অন্যদের ক্ষতির বিরুদ্ধে একটি ঐচ্ছিক বীমা কভারেজ সুরক্ষিত করতে পারেন, যেমন HOLO TECH Ltd সময়ে সময়ে যে সুবিধাগুলি অফার করে । আপনি এই ধরনের বিকল্প বেছে 
                        নেওয়ার ক্ষেত্রে, HOLO TECH Ltd এর সফ্টওয়্যার, অ্যাপ্লিকেশন এবং/অথবা পরিষেবা ব্যবহারের জন্য উল্লিখিত পরিষেবা ফি ছাড়াও প্রাসঙ্গিক বীমা প্রিমিয়াম অবিলম্বে আপনার দ্বারা 
                        HOLO TECH Ltdকে প্রদান করা হবে। 
                        </div>
                      </li>
                      <li>
                        <strong>বিজ্ঞপ্তি</strong>
                        <div>
                        HOLO TECH Ltd. অ্যাপ্লিকেশনে একটি সাধারণ নোটিশের মাধ্যমে অথবা HOLO TECH Ltd.-এর রেকর্ডে আপনার ইমেল ঠিকানায় ইলেকট্রনিক মেইল, অথবা HOLO TECH Ltd.-এর 
                        রেকর্ডে আপনার ঠিকানায় নিবন্ধিত মেইল ​​বা প্রি-পেইড পোস্টের মাধ্যমে পাঠানো লিখিত যোগাযোগের মাধ্যমে নোটিশ দিতে পারে। এই ধরনের নোটিশ দেওয়া হয়েছে বলে মনে করা হবে 
                        মেইলিং বা পোস্ট করার 48 ঘন্টার মেয়াদ শেষ হয় (যদি নিবন্ধিত মেইল ​​বা প্রি-পেইড পোস্ট দ্বারা পাঠানো হয়) বা পাঠানোর 1 ঘন্টা পরে (যদি ইমেল দ্বারা পাঠানো হয়)। আপনি 
                        HOLO TECH Ltd. কে কুরিয়ারে বা নিবন্ধিত মেইলের মাধ্যমে নোটিশ দিতে পারেন।
                        </div>
                      </li>
                      <li>
                        <strong>অনুমোদন</strong>
                        <div>
                        সময়ে সময়ে পরিবর্তিত নিয়ম ও শর্তাবলী দ্বারা গঠিত চুক্তিটি HOLO TECH Ltd এর পূর্ব লিখিত অনুমোদন ব্যতীত আপনার দ্বারা বরাদ্দ করা নাও হতে পারে তবে HOLO TECH Ltd 
                        আপনার সম্মতি ছাড়াই বরাদ্দ করতে পারে। এই শর্তের ধারা লঙ্ঘন করে এমন কোন অনুমোদন বাতিল করা হবে।
                        </div>
                      </li>
                      <li>
                        <strong>বিবিধ</strong>
                        <div>
                        এই চুক্তিটি আপনার নিজের দেশের আইন দ্বারা পরিচালিত হবে, কোনো এখতিয়ারের আইনের বিধানের পছন্দ বা দ্বন্দ্ব বিবেচনা না করেই, এবং এই চুক্তি বা পরিষেবা থেকে বা এর সাথে 
                        সম্পর্কিত যে কোনো বিরোধ, ক্রিয়া, দাবি বা কর্মের কারণ আপনার নিজের দেশের আদালতের একচেটিয়া এখতিয়ারের সাপেক্ষে বাধ্যতামূলক আনুগত্যের ক্লান্তি এবং সালিসের পদ্ধতির 
                        সম্মতি হিসেবে বিবেচিত হবে। এছাড়াও, আপনি HOLO TECH Ltd এর উৎপত্তির দেশের প্রযোজ্য আইন, বিধি ও প্রবিধান দ্বারা আবদ্ধ হবেন যেখানে এটি নিবন্ধিত হয়েছে যতটা এটি 
                        প্রযোজ্য এবং আপনার স্থানীয় আঞ্চলিক অধিক্ষেত্রের আইন ও প্রবিধানের সাথে সামঞ্জস্যপূর্ণ। দ্বিপাক্ষিক চুক্তি, আন্তর্জাতিক ব্যবসা এবং বাণিজ্যিক আইন সহ সবক্ষেত্রেই বিবেচিত হবে। 
                        <br/>
                        অধিকন্তু, যদি আপনার নিজের দেশের আইনটি HOLO TECH Ltd এর মূল দেশের আদালতের এখতিয়ারের অনুমতি দেয় না যেখানে এটি নিবন্ধিত হয় তবে শর্ত থাকে যে যেখানে এই 
                        ধরনের আদালতের রায় আপনার নিজের দেশে এবং অঞ্চলে প্রয়োগযোগ্য নয় , অমীমাংসিত বিরোধগুলি বাংলাদেশ আন্তর্জাতিক সালিসি কেন্দ্রে (“BIAC”) রেফার করা হবে, BIAC-এর 
                        বিধি অনুসারে সময়ে সময়ে পরিবর্তিত বা সংশোধিত (“বিধি”) পারস্পরিক চুক্তির দ্বারা নিযুক্ত একমাত্র সালিসকারী দ্বারা। পক্ষগুলি ("সালিশকারী")। যদি দলগুলো কোনো সালিসের 
                        বিষয়ে একমত হতে না পারে, তাহলে সালিশকারীকে BIAC-এর সভাপতি নিয়ম অনুযায়ী নিযুক্ত করবেন।
                        <br/>
                        এই চুক্তির উদ্দেশ্যে এবং এর আগে এখানে প্রদত্ত সালিসের বিধানের জন্য, সালিশের আসন এবং স্থান ঢাকায় হবে, ইংরেজি ভাষায় এবং সালিসকারীর ফি উভয় পক্ষ সমানভাবে বহন 
                        করবে, তবে শর্ত থাকে যে সালিসকারীর প্রয়োজন হতে পারে। যে এই ধরনের ফি এই ধরনের সালিসি ধারা প্রযোজ্য আইনের অধীনে প্রয়োগযোগ্য হওয়ার জন্য সালিসি নির্ধারণ করা 
                        প্রয়োজন।
                        <br/>
                        তবে শর্ত থাকে যে, HOLO TECH Ltd এর বিবেচনার ভিত্তিতে এই চুক্তির অধীনে সমস্যা, বিরোধ ইত্যাদির কার্যকর এবং দ্রুত সমাধানের জন্য সালিসকারীর পাশাপাশি সালিসের স্থান 
                        পরিবর্তন করা যেতে পারে।
                        <br/>
                        এই চুক্তি বা পরিষেবার ব্যবহারের ফলে আপনার, HOLO TECH Ltd বা কোনও তৃতীয়-পক্ষ প্রদানকারীর মধ্যে কোনও যৌথ উদ্যোগ, অংশীদারিত্ব, কর্মসংস্থান বা সংস্থার সম্পর্ক বিদ্যমান 
                        নয়।
                        <br/>
                        যদি চুক্তির কোনো বিধান অবৈধ বা অপ্রয়োগযোগ্য বলে ধরা হয়, তাহলে এই ধরনের বিধানকে বাতিল করা হবে এবং অবশিষ্ট বিধানগুলি আইনের অধীনে সম্পূর্ণ পরিমাণে প্রয়োগ করা 
                        হবে। এটি, সীমাবদ্ধতা ছাড়াই, উপরে বর্ণিত প্রযোজ্য আইন এবং এখতিয়ারের ক্ষেত্রেও প্রযোজ্য হবে৷
                        <br/>
                        চুক্তিতে কোনো অধিকার বা বিধান কার্যকর করতে HOLO TECH Ltd এর ব্যর্থতা এই ধরনের অধিকার বা বিধানের মওকুফ গঠন করবে না যদি না HOLO TECH Ltd লিখিতভাবে 
                        স্বীকৃত এবং সম্মত হয়। চুক্তিটি আপনার এবং HOLO TECH Ltd এর মধ্যে সম্পূর্ণ চুক্তিকে অন্তর্ভুক্ত করে এবং এখানে থাকা বিষয়বস্তু সম্পর্কিত পক্ষগুলির মধ্যে লিখিত বা মৌখিক 
                        (যদি থাকে) সমস্ত পূর্ববর্তী বা সমসাময়িক আলোচনা বা আলোচনাকে বাতিল করে।
                        <br/>
                        আপনি এতদ্বারা সম্মত হন যে এই চুক্তির অধীনে আপনার দায়িত্ব পালনের সময় HOLO TECH Ltd. অবিলম্বে এই চুক্তিটি বাতিল করার অধিকারী যদি আপনি এই চুক্তিতে প্রদত্ত যে 
                        কোনও শর্ত লঙ্ঘন করেন। সন্দেহ এড়ানোর জন্য, এই চুক্তির অবসানের জন্য HOLO TECH Ltd এর আপনার দ্বারা সংরক্ষিত ক্রেডিট বা অন্য যেকোন অর্থ প্রদান সহ এমন কোনো 
                        খরচ করতে হবে না যা ক্ষতিপূরণ, পরিশোধ বা কভার করে। 
                        </div>
                      </li>
                    </ol>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterBlack />
      </>
    );
  }
}

export default TermsBangla;
