import React from "react";
import { Link } from "react-router-dom";
import FooterBlack from "../components/footerblack";
import HeaderBlack from "../components/headerblack";
import loadjs from "loadjs";
// import Preloader from "../components/preloader";
import MobileNav from "../components/mobilenav";

class News extends React.Component {
  componentDidMount() {
    loadjs("./js/main.js");

    loadjs("./js/plugins.js");
  }

  render() {
    return (
      <>
        <HeaderBlack />
        <MobileNav />
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">News Details</h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>News Details</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* PAGE DETAILS AREA START (blog-details) */}
        <div className="ltn__page-details-area ltn__blog-details-area mb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="ltn__blog-details-wrap">
                  <div className="ltn__page-details-inner ltn__blog-details-inner">
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-category">
                          <Link to="#">Service</Link>
                        </li>
                      </ul>
                    </div>
                    <h2 className="ltn__blog-title">The Journey of Holo</h2>
                    <div className="ltn__blog-meta">
                      <ul>
                        <li className="ltn__blog-author">
                          <Link to="/">
                            <img src="img/Holo-Logo-Icon.png" alt="#" />
                            By: Admin
                          </Link>
                        </li>
                        <li className="ltn__blog-date">
                          <i className="far fa-calendar-alt" />
                          January, 2022
                        </li>
                      </ul>
                    </div>
                    <p>
                      Ride sharing is a relatively new, yet very popular concept
                      in Bangladesh. Holo is one of the newest edition in the
                      ride sharing industry of the country. The concept of Holo
                      started forming in order to solve the existing problems of
                      the ride sharing industry of Bangladesh. The aim of this
                      company is to find out and eliminate the biggest issue in
                      this industry in Bangladesh - drop of rides in digital
                      platforms.{" "}
                    </p>
                    <p>
                      The concept of ride sharing in digital platforms is not
                      something new in the world, yet the conventional method
                      that has been established in a lot of western countries
                      has not always been proven effective in Bangladesh due to
                      cultural differences. Hence, the concept of Holo came,
                      which was customized keeping the cultural and social norms
                      of people in mind. Holo intends to provide service in such
                      a manner that both the riders and the users become
                      satisfied with their experiences.
                    </p>
                    <img src="img/Holo-Logo-Black.png" alt="Icon" />
                    <h2>We Care for Your Needs</h2>
                    <p>
                      Commuting to workplaces and educational institutions is a
                      daily necessity, yet the number of offline (without app)
                      rides are skyrocketing, even when it has been prohibited
                      by the authority. What is the reason for this loss of
                      profit of this industry then?
                    </p>
                    <hr />

                    <blockquote>
                      <h6 className="ltn__secondary-color mt-0">
                        bdnews24.com, Published: 28 Oct 2021 08:49 PM BdST
                      </h6>
                      "BRTA threatens legal action against ride-sharing without
                      app."
                    </blockquote>
                    <p>
                      Holo has been designed and customized not only keeping
                      what the users want in mind, but also providing maximum
                      flexibility and affordability to the riders who are
                      planning to share their rides.{" "}
                    </p>
                    <div className="list-item-with-icon-2">
                      <ul>
                        <li>Affordable and easy payment methods.</li>
                        <li>Simpler user experience.</li>
                        <li>No delay in sending and receiving payments.</li>
                        <li>
                          Ensuring your rider won't be forced to go somewhere he
                          doesn't want to go.
                        </li>
                      </ul>

                      <h4>Try Our App</h4>
                      <p>
                        Download the app from google playstore and try it for
                        free!
                      </p>
                      {/* <div class="row">
                                <div class="col-lg-6">
                                    <img src="img/service/31.jpg" alt="Image">
                                    <label>Image Caption Here</label>
                                </div>
                                <div class="col-lg-6">
                                    <img src="img/service/32.jpg" alt="Image">
                                </div>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa, amet, fuga omnis eligendi sed cupiditate molestias enim autem animi est tempore ipsa corporis. Recusandae, quia.</p> */}
                    </div>
                    {/* blog-tags-social-media */}
                    <div className="ltn__blog-tags-social-media mt-80 row">
                      <div className="ltn__tagcloud-widget col-lg-8">
                        <h4>Related Tags</h4>
                        <ul>
                          <li>
                            <Link to="#">Popular</Link>
                          </li>
                          <li>
                            <Link to="#">Service</Link>
                          </li>
                          <li>
                            <Link to="#">Rideshare</Link>
                          </li>
                        </ul>
                      </div>
                      <div className="ltn__social-media text-right col-lg-4">
                        <h4>Social Share</h4>
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/HoloAppTech"
                              title="Facebook"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.instagram.com/holoapptech/"
                              title="Instagram"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/holoapptech"
                              title="Dribbble"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <aside className="sidebar-area blog-sidebar ltn__right-sidebar">
                  {/* Author Widget */}
                  <div className="widget ltn__author-widget">
                    <h4 className="ltn__widget-title ltn__widget-title-border">
                      About Us
                    </h4>
                    <div className="ltn__author-widget-inner text-center">
                      <img src="img/Holo-Logo-Icon.png" alt="Icon" />
                      <h5>Holo Limited</h5>
                      <p>
                        Holo is a technology based ride sharing service
                        providing company. It has been established in 2022 and
                        providing service since then.
                      </p>
                      <div className="ltn__social-media">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/HoloAppTech"
                              title="Facebook"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.instagram.com/holoapptech/"
                              title="Instagram"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/holoapptech"
                              title="Dribbble"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="widget ltn__social-media-widget">
                      <h4 className="ltn__widget-title ltn__widget-title-border">
                        Never Miss News
                      </h4>
                      <div className="ltn__social-media-2">
                        <ul>
                          <li />
                          <li />
                          <li />
                          <li />
                          <li>
                            <a
                              href="https://www.facebook.com/HoloAppTech"
                              title="Facebook"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>

                          <li>
                            <a
                              href="https://www.instagram.com/holoapptech/"
                              title="Instagram"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-instagram" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/holoapptech"
                              title="Dribbble"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fab fa-linkedin" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="widget ltn__banner-widget">
                      <img src="img/safety.jpg" alt="Banner" />
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
        {/* PAGE DETAILS AREA END */}
        {/* FOOTER AREA START (footer-2) */}
        <FooterBlack />
        {/* FOOTER AREA END */}

      </>
    );
  }
}

export default News;
