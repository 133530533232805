import React, { Component } from "react";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import FooterBlack from "../components/footerblack";
import axios from "axios";

const holo_point =
  "https://api.holoapp.tech/rewards/public/get-holo-points-leaderboard-view";

const referral_point =
  "https://api.holoapp.tech/rewards/public/get-referral-points-leaderboard-view";

class Offer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender_p: "",
      duration: "",
      gender_r: "",
      data: [],
      isSubmitted: false,
    };
  }

  onChangeDuration = (e) => {
    this.setState({
      duration: e.target.value,
    });
    // console.log("duration", e.target.value);
  };

  onChangeGenderPoint = (e) => {
    this.setState({
      gender_p: e.target.value,
    });
    // console.log("gender", e.target.value);
  };

  onChangeGenderRef = (e) => {
    this.setState({
      gender_r: e.target.value,
    });
    // console.log("ref gender", e.target.value);
  };

  componentDidMount() {
    loadjs("./js/main.js", true);
    loadjs("./js/plugins.js", true);
  }

  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   return false;
  // }

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.gender_p !== "" || this.state.gender_r === "") {
      axios
        .get(holo_point, {
          params: {
            duration: this.state.duration,
            gender: this.state.gender_p,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.setState({
            data: response.data,
            isSubmitted: true,
            gender_p: "",
            duration: "",
          });
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") return "timeout";
          else throw error;
        });
    } else if (this.state.gender_r !== "" || this.state.gender_p === "") {
      axios
        .get(referral_point, {
          params: {
            duration: this.state.duration,
            gender: this.state.gender_r,
          },
        })
        .then((response) => {
          // console.log(response.data);
          this.setState({
            data: response.data,
            isSubmitted: true,
            gender_r: "",
            duration: "",
          });
        })
        .catch((error) => {
          if (error.code === "ECONNABORTED") return "timeout";
          else throw error;
        });
    }
  };

  render() {
    return (
      <>
        <HeaderBlack />
        <MobileNav />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">Offers</h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Offers</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}

        {/* OFFER AREA START */}

        <div className="ltn__product-tab-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="ltn__tab-menu-list mb-50">
                  <div className="nav">
                    <a
                      className="active show"
                      data-toggle="tab"
                      href="#liton_tab_1_1"
                    >
                      Jatri <i className="fas fa-user" />
                    </a>
                    <a data-toggle="tab" href="#liton_tab_1_2">
                      Chalok <i className="fas fa-bicycle" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="tab-content">
                  <div className="tab-pane fade active show" id="liton_tab_1_1">
                    <div className="ltn__myaccount-tab-content-inner">
                      <h2 className="ltn__blog-title">Offers and Rewards</h2>
                      {/*<form onSubmit={this.onSubmit}>*/}
                      {/*  /!*<div className="ltn__blog-tags-social-media mt-80 row">*!/*/}
                      {/*  <div className="col-md-12">*/}
                      {/*    <p>*/}
                      {/*      <label className="input-info-save mb-0">*/}
                      {/*        <input*/}
                      {/*          type="radio"*/}
                      {/*          name="gender"*/}
                      {/*          value="male"*/}
                      {/*          checked={this.state.gender_p === "male"}*/}
                      {/*          onChange={(e) => this.onChangeGenderPoint(e)}*/}
                      {/*        />{" "}*/}
                      {/*        Male Holo Points*/}
                      {/*      </label>{" "}*/}
                      {/*      <label className="input-info-save mb-0">*/}
                      {/*        <input*/}
                      {/*          type="radio"*/}
                      {/*          name="gender"*/}
                      {/*          value="female"*/}
                      {/*          checked={this.state.gender_p === "female"}*/}
                      {/*          onChange={(e) => this.onChangeGenderPoint(e)}*/}
                      {/*        />{" "}*/}
                      {/*        Female Holo Points*/}
                      {/*      </label>{" "}*/}
                      {/*      <label className="input-info-save mb-0">*/}
                      {/*        <input*/}
                      {/*          type="radio"*/}
                      {/*          name="gender"*/}
                      {/*          value="male"*/}
                      {/*          checked={this.state.gender_r === "male"}*/}
                      {/*          onChange={(e) => this.onChangeGenderRef(e)}*/}
                      {/*        />{" "}*/}
                      {/*        Male Referral Points*/}
                      {/*      </label>{" "}*/}
                      {/*      <label className="input-info-save mb-0">*/}
                      {/*        <input*/}
                      {/*          type="radio"*/}
                      {/*          name="gender"*/}
                      {/*          value="female"*/}
                      {/*          checked={this.state.gender_r === "female"}*/}
                      {/*          onChange={(e) => this.onChangeGenderRef(e)}*/}
                      {/*        />{" "}*/}
                      {/*        Female Referral Points*/}
                      {/*      </label>{" "}*/}
                      {/*    </p>*/}
                      {/*  </div>{" "}*/}
                      {/*  <div className="ltn__blog-tags-social-media mt-80 row">*/}
                      {/*    <div className="ltn__tagcloud-widget col-lg-12">*/}
                      {/*      <ul>*/}
                      {/*        <li>*/}
                      {/*          <button*/}
                      {/*            className="theme-btn-1 btn"*/}
                      {/*            type="submit"*/}
                      {/*            name="duration"*/}
                      {/*            value="weekly"*/}
                      {/*            onClick={(e) => this.onChangeDuration(e)}*/}
                      {/*          >*/}
                      {/*            Weekly*/}
                      {/*          </button>*/}
                      {/*        </li>*/}
                      {/*        <li>*/}
                      {/*          <button*/}
                      {/*            className="theme-btn-1 btn"*/}
                      {/*            type="submit"*/}
                      {/*            name="duration"*/}
                      {/*            value="monthly"*/}
                      {/*            onClick={(e) => this.onChangeDuration(e)}*/}
                      {/*          >*/}
                      {/*            Monthly*/}
                      {/*          </button>*/}
                      {/*        </li>*/}
                      {/*        <li>*/}
                      {/*          <button*/}
                      {/*            className="theme-btn-1 btn"*/}
                      {/*            type="submit"*/}
                      {/*            name="duration"*/}
                      {/*            value="yearly"*/}
                      {/*            onClick={(e) => this.onChangeDuration(e)}*/}
                      {/*          >*/}
                      {/*            Yearly*/}
                      {/*          </button>*/}
                      {/*        </li>*/}
                      {/*        /!*<li>*!/*/}
                      {/*        /!*  <input*!/*/}
                      {/*        /!*    className="theme-btn-1 btn"*!/*/}
                      {/*        /!*    type="submit"*!/*/}
                      {/*        /!*    name="duration"*!/*/}
                      {/*        /!*    value="yearly"*!/*/}
                      {/*        /!*    onClick={(e) => this.onChangeDuration(e)}*!/*/}
                      {/*        /!*  />*!/*/}
                      {/*        /!*</li>*!/*/}
                      {/*      </ul>*/}
                      {/*    </div>*/}
                      {/*  </div>*/}
                      {/*</form>*/}
                      {/*<br />*/}
                      {/*<br />*/}
                      {/*<br />*/}
                      {/*<div className="container">*/}
                      {/*  {this.state.isSubmitted && (*/}
                      {/*    <div className="table-responsive">*/}
                      {/*      {this.state.data.length !== 0 ? (*/}
                      {/*        <table className="table">*/}
                      {/*          <thead>*/}
                      {/*            <tr>*/}
                      {/*              <th>Phone</th>*/}
                      {/*              <th>Points</th>*/}
                      {/*            </tr>*/}
                      {/*          </thead>*/}
                      {/*          <tbody>*/}
                      {/*            {this.state.data.map((item) => (*/}
                      {/*              <tr key={item.guid}>*/}
                      {/*                <td>{item.phone}</td>*/}
                      {/*                <td>{item.total_points}</td>*/}
                      {/*              </tr>*/}
                      {/*            ))}*/}
                      {/*          </tbody>*/}
                      {/*        </table>*/}
                      {/*      ) : (*/}
                      {/*        <h2 className="ltn__blog-title">Coming Soon</h2>*/}
                      {/*      )}*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*</div>*/}
                      {/*<br />*/}

                      <div className="ltn__blog-details-wrap">
                        <div className="ltn__page-details-inner ltn__blog-details-inner">
                          <ul>
                            <li>
                              <strong>Reward Point Offer</strong>
                              <ol>
                                <li>
                                  The top place holders based on Holo points in
                                  male and female leaderboards will receive a
                                  reward of BDT 1000 every week. The next 4
                                  persons in both male and female Holo point
                                  leaderboards will receive BDT 100 mobile
                                  recharge.
                                </li>
                                <li>
                                  The top place holders based on Holo points in
                                  male and female leaderboards will receive a
                                  reward of BDT 15000 every month. The next 4
                                  persons in both male and female Holo point
                                  leaderboards will receive a reward of BDT
                                  1500.
                                </li>
                                <li>
                                  The top place holders based on Holo points in
                                  male and female leaderboards will receive a
                                  reward of BDT 150000 every year. The next 4
                                  persons in both male and female Holo point
                                  leaderboards will receive a reward of BDT
                                  15000.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <strong>Reward Point Offer Rules</strong>
                              <ol>
                                <li>
                                  Holo points will be given based on the total
                                  number of rides. 20 points will be given for a
                                  successful ride.
                                </li>
                                <li>
                                  If the points on the leaderboard of two or
                                  more people are the same,the one with more
                                  average number of rides per day will be ranked
                                  higher.If a tie occurs at this point, then the
                                  one who has paid the highest fare will be
                                  ranked higher.
                                </li>
                                <li>
                                  Weekly Leaderboard accounts will be held from
                                  12 A.M on Friday to 12 A.M on the following
                                  Friday. This award will be given next week. To
                                  get a place in the weekly leaderboard, you
                                  have to earn at least 140 Holo points.
                                </li>
                                <li>
                                  Monthly Leaderboard accounts will be held from
                                  12 A.M on the 1st of each month to 12 A.M on
                                  the 1st of the following month. The award will
                                  be given in the first two weeks of the next
                                  month. To get a place in the monthly
                                  leaderboard, you have to earn at least 640
                                  Holo points.
                                </li>
                                <li>
                                  The accounts of the Annual Leaderboard will be
                                  held from 12 A.M on January 1 of each year to
                                  12 A.M on January 1 of the following year. The
                                  award of this leaderboard will be given in the
                                  first three weeks of next year. To get a place
                                  in the annual leaderboard, you have to earn at
                                  least 4000 Holo points.
                                </li>
                                <li>
                                  Holo Ltd. will have the sole authority to make
                                  any decision on the matter.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <strong>Reference Point Offer</strong>
                              <ol>
                                <li>
                                  The top place holders based on Holo referral
                                  points in male and female leaderboards will
                                  receive a reward of BDT 10000 every month. The
                                  next 4 persons in both male and female Holo
                                  referral point leaderboards will receive a
                                  reward of BDT 1000.
                                </li>
                                <li>
                                  The top place holders based on Holo referral
                                  points in male and female leaderboards will
                                  receive a reward of BDT 100000 every year. The
                                  next 4 persons in both male and female Holo
                                  referral point leaderboards will receive a
                                  reward of BDT 10000.
                                </li>
                              </ol>
                            </li>
                            <li>
                              <strong>Reference Point Offer Rules</strong>
                              <ol>
                                <li>
                                  Holo Referral points will be given based on
                                  the number of referrals. If one can
                                  successfully register an jatri, five points
                                  will be awarded after the first successful
                                  ride of the jatri. And if one can successfully
                                  refer a chalok to the monthly package, the
                                  equivalent of five percent of the price of the
                                  monthly package will be given.
                                </li>
                                <li>
                                  If someone's points on the leaderboard are the
                                  same, the one who refers more chalok will be
                                  at the top. Here too, if more than one
                                  person's account is equal, then the one who
                                  has signed up before will be on top.
                                </li>
                                <li>
                                  Monthly Leaderboard accounts will be held from
                                  12 A.M on the 1st of each month to 12 A.M on
                                  the 1st of the following month. The award will
                                  be given in the first two weeks of the next
                                  month. To get a place in the monthly
                                  leaderboard, you have to earn at least 100
                                  Holo referral points.
                                </li>
                                <li>
                                  The accounts of the Annual Leaderboard will be
                                  held from 12 A.M on January 1 of each year to
                                  12 A.M on January 1 of the following year. The
                                  award of this leaderboard will be given in the
                                  first three weeks of next year. To get a place
                                  in the annual leaderboard, you have to earn at
                                  least 1500 Holo referral points.
                                </li>
                                <li>
                                  Holo Ltd. will have the sole authority to make
                                  any decision on the matter.
                                </li>
                              </ol>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <br />
                      <br />
                      <br />
                    </div>
                  </div>

                  <div className="tab-pane fade" id="liton_tab_1_2">
                    <div className="ltn__myaccount-tab-content-inner">
                      <div className="container">
                        <div className="row">

                        </div>
                        <div>
                          <ul>
                            <strong>Referral System</strong>
                            <li>
                              chaloks will get "chalok Point" for referring. There
                              are two types of referral a chalok can do. One is
                              package referral - chalok can refer another chalok
                              while buying a monthly package, if the chalok
                              buying the package puts the phone number of the
                              referrer in the referral box, the referrer will
                              get 5% of the value of the package. Like if the
                              current value of the package is 1500 with 40%
                              discount, But the main value is 2500, the
                              effective value which is 1500 will be considered
                              and the referrer will get 5% of 1500 , hence 75
                              chalok Point for a referral. <br />
                              The other one is jatri referral - chalok can refer
                              an jatri while the jatri sign up for Holo. The
                              process is similar, if jatri signing up for Holo
                              puts the phone number of the referrer in the
                              referral box, the referrer will get 5 chalok
                              Points.
                            </li>
                          </ul>
                        </div>
                        <div>
                          <ul>
                            <strong>Cashout Rules</strong>
                            <li>
                              A chalok can cashout the chalok points he/she earns.
                              But to able to cashout, one must have 1000 or more
                              chalok points. 1 chalok point is considered to be 1
                              BDT, so the more chalok points one can earn, the
                              more money he is earning.
                            </li>
                          </ul>
                        </div>
                        <div>
                          <ul>
                            <strong>রেফারেল সিস্টেম</strong>
                            <li>
                              একজন চালক রেফার করে "চালক পয়েন্ট" অর্জন করতে
                              পারবে। একজন চালক দুই ধরণের রেফার করতে পারবে -
                              একটি হচ্ছে প্যাকেজ রেফারেল মাসিক প্যাকেজ কেনার
                              সময় চালক অন্য চালককে রেফার করতে পারে, যদি
                              প্যাকেজটি কেনার সময় চালক রেফারেল বক্সে রেফারারের
                              ফোন নম্বর দেয়, তাহলে রেফারার প্যাকেজের মূল্যের ৫%
                              পাবেন। যেমন প্যাকেজের বর্তমান মূল্য ৪০% ডিসকাউন্ট
                              সহ ১৫০০ টাকা হলে মূল মূল্য হল ২৫০০ টাকা,কিন্তু
                              রেফারেল এর ক্ষেত্রে কার্যকরী মূল্য ১৫০০ টাকা
                              বিবেচনা করা হবে এবং রেফারকারী ১৫০০ এর ৫% পাবে,
                              সুতরাং ১৫০০ টাকা মূল্যের প্যাকেজ এর ক্ষেত্রে
                              রেফারেলের জন্য ৭৫ চালক পয়েন্ট পাবে।
                              <br />
                              আরেকটি রেফারেল হচ্ছে যাত্রী বা গ্রাহক রেফারেল
                              হোলোতে সাইন আপ করার সময় একজন ব্যবহারকারীকে একজন
                              চালক রেফার করতে পারবেন। প্রক্রিয়াটি একই রকম,
                              যদি ব্যবহারকারী Holo-এর জন্য সাইন আপ করেন রেফারেল
                              বক্সে রেফারারের ফোন নম্বর রাখলে রেফারার ৫ চালক
                              পয়েন্ট পাবেন।
                            </li>
                          </ul>
                        </div>
                        <div>
                          <ul>
                            <strong>ক্যাশআউট শর্তাবলি -</strong>
                            <li>
                              একজন চালক তার উপার্জন করা চালক পয়েন্ট
                              ক্যাশআউট করতে পারেন। কিন্তু ক্যাশআউট করতে, একজনের
                              অবশ্যই ১০০০ বা তার বেশি চালক পয়েন্ট থাকতে হবে।
                              ১ চালক পয়েন্ট এর মূল্যমান ১ টাকা হিসাবে বিবেচিত
                              হবে, অতএব একজন যত বেশি চালক পয়েন্ট উপার্জন করতে
                              পারবেন, তিনি তত বেশি অর্থ উপার্জন করবেন।
                            </li>
                          </ul>
                        </div>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* PRODUCT TAB AREA END */}

        {/* OFFER AREA END */}
        <FooterBlack />
      </>
    );
  }
}

export default Offer;
