import React from "react";
import { Link } from "react-router-dom";
import loadjs from "loadjs";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import FooterBlack from "../components/footerblack";
import zoneData from "../zone.json";

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
    };
  }

  componentDidMount() {
    loadjs("./js/main.js");

    loadjs("./js/plugins.js");
  }

  handleChange = (e) => {
    // e.preventDefault();
    this.setState({
      search: e,
    });
  };

  render() {

    return (
      <>
        <HeaderBlack />
        <MobileNav />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">Service Area</h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>Service Area</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        <div className="container">
          <input
            type="text"
            placeholder="Search Your Area"
            onChange={(e) => this.handleChange(e.target.value)}
          />
          <div className="table-responsive">
            <table
              className="table"
              // style={{
              //   height: "150px",
              //   width: "600px",
              //   marginLeft: "auto",
              //   marginRight: "auto",
              // }}
            >
              <thead>
                <tr>
                  <th>Region</th>
                  <th>Area</th>
                </tr>
              </thead>
              <tbody>
                {zoneData
                  .filter((val) => {
                    if (this.state.search === "") {
                      return val;
                    } else if (
                      Object.values(val.fields.area_en).join('').toLowerCase().includes(
                        this.state.search.toLowerCase()
                      )
                    ) {
                      return val;
                    }
                  })
                  .map((val) => {
                    return (
                      <tr key={val.pk}>
                        <td>{Object.values(val.fields.region)}</td>
                        <td>{Object.values(val.fields.area_en)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <FooterBlack />
      </>
    );
  }
}

export default Service;
