import React from "react";
import {Link} from "react-router-dom";
import loadjs from "loadjs";
import HeaderBlack from "../components/headerblack";
import MobileNav from "../components/mobilenav";
import FooterBlack from "../components/footerblack";

class Terms extends React.Component {
    componentDidMount() {
        loadjs("./js/main.js");
        loadjs("./js/plugins.js");
    }

    render() {
        return (
            <>
                <HeaderBlack/>
                <MobileNav/>
                <div className="ltn__utilize-overlay"/>

                {/* BREADCRUMB AREA START */}
                <div
                    className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
                    data-bg="img/bg/dhaka.jpeg"
                >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                                    <div className="section-title-area ltn__section-title-2">
                                        <h6 className="section-subtitle ltn__secondary-color">
                                            Welcome to Holo
                                        </h6>
                                        <h1 className="section-title white-color">
                                            Terms of Service
                                        </h1>
                                    </div>
                                    <div className="ltn__breadcrumb-list">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>Terms of Service</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* BREADCRUMB AREA END */}

                {/* PAGE DETAILS AREA START (blog-details) */}
                {/*<Link*/}
                {/*                      to="/termsBangla"*/}
                {/*                      className="theme-btn-1 btn btn-effect-1"*/}
                {/*                      style= {{ marginTop: "-10%",marginLeft: "10%" }}*/}
                {/*                    >*/}
                {/*                    বাংলা*/}
                {/*</Link>*/}

                <div className="ltn__page-details-area ltn__blog-details-area mb-120">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="ltn__blog-details-wrap">
                                    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>
                                        <div className="section-title-area ltn__section-title-2 text-center">
                                            {/*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*/}
                                            <h3 className="section-title">Terms HOLO Bangla</h3>
                                        </div>

                                        <a href="img/toc/Terms HOLO Bangla.pdf"
                                           download="Terms HOLO Bangla.pdf"><img
                                            src="img/service/file.png" width="100" height="100"/></a>
                                    </div>
                                    {/*<div className="ltn__page-details-inner ltn__blog-details-inner">*/}
                                    {/*<ol>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>INTRODUCTION</strong>*/}
                                    {/*      <div>                        */}
                                    {/*      Important – please read these terms carefully. By using this Service, you agree that you have read, understood, accepted and agreed */}
                                    {/*      with the Terms and Conditions, and the conditions stated in the Code of Conduct. You further agree to the representations made by */}
                                    {/*      yourself below. If you do not agree to or fall within the Terms and Conditions of the Service (as defined below) and wish to */}
                                    {/*      discontinue using the Service, please do not continue using this Application or Service. The terms and conditions stated herein */}
                                    {/*      (collectively, the “Terms and Conditions” or this “Agreement”) constitute a legal agreement between you and the “HOLO TECH Ltd.”.*/}
                                    {/*      <br/>*/}
                                    {/*      In order to use the Service (each as defined below) you must agree to the Terms and Conditions that are set out below. By using the */}
                                    {/*      mobile application supplied to you by HOLO TECH Ltd. (the “Application”), and downloading, installing or using any associated */}
                                    {/*      software supplied by the HOLO TECH Ltd. (the “Software”) of which overall purpose is to enable the Service (each as defined below), */}
                                    {/*      you hereby expressly acknowledge and agree to be bound by the Terms and Conditions, and any future amendments and additions to this */}
                                    {/*      Terms and Conditions as published from time to time at the HOLO TECH Ltd. or through the Application.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}

                                    {/*    <li>*/}
                                    {/*      <strong>SERVICES</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The HOLO TECH Ltd. offers information, method and platform for transportation providers, drivers and vehicle operators to */}
                                    {/*      establish contact with Passengers or Customers, but does not and does not intend to provide transportation services or act in any */}
                                    {/*      way as a taxi operator, transportation carrier or provider, and has no responsibility or liability for any transportation services */}
                                    {/*      provided to Passengers or Customers by you.<br/>*/}
                                    {/*      The HOLO TECH Ltd. reserves the right to modify, refine or correct the terms and conditions of this Agreement or its policies */}
                                    {/*      relating to the Service at any time as it deems fit. Such modifications, refinements or correction to the Terms and Conditions */}
                                    {/*      policies relating to the Service shall be effective upon the posting of an updated version at the HOLO TECH Ltd. . You agree that */}
                                    {/*      it shall be your responsibility to review this Agreement regularly whereupon the continued use of the Service after any such changes, */}
                                    {/*      whether or not reviewed by you, shall constitute your acceptance to such changes. <br/>*/}
                                    {/*      Provided that, this ride sharing business is operated based on a freelancing model, the drivers are not employees of the HOLO */}
                                    {/*      TECH Ltd. . Concerned individual parties, not the HOLO TECH Ltd., shall be solely responsible for any and all claims, judgements */}
                                    {/*      and liabilities resulting from any accident, loss or damage including personal injuries, death, total loss and property damages */}
                                    {/*      which is due to or is alleged to be a result of the passenger transport.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}

                                    {/*    <li>*/}
                                    {/*      <strong>NOT A TRANSPORTATION PROVIDER</strong>*/}
                                    {/*      <div>*/}
                                    {/*      THE HOLO TECH Ltd. IS A TECHNOLOGY COMPANY THAT DOES NOT PROVIDE OR ENGAGE IN TRANSPORTATION SERVICES AND THE HOLO TECH Ltd. */}
                                    {/*      IS NOT A TRANSPORTATION PROVIDER. THE SOFTWARE AND THE APPLICATION ARE INTENDED TO BE USED FOR FACILITATING YOU (AS A */}
                                    {/*      TRANSPORTATION PROVIDER) TO OFFER YOUR TRANSPORTATION SERVICES TO YOUR PASSENGER OR CUSTOMER. THE HOLO TECH Ltd. IS NOT RESPONSIBLE */}
                                    {/*      OR LIABLE FOR THE ACTS AND/OR OMISSIONS WITH REGARD TO ANY SERVICES YOU PROVIDED TO YOUR PASSENGERS, AND FOR ANY ILLEGAL ACTION */}
                                    {/*      COMMITTED BY YOU. YOU SHALL, AT ALL TIME, NOT CLAIM OR CAUSE ANY PERSON TO MISUNDERSTAND THAT YOU ARE THE AGENT, EMPLOYEE OR STAFF */}
                                    {/*      OF THE HOLO TECH Ltd., AND THE SERVICES PROVIDED BY YOU IS NOT, IN ANYWAY, BE DEEMED AS SERVICES OF THE HOLO TECH Ltd.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>RIGHT TO REFUSE AND BLACKLIST</strong>*/}
                                    {/*      <div>*/}
                                    {/*      Notwithstanding anything herein written, the HOLO TECH Ltd. may, at its sole and absolute discretion, blacklist you permanently or */}
                                    {/*      temporarily and reject your request to use the Application and / or Service or any part thereof for such reasons as it deems fit, */}
                                    {/*      including but not limited to receiving complaints about you from customers or employees of the HOLO TECH Ltd. about your behaviour */}
                                    {/*      or interaction with anyone whatsoever (including but not limited to Customers, HOLO TECH Ltd.’s employees, law enforcement, */}
                                    {/*      government authorities) or driving with a competence lower than reasonably expected.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>REPRESENTATION AND WARRANTIES</strong>*/}
                                    {/*      <div>*/}
                                    {/*      By using the Service, you expressly represent and warrant that you are legally entitled to accept and agree to the Terms and */}
                                    {/*      Conditions and that you are at least eighteen (18) years old. Without limiting the generality of the foregoing, the Service is not */}
                                    {/*      available to persons under the age of eighteen (18) or such persons that are forbidden for any reason whatsoever to enter into a */}
                                    {/*      contractual relationship. By using the Service, you further represent and warrant that you have the right, authority and capacity */}
                                    {/*      to use the Service and to abide by the Terms and Conditions. You further confirm that all the information which you provide shall */}
                                    {/*      be true and accurate. Your use of the Service is for your own sole, personal use. You undertake not to authorise others to use your */}
                                    {/*      identity or user status, and you may not assign or otherwise transfer your user account to any other person or entity. When using */}
                                    {/*      the Service you agree to comply with all applicable laws of your country. Besides, you will also be bound by applicable laws, rules */}
                                    {/*      and regulations of the country of origin of the HOLO TECH Ltd. where it is registered to the extent it is applicable and consistent */}
                                    {/*      with the laws and regulations of your local territorial jurisdiction in pursuant to the bilateral treaty, international business */}
                                    {/*      and commercial laws as formulated from time to time.<br/>*/}
                                    {/*      You may only access the Service using authorised and legal means. It is your responsibility to check and ensure that you download */}
                                    {/*      the correct Software for your device. The HOLO TECH Ltd. is not liable if you do not have a compatible handset or if you have */}
                                    {/*      downloaded the wrong version of the Software for your handset. The HOLO TECH Ltd. reserves the right to terminate this Agreement */}
                                    {/*      should you be using the Service with an incompatible or unauthorised device or for purposes other than which the Application or */}
                                    {/*      Software is intended to be used.*/}
                                    {/*        <ul>*/}
                                    {/*        <b>By using the Service, you represent, warrant, undertake and agree that:</b>*/}
                                    {/*          <li>*/}
                                    {/*          You possess a valid driver’s licence and are authorised to operate a motor vehicle and have all the appropriate licences, */}
                                    {/*          approvals and authority to provide transportation for hire to third parties in the jurisdiction in which you use the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You own, or have the legal right and authority to operate, the vehicle which you intend to use when accepting passengers or */}
                                    {/*          customers, and such vehicle is in good operating condition and meets the industry safety standards for vehicles of its kind;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You have a valid policy of liability insurance (in industry-standard coverage amounts) for the operation of your motor */}
                                    {/*          vehicle/passenger vehicle and/or business insurance to cover any anticipated losses related to the operation of a */}
                                    {/*          taxi/passenger delivery service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall forthwith provide to the HOLO TECH Ltd. such information and identity documents, including but not limited to National */}
                                    {/*          ID Card and Driver’s Licences, as reasonably requested by the HOLO TECH Ltd.;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall be solely responsible for any and all claims, judgments and liabilities resulting from any accident, loss or damage */}
                                    {/*          including, but not limited to personal injuries, death, total loss and property damages which is due to or is alleged to be a */}
                                    {/*          result of the passenger transport and/or delivery service howsoever operated;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall obey all local laws related to the operation of passenger transport and/or delivery services and will be solely */}
                                    {/*          responsible for any violations of such local laws;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use the Service for lawful purposes;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use the Service for the purpose for which it is intended to be used;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Application for sending or storing any unlawful material or for fraudulent purposes;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Application and/or the Software to cause nuisance, annoyance, inconvenience or make fake bookings;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Service, Application and/or Software for purposes other than obtaining the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall not contact the Customers for purposes other than the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not impair the proper operation of the network;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not try to harm the Service, Application and/or the Software in any way whatsoever;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use the Software and/or the Application for your own use and will not resell it to a third party;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will keep secure and confidential your account password or any identification we provide you which allows access to the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will provide us with whatever proof of identity the HOLO TECH Ltd. may reasonably request or require;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You agree to provide accurate, current and complete information as required for the Service and undertake the responsibility to */}
                                    {/*          maintain and update your information in a timely manner to keep it accurate, current and complete at all times during the term */}
                                    {/*          of the Agreement. You agree that the HOLO TECH Ltd. may rely on your information as accurate, current and complete. You */}
                                    {/*          acknowledge that if your information is untrue, inaccurate, not current or incomplete in any respect, the HOLO TECH Ltd. has */}
                                    {/*          the right but not the obligation to terminate this Agreement and your use of the Service at any time with or without notice;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use an access point or data account (AP) which you are authorised to use;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall not employ any means to defraud the HOLO TECH Ltd. or enrich yourself, through any means, whether fraudulent or */}
                                    {/*          otherwise, whether or not through any event, promotion or campaign launched by the HOLO TECH Ltd. to encourage new subscription */}
                                    {/*          or usage of the Service by new or existing passengers;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Service or any part thereof for carrying contraband items as may be listed publicly and updated by the */}
                                    {/*          HOLO TECH Ltd. from to time and if, in the event that you display suspicious behaviour, you will fully comply with the request */}
                                    {/*          of the third-party service provider, any government authority and / or law enforcement, to inspect any bags and / or items you */}
                                    {/*          are carrying with you which may or may not be readily visible;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You are aware that when responding to Passengers’ or Customers’ requests for transportation services, standard telecommunication */}
                                    {/*          charges will apply and which shall be solely borne by you;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall not impair or circumvent the proper operation of the network which the Service operates on;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You agree that the Service is provided on a reasonable effort basis;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You unconditionally agree to assume full responsibility and liability for all loss or damage suffered by yourself, the */}
                                    {/*          Customers or Passengers, the HOLO TECH Ltd., Merchant and/or any third party as a result of any breach of the Terms and */}
                                    {/*          Conditions by you.*/}
                                    {/*          </li>*/}
                                    {/*        </ul>*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>LICENCE GRANT & RESTRICTIONS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The HOLO TECH Ltd. and its licensors, where applicable, hereby grants you a revocable, non-exclusive, non- transferable, */}
                                    {/*      non-assignable, personal, limited licence to use the Application and/or the Software, solely for your own personal purpose, */}
                                    {/*      subject to the terms and conditions of this Agreement. All rights not expressly granted to you are reserved by the HOLO TECH Ltd. */}
                                    {/*      and its licensors.*/}
                                    {/*      <ul>*/}
                                    {/*          <b>You shall not:</b>*/}
                                    {/*          <li>Licence, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any */}
                                    {/*            third party the Application and/or the Software in any way;*/}
                                    {/*          </li>*/}
                                    {/*          <li>Modify or make derivative works based on the Application and/or the Software;</li>*/}
                                    {/*          <li>*/}
                                    {/*          Create internet “links” to the Application or “frame” or “mirror” the Software on any other server or wireless or internet-based */}
                                    {/*          device;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*            <ul>*/}
                                    {/*            Reverse engineer or access the Software in order to -*/}
                                    {/*              <li>build a competitive product or service</li>*/}
                                    {/*              <li>build a product using similar ideas, features, functions or graphics of the Application and/or the Software, or</li>*/}
                                    {/*              <li>copy any ideas, features, functions or graphics of the Application and/or the Software</li>*/}
                                    {/*            </ul>                            */}
                                    {/*          </li>*/}
                                    {/*        <li>*/}
                                    {/*        Launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web */}
                                    {/*        indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders */}
                                    {/*        the operation and/or performance of the Application and/or the Software;*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*        Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, */}
                                    {/*        “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents;*/}
                                    {/*        </li>*/}
                                    {/*        Post, distribute or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining */}
                                    {/*        the prior consent of the owner of such proprietary rights;*/}
                                    {/*        <li>*/}
                                    {/*        Remove any copyright, trademark or other proprietary rights notices contained in the Service.*/}
                                    {/*        </li>*/}
                                    {/*      </ul>*/}
                                    {/*      <ul>*/}
                                    {/*        <b>You may use the Software and/or the Application only for your personal, non-commercial purposes and shall not use the Software and/or the */}
                                    {/*          Application to:</b>*/}
                                    {/*        <li>Send spam or otherwise duplicative or unsolicited messages;</li>*/}
                                    {/*        <li>Send or store infringing, obscene, threatening, libellous, or otherwise unlawful or tortious material, including but not */}
                                    {/*          limited to materials harmful to children or violate of third party privacy rights;</li>*/}
                                    {/*        <li>Send material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or */}
                                    {/*          programs;</li>*/}
                                    {/*        <li>Interfere with or disrupt the integrity or performance of the Software and/or the Application or the data contained therein;</li>*/}
                                    {/*        <li>Attempt to gain unauthorised access to the Software and/or the Application or its related systems or networks; or</li>*/}
                                    {/*        <li>Impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity to abstain from any */}
                                    {/*          conduct that could possibly damage the HOLO TECH Ltd.’s reputation or amount to being disreputable.</li>*/}
                                    {/*      </ul>*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>PAYMENT TERMS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      Any fees which the HOLO TECH Ltd. may charge you for the Service are due immediately and are non-refundable (“Service Fee”). */}
                                    {/*      This no-refund policy shall apply at all times regardless of your decision to terminate your usage, our decision to terminate or */}
                                    {/*      suspend your usage, disruption caused to the Service either planned, accidental or intentional, or any reason whatsoever. <br/>*/}
                                    {/*      For prepaid packages, you will pay before you use the software. For postpaid packages, you will pay the due before buying the next */}
                                    {/*      package. You understand the significance of this requirement and you acknowledge that you may be blacklisted for failure to comply */}
                                    {/*      with the aforesaid requirement and that your ability to use the Service shall be barred until due compliance is made in this */}
                                    {/*      regard. <br/>*/}
                                    {/*      The HOLO TECH Ltd. may, at its sole discretion, make promotional offers with different features and different rates to any of the */}
                                    {/*      Passengers or Customers whereby these promotional offers shall accordingly be honoured by you. The HOLO TECH Ltd. may determine or */}
                                    {/*      change the Service Fee as the HOLO TECH Ltd. deems in its absolute discretion as necessary or appropriate for the business.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>PAYMENT BY PASSENGER</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The Passenger or Customer may choose to pay for the Service by cash. <br/>*/}
                                    {/*      Any complaints that the Passenger or Customer shall have regarding the transportation provided by the driver shall be taken up by the */}
                                    {/*      Passenger or Customer with the driver directly. <br/>*/}
                                    {/*      The HOLO TECH Ltd. retains the right to suspend the processing of any transaction where it reasonably believes that the transaction */}
                                    {/*      may be fraudulent, illegal or involves any criminal activity or where it reasonably believes the Passenger or Customer to be in */}
                                    {/*      breach of the Terms and Conditions between the Passenger or Customer and the HOLO TECH Ltd.. In such an event, the driver shall */}
                                    {/*      not hold the HOLO TECH Ltd. liable for any withholding of, delay in, suspension of or cancellation of, any payment to the */}
                                    {/*      driver. <br/>*/}
                                    {/*      You ( Either As User or driver ), agree that you will cooperate in relation to any criminal investigation that is required and to */}
                                    {/*      assist the HOLO TECH Ltd. in complying with any internal investigations, instructions from the authorities or requirements of */}
                                    {/*      prevailing laws or regulations for the time being in force.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>TAXES</strong>*/}
                                    {/*      <div>*/}
                                    {/*      You agree that this Agreement shall be subject to all prevailing statutory taxes, duties, fees, charges and/or costs, however */}
                                    {/*      denominated, as may be in force and in connection with any future taxes that may be introduced at any point of time. You further */}
                                    {/*      agree to use your best efforts to do everything necessary and required by the relevant laws to enable, assist and/or defend the */}
                                    {/*      HOLO TECH Ltd. to claim or verify any input tax credit, set off, rebate or refund in respect of any taxes paid or payable in */}
                                    {/*      connection with the Services supplied under this Agreement.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>CONFIDENTIALITY</strong>*/}
                                    {/*      <div>*/}
                                    {/*      You shall maintain in confidence all information and data relating to the HOLO TECH Ltd., its services, products, business */}
                                    {/*      affairs, marketing and promotion plans or other operations of the HOLO TECH Ltd. and its associated companies which are disclosed */}
                                    {/*      to you by or on behalf of the HOLO TECH Ltd. (whether orally or in writing and whether before, on or after the date of this */}
                                    {/*      Agreement) or which are otherwise directly or indirectly acquired by you from the HOLO TECH Ltd., or any of its affiliated */}
                                    {/*      companies, or created in the course of this Agreement. You shall further ensure that you only use such confidential information */}
                                    {/*      in order to perform the Services, and shall not without the HOLO TECH Ltd.’s prior written consent, disclose such information to */}
                                    {/*      any third-party nor use it for any other purpose except for providing services under this agreement. You shall only disclose such */}
                                    {/*      information to such officers, employees and agents as need to know it to fulfil its obligations under this Agreement. <br/>*/}
                                    {/*      <ul>*/}
                                    {/*      <b>The above obligations of confidentiality shall not apply to the extent that you can show that the relevant information:</b>*/}
                                    {/*        <li>Was at the time of receipt already in the Recipient’s possession;</li>*/}
                                    {/*        <li>Is, or becomes in the future, public knowledge through no fault or omission of the Recipient;</li>*/}
                                    {/*        <li>Was received from a third-party having the right to disclose it; or*/}
                                    {/*        Is required to be disclosed by law.</li>*/}
                                    {/*      </ul>*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>PERSONAL DATA PROTECTION</strong>*/}
                                    {/*      <div>*/}
                                    {/*      You agree and consent to the HOLO TECH Ltd. using and processing your Personal Data for the Purposes and in the manner as identified */}
                                    {/*      hereunder.<br/>*/}
                                    {/*      For the purposes of this Agreement, “Personal Data” means information about you, from which you are identifiable, including but */}
                                    {/*      not limited to your name, identification card number, birth certificate number, passport number, nationality, address, telephone */}
                                    {/*      number, credit or debit card details, race, gender, date of birth, email address, any information about you which you have provided */}
                                    {/*      to the HOLO TECH Ltd. in registration forms, application forms or any other similar forms and/or any information about you that */}
                                    {/*      has been or may be collected, stored, used and processed by the HOLO TECH Ltd. from time to time and includes sensitive personal */}
                                    {/*      data such as data relating to health, religious or other similar beliefs. <br/>*/}
                                    {/*      The provision of your Personal Data is voluntary. However, if you do not provide the HOLO TECH Ltd. your Personal Data, your */}
                                    {/*      request for the Application may be incomplete and the HOLO TECH Ltd. will not be able to process your Personal Data for the */}
                                    {/*      Purposes outlined below and may cause the HOLO TECH Ltd. to be unable to allow you to use the Service.*/}
                                    {/*        <ul>*/}
                                    {/*          <b>The HOLO TECH Ltd. may use and process your Personal Data for business and activities of the HOLO TECH Ltd. which shall include, */}
                                    {/*            without limitation the following (“the Purpose”):</b>*/}
                                    {/*          <li>*/}
                                    {/*          To perform the HOLO TECH Ltd.’s obligations in respect of any contract entered into with you;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To provide you with any services pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To process your participation in any events, promotions, activities, focus groups, research studies, contests, promotions, */}
                                    {/*          polls, surveys or any productions and to communicate with you regarding your attendance thereto;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          Process, manage or verify your application for the Service pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To validate and/or process payments pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To develop, enhance and provide what is required pursuant to the Terms and Conditions herein to meet your needs;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To process any refunds, rebates and/or charges pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To facilitate or enable any checks as may be required pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To respond to questions, comments and feedback from you;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To communicate with you for any of the purposes listed herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          For internal administrative purposes, such as auditing, data analysis, database records;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          For purposes of detection, prevention and prosecution of crime;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          For the HOLO TECH Ltd. to comply with its obligations under law;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To send you alerts, newsletters, updates, mailers, promotional materials, special privileges, festive greetings from the HOLO */}
                                    {/*          TECH Ltd., its partners, advertisers and/or sponsors;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To notify and invite you to events or activities organised by the HOLO TECH Ltd., its partners, advertisers, and/or sponsors;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To share your Personal Data amongst the companies within the HOLO TECH Ltd.’s group of companies comprising the subsidiaries, */}
                                    {/*          associate companies and/or jointly controlled entities of the holding HOLO TECH Ltd. of the group (“the Group”) and with the */}
                                    {/*          HOLO TECH Ltd.’s and Group’s agents, third party providers, developers, advertisers, partners, event companies or sponsors who */}
                                    {/*          may communicate with you for any reasons whatsoever.*/}
                                    {/*          </li>*/}
                                    {/*        </ul>*/}
                                    {/*        If you do not consent to the HOLO TECH Ltd. processing your Personal Data for any of the Purposes, please notify the HOLO TECH Ltd. */}
                                    {/*        using the support contact details as provided in the Application.<br/>*/}
                                    {/*        If any of the Personal Data that you have provided to us changes, for example, if you change your e- mail address, */}
                                    {/*        telephone number, payment details or if you wish to cancel your account, please update your details by sending your request */}
                                    {/*        to the support contact details as provided in the Application. The HOLO TECH Ltd. will, to the best of our abilities, effect */}
                                    {/*        such changes as requested within fourteen (14) working days of receipt of such notice of change.<br/>*/}
                                    {/*        By submitting your information, you consent to the use of that information as set out in the form of submission and in this Agreement.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*    <strong>THIRD PARTY INTERACTIONS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      During the Service tenure, you may enter into correspondence with, provide services to, or participate in promotions of third */}
                                    {/*      party providers, advertisers or sponsors showing their goods and/or services through the Service, Software and/or the Application. */}
                                    {/*      Any such activity, and any terms, conditions, warranties or representations associated with such activity are solely between you */}
                                    {/*      and the applicable third-party. The HOLO TECH Ltd. and its licensors shall have no liability, obligation or responsibility for any */}
                                    {/*      such correspondence, purchase, transaction or promotion between you and any such third- party. The Group does not endorse any */}
                                    {/*      applications or sites on the Internet that are linked through the Service, Application and/or the Software, and in no event shall */}
                                    {/*      the HOLO TECH Ltd., its licensors or the Group be responsible for any content, products, services or other materials on or */}
                                    {/*      available from such sites or third-party providers. The HOLO TECH Ltd. provides the Service to you pursuant to the Terms and */}
                                    {/*      Conditions. You recognize, however, that certain third-party providers of transportation, goods and/or services may require your */}
                                    {/*      agreement to additional or different terms and conditions prior to your use of or access to such goods or services, and the HOLO */}
                                    {/*      TECH Ltd. is not a party to and disclaims any and all responsibility and/or liability arising from such agreements between you and */}
                                    {/*      the third-party providers.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>INDEMNIFICATION</strong>*/}
                                    {/*      <div>*/}
                                    {/*        {" "}*/}
                                    {/*        By agreeing to this Agreement for providing the Service, you agree that you shall defend, indemnify and hold the HOLO TECH Ltd., */}
                                    {/*        its licensors and each such party’s parent organisations, subsidiaries, affiliates, officers, directors, members, employees, */}
                                    {/*        attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses */}
                                    {/*        (including attorneys’ fees and costs) arising out of or in connection with:*/}
                                    {/*      </div>*/}
                                    {/*      <ul>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your violation or breach of any term of these Terms and Conditions or any applicable law or regulation, including any local */}
                                    {/*        laws or ordinances, whether or not referenced herein; */}
                                    {/*        </li>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your violation of any rights of any third party, including, but not limited to passengers of your vehicle or the vehicle that you */}
                                    {/*        have control over, other motorists, and pedestrians, as a result of your own interaction with any third party; */}
                                    {/*        </li>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your use (or misuse) of the Application and/or Software; and*/}
                                    {/*        </li>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your ownership, use or operation of a motor vehicle or passenger vehicle, including your carriage of Passengers or Customers who */}
                                    {/*        have procured your transportation services via the Service, or of their goods.*/}
                                    {/*        </li>*/}
                                    {/*      </ul>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>DISCLAIMER OF WARRANTIES</strong>*/}
                                    {/*      <div>*/}
                                    {/*     <ul>   */}
                                    {/*     THE HOLO TECH Ltd. MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, */}
                                    {/*     AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES, APPLICATION AND/OR THE SOFTWARE. THE HOLO TECH Ltd. DOES NOT REPRESENT OR */}
                                    {/*     WARRANT THAT :*/}
                                    {/*      <li>THE USE OF THE SERVICE, APPLICATION AND/OR THE SOFTWARE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN */}
                                    {/*        COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA </li>                         */}
                                    {/*      <li>THE SERVICE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS </li>                         */}
                                    {/*      <li>ANY STORED DATA WILL BE ACCURATE OR RELIABLE </li>                         */}
                                    {/*      <li>THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIALS PURCHASED OR OBTAINED BY YOU THROUGH THE APPLICATION */}
                                    {/*        WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS </li>                         */}
                                    {/*      <li>ERRORS OR DEFECTS IN THE APPLICATION AND/OR THE SOFTWARE WILL BE CORRECTED OR </li>                         */}
                                    {/*      <li>THE APPLICATION OR THE SERVER(S) THAT MAKE THE APPLICATION AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR </li>                         */}
                                    {/*      <li>THE APPLICATION AND/OR THE SOFTWARE TRACKS YOU OR THE VEHICLE USED BY THE TRANSPORTATION PROVIDER. </li>                         */}
                                    {/*      </ul>*/}
                                    {/*      THE SERVICE IS PROVIDED TO YOU STRICTLY ON AN “AS IS” BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, */}
                                    {/*      IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR */}
                                    {/*      PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY EXCLUDED AND DISCLAIMED TO THE HIGHEST AND MAXIMUM EXTENT. <br/>*/}

                                    {/*      THE HOLO TECH Ltd. MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY */}
                                    {/*      OR AVAILABILITY OF ANY SERVICES, INCLUDING BUT NOT LIMITED TO THE TRANSPORTATION SERVICES PROVIDED BY YOU TO CUSTOMERS OR PASSENGERS */}
                                    {/*      THROUGH THE USE OF THE SERVICE, APPLICATION AND/OR THE SOFTWARE. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF SUCH */}
                                    {/*      USE OF THE SERVICES REMAINS SOLELY AND ABSOLUTELY WITH YOU AND YOU SHALL HAVE NO RECOURSE WHATSOEVER AGAINST THE HOLO TECH Ltd..*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>INTERNET DELAYS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      THE SERVICE, APPLICATION AND/OR THE SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE */}
                                    {/*      INTERNET AND ELECTRONIC COMMUNICATIONS INCLUDING THE DEVICE USED BY YOU BEING FAULTY, NOT CONNECTED, OUT OF RANGE, SWITCHED OFF */}
                                    {/*      OR NOT FUNCTIONING. THE HOLO TECH Ltd. IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, DAMAGES OR LOSSES RESULTING FROM SUCH */}
                                    {/*      PROBLEMS.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>LIMITATION OF LIABILITY</strong>*/}
                                    {/*      <div>*/}
                                    {/*      ANY CLAIMS AGAINST THE HOLO TECH Ltd. BY YOU SHALL IN ANY EVENT BE LIMITED TO THE AGGREGATE AMOUNT OF ALL AMOUNTS ACTUALLY PAID */}
                                    {/*      BY AND/OR DUE FROM YOU IN UTILISING THE SERVICE DURING THE EVENT GIVING RISE TO SUCH CLAIMS. IN NO EVENT SHALL THE HOLO TECH Ltd. */}
                                    {/*      AND/OR ITS LICENSORS BE LIABLE TO YOU OR ANYONE FOR ANY DIRECT, INDIRECT, PUNITIVE, ECONOMIC, FUTURE SPECIAL, EXEMPLARY, INCIDENTAL, */}
                                    {/*      CONSEQUENTIAL OR OTHER DAMAGES OR LOSSES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY OF ANY KIND WHATSOEVER INCLUDING LOSS OF */}
                                    {/*      LIFE OR LIMBS OR SERIOUS HARM OF ANY KIND WHATSOEVER, EMOTIONAL DISTRESS AND LOSS OF DATA, GOODS, REVENUE, PROFITS, USE OR OTHER */}
                                    {/*      ECONOMIC ADVANTAGE). THE HOLO TECH Ltd. AND/OR ITS LICENSORS SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE OR INJURY WHICH MAY BE */}
                                    {/*      INCURRED BY OR CAUSED TO YOU OR TO ANY PERSON FOR WHOM YOU HAVE BOOKED THE SERVICE FOR, INCLUDING BUT NOT LIMITED TO LOSS, DAMAGE */}
                                    {/*      OR INJURY ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE SERVICE, APPLICATION AND/OR THE SOFTWARE, INCLUDING BUT NOT LIMITED TO */}
                                    {/*      THE USE OR INABILITY TO USE THE SERVICE, APPLICATION AND/OR THE SOFTWARE, ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY */}
                                    {/*      OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY CUSTOMER, PASSENGER OR THIRD */}
                                    {/*      PARTY APPLICATION AND/OR THE SOFTWARE, EVEN IF THE HOLO TECH Ltd. AND/OR ITS LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE */}
                                    {/*      POSSIBILITY OF SUCH DAMAGES.*/}
                                    {/*      IN ANY EVENT, THE HOLO TECH Ltd. SHALL HAVE NO RESPONSIBILITY OR LIABILITY WHATSOEVER FOR ANY DAMAGES, INJURIES, LOSSES OF ANY KIND */}
                                    {/*      WHATSOEVER SUFFERED BY THE CUSTOMER OR PASSENGER, EITHER DIRECTLY OR INDIRECTLY, BECAUSE OF YOUR ACT OR OMISSION OR LACK OF CONTROL */}
                                    {/*      OVER THE VEHICLE OR AWARENESS OF THE ROAD OR ANY OTHER CAUSE THAT IS ATTRIBUTABLE TO YOU DURING THE COURSE OF THE SERVICE.SIMILARLY, */}
                                    {/*      AND IN ANY EVENT, THE HOLO TECH Ltd. SHALL NOT BE HELD RESPONSIBLE OR LIABLE IN ANY MANNER WHATSOEVER FOR ANY DAMAGE, INJURIES, */}
                                    {/*      LOSSES OF ANY KIND WHATSOEVER SUFFERED BY YOU, EITHER DIRECTLY OR INDIRECTLY, BY THE ACTION OR OMISSION OF A PASSENGER OR A CUSTOMER.*/}
                                    {/*       <br/> */}
                                    {/*       IN NO EVENT WHATSOEVER, SHALL THE HOLO TECH Ltd. BE HELD RESPONSIBLE OR LIABLE, IN ANY MANNER WHATSOEVER, FOR CRIMINAL */}
                                    {/*       INVESTIGATION BY POLICE OR OTHER LAW ENFORCEMENT AUTHORITIES, FOR YOUR ACTIONS OR INACTIONS NOR THE ACTIONS OR INACTIONS OF A */}
                                    {/*       PASSENGER OR CUSTOMER INCLUDING BUT NOT LIMITED TO, FOR YOUR BREACH OF YOUR REPRESENTATIONS, WARRANTIES AND ACKNOWLEDGEMENTS MADE */}
                                    {/*       THROUGHOUT THIS TERMS AND CONDITIONS AND SPECIFICALLY THOSE REPRESENTATIONS AND WARRANTIES MADE BY YOU IN PARAGRAPH 5 ABOVE.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>OPTIONAL INSURANCE PREMIUM</strong>*/}
                                    {/*      <div>*/}
                                    {/*      At your sole discretion, you may opt to reap the benefits of the HOLO TECH Ltd.’s good relation with insurance companies and */}
                                    {/*      secure an optional insurance coverage against damages to your vehicle or injuries to yourself and others, at such premium and */}
                                    {/*      conditions as the HOLO TECH Ltd. offers from time to time. In the event that you opt for such an option, the relevant insurance */}
                                    {/*      premium shall be forthwith paid to the HOLO TECH Ltd. by you in addition to the aforesaid Service Fees for the use of the HOLO */}
                                    {/*      TECH Ltd. 's Software, Application and / or Service.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>NOTICE</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The HOLO TECH Ltd. may give notice by means of a general notice on the Application; electronic mail to your email address in the */}
                                    {/*      records of the HOLO TECH Ltd., or by written communication sent by Registered mail or pre-paid post to your address in the record */}
                                    {/*      of the HOLO TECH Ltd.. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting */}
                                    {/*      (if sent by Registered mail or pre-paid post) or 1 hour after sending (if sent by email). You may give notice to the HOLO TECH Ltd. */}
                                    {/*      (such notice shall be deemed given when received by the HOLO TECH Ltd.) by letter sent by courier or registered mail to the HOLO */}
                                    {/*      TECH Ltd. using the contact details as provided in the Application.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>ASSIGNMENT</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The agreement as constituted by the terms and conditions as modified from time to time may not be assigned by you without the prior */}
                                    {/*      written approval of the HOLO TECH Ltd. but may be assigned without your consent by the HOLO TECH Ltd.. Any purported assignment by */}
                                    {/*      you in violation of this section shall be void.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>MISCELLANEOUS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      This Agreement shall be governed by law of your own country, without regard to the choice or conflicts of law provisions of any */}
                                    {/*      jurisdiction, and any disputes, actions, claims or causes of action arising out of or in connection with this Agreement or the */}
                                    {/*      Service shall be subject to the exclusive jurisdiction of the courts of your own country subject to the exhaustion of mandatory */}
                                    {/*      adherence and compliance of procedure for Arbitration provided hereinafter first. Besides, you will also be bound by applicable */}
                                    {/*      laws, rules and regulations of the country of origin of the HOLO TECH Ltd. where it is registered to the extent it is applicable */}
                                    {/*      and consistent with the laws and regulations of your local territorial jurisdiction in pursuant to the bilateral treaty, */}
                                    {/*      international business and commercial laws.*/}
                                    {/*      <br/>*/}
                                    {/*      Furthermore, in the event that the law in your own Country does not allow jurisdiction to be that of the courts of country of */}
                                    {/*      origin of the HOLO TECH Ltd. where it is registered provided that where judgement of such court is unenforceable in your own country */}
                                    {/*      and territory , unresolved disputes shall be referred to the Bangladesh International Arbitration Centre (“BIAC”), in accordance */}
                                    {/*      with the Rules of the BIAC as modified or amended from time to time (the “Rules”) by a sole arbitrator appointed by the mutual */}
                                    {/*      agreement of the parties (the “Arbitrator”). If parties are unable to agree on an arbitrator, the Arbitrator shall be appointed */}
                                    {/*      by the President of BIAC in accordance with the Rules.*/}
                                    {/*      <br/>*/}
                                    {/*      For the purpose of this agreement and the provision of Arbitration provided hereinbefore, the seat and venue of the arbitration */}
                                    {/*      shall be at Dhaka, in the English language and the fees of the Arbitrator shall be borne equally by the parties, provided that the */}
                                    {/*      Arbitrator may require that such fees be borne in such other manner as the Arbitrator determines is required in order for this */}
                                    {/*      arbitration clause to be enforceable under applicable law.*/}
                                    {/*      <br/>*/}
                                    {/*      Provided that, at the discretion of the HOLO TECH Ltd. the Arbitrator as well as place of Arbitration can be changed for effective */}
                                    {/*      and prompt resolve of issues, disputes etc. under this agreement.*/}
                                    {/*      <br/>*/}
                                    {/*      No joint venture, partnership, employment, or agency relationship exists between you, the HOLO TECH Ltd. or any third-party */}
                                    {/*      provider as a result of this Agreement or use of the Service.*/}
                                    {/*      <br/>*/}
                                    {/*      If any provision of the Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining */}
                                    {/*      provisions shall be enforced to the fullest extent under law. This shall, without limitation, also apply to the applicable law */}
                                    {/*      and jurisdiction as stipulated above.*/}
                                    {/*      <br/>*/}
                                    {/*      The failure of the HOLO TECH Ltd. to enforce any right or provision in the Agreement shall not constitute a waiver of such right */}
                                    {/*      or provision unless acknowledged and agreed to by the HOLO TECH Ltd. in writing. The Agreement comprises the entire agreement */}
                                    {/*      between you and the HOLO TECH Ltd. and supersedes all prior or contemporaneous negotiations or discussions, whether written or */}
                                    {/*      oral (if any) between the parties regarding the subject matter contained herein.*/}
                                    {/*      <br/>*/}
                                    {/*      You hereby agree that the HOLO TECH Ltd. is entitled to terminate this Agreement immediately in the event that you are found to */}
                                    {/*      be in breach of any of the terms stipulated in this Agreement. For the avoidance of doubt, the termination of this Agreement shall */}
                                    {/*      not require the HOLO TECH Ltd. to compensate, reimburse or cover any cost incurred by you, including but not limited to the credit */}
                                    {/*      reserved with the HOLO TECH Ltd. or any other monies paid to the HOLO TECH Ltd. in the course of performing your obligations under */}
                                    {/*      this Agreement.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*  </ol>*/}
                                    {/*  <hr />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="ltn__blog-details-wrap">
                                    <div className="widget ltn__banner-widget" style={{textAlign: 'center'}}>
                                        <div className="section-title-area ltn__section-title-2 text-center">
                                            {/*<h6 className="section-subtitle ltn__secondary-color">Apps</h6>*/}
                                            <h3 className="section-title">Terms HOLO English</h3>
                                        </div>

                                        <a href="img/toc/Terms HOLO English.pdf"
                                           download="Terms HOLO English.pdf"><img
                                            src="img/service/file.png" width="100" height="100"/></a>
                                    </div>
                                    {/*<div className="ltn__page-details-inner ltn__blog-details-inner">*/}
                                    {/*<ol>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>INTRODUCTION</strong>*/}
                                    {/*      <div>                        */}
                                    {/*      Important – please read these terms carefully. By using this Service, you agree that you have read, understood, accepted and agreed */}
                                    {/*      with the Terms and Conditions, and the conditions stated in the Code of Conduct. You further agree to the representations made by */}
                                    {/*      yourself below. If you do not agree to or fall within the Terms and Conditions of the Service (as defined below) and wish to */}
                                    {/*      discontinue using the Service, please do not continue using this Application or Service. The terms and conditions stated herein */}
                                    {/*      (collectively, the “Terms and Conditions” or this “Agreement”) constitute a legal agreement between you and the “HOLO TECH Ltd.”.*/}
                                    {/*      <br/>*/}
                                    {/*      In order to use the Service (each as defined below) you must agree to the Terms and Conditions that are set out below. By using the */}
                                    {/*      mobile application supplied to you by HOLO TECH Ltd. (the “Application”), and downloading, installing or using any associated */}
                                    {/*      software supplied by the HOLO TECH Ltd. (the “Software”) of which overall purpose is to enable the Service (each as defined below), */}
                                    {/*      you hereby expressly acknowledge and agree to be bound by the Terms and Conditions, and any future amendments and additions to this */}
                                    {/*      Terms and Conditions as published from time to time at the HOLO TECH Ltd. or through the Application.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}

                                    {/*    <li>*/}
                                    {/*      <strong>SERVICES</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The HOLO TECH Ltd. offers information, method and platform for transportation providers, drivers and vehicle operators to */}
                                    {/*      establish contact with Passengers or Customers, but does not and does not intend to provide transportation services or act in any */}
                                    {/*      way as a taxi operator, transportation carrier or provider, and has no responsibility or liability for any transportation services */}
                                    {/*      provided to Passengers or Customers by you.<br/>*/}
                                    {/*      The HOLO TECH Ltd. reserves the right to modify, refine or correct the terms and conditions of this Agreement or its policies */}
                                    {/*      relating to the Service at any time as it deems fit. Such modifications, refinements or correction to the Terms and Conditions */}
                                    {/*      policies relating to the Service shall be effective upon the posting of an updated version at the HOLO TECH Ltd. . You agree that */}
                                    {/*      it shall be your responsibility to review this Agreement regularly whereupon the continued use of the Service after any such changes, */}
                                    {/*      whether or not reviewed by you, shall constitute your acceptance to such changes. <br/>*/}
                                    {/*      Provided that, this ride sharing business is operated based on a freelancing model, the drivers are not employees of the HOLO */}
                                    {/*      TECH Ltd. . Concerned individual parties, not the HOLO TECH Ltd., shall be solely responsible for any and all claims, judgements */}
                                    {/*      and liabilities resulting from any accident, loss or damage including personal injuries, death, total loss and property damages */}
                                    {/*      which is due to or is alleged to be a result of the passenger transport.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}

                                    {/*    <li>*/}
                                    {/*      <strong>NOT A TRANSPORTATION PROVIDER</strong>*/}
                                    {/*      <div>*/}
                                    {/*      THE HOLO TECH Ltd. IS A TECHNOLOGY COMPANY THAT DOES NOT PROVIDE OR ENGAGE IN TRANSPORTATION SERVICES AND THE HOLO TECH Ltd. */}
                                    {/*      IS NOT A TRANSPORTATION PROVIDER. THE SOFTWARE AND THE APPLICATION ARE INTENDED TO BE USED FOR FACILITATING YOU (AS A */}
                                    {/*      TRANSPORTATION PROVIDER) TO OFFER YOUR TRANSPORTATION SERVICES TO YOUR PASSENGER OR CUSTOMER. THE HOLO TECH Ltd. IS NOT RESPONSIBLE */}
                                    {/*      OR LIABLE FOR THE ACTS AND/OR OMISSIONS WITH REGARD TO ANY SERVICES YOU PROVIDED TO YOUR PASSENGERS, AND FOR ANY ILLEGAL ACTION */}
                                    {/*      COMMITTED BY YOU. YOU SHALL, AT ALL TIME, NOT CLAIM OR CAUSE ANY PERSON TO MISUNDERSTAND THAT YOU ARE THE AGENT, EMPLOYEE OR STAFF */}
                                    {/*      OF THE HOLO TECH Ltd., AND THE SERVICES PROVIDED BY YOU IS NOT, IN ANYWAY, BE DEEMED AS SERVICES OF THE HOLO TECH Ltd.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>RIGHT TO REFUSE AND BLACKLIST</strong>*/}
                                    {/*      <div>*/}
                                    {/*      Notwithstanding anything herein written, the HOLO TECH Ltd. may, at its sole and absolute discretion, blacklist you permanently or */}
                                    {/*      temporarily and reject your request to use the Application and / or Service or any part thereof for such reasons as it deems fit, */}
                                    {/*      including but not limited to receiving complaints about you from customers or employees of the HOLO TECH Ltd. about your behaviour */}
                                    {/*      or interaction with anyone whatsoever (including but not limited to Customers, HOLO TECH Ltd.’s employees, law enforcement, */}
                                    {/*      government authorities) or driving with a competence lower than reasonably expected.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>REPRESENTATION AND WARRANTIES</strong>*/}
                                    {/*      <div>*/}
                                    {/*      By using the Service, you expressly represent and warrant that you are legally entitled to accept and agree to the Terms and */}
                                    {/*      Conditions and that you are at least eighteen (18) years old. Without limiting the generality of the foregoing, the Service is not */}
                                    {/*      available to persons under the age of eighteen (18) or such persons that are forbidden for any reason whatsoever to enter into a */}
                                    {/*      contractual relationship. By using the Service, you further represent and warrant that you have the right, authority and capacity */}
                                    {/*      to use the Service and to abide by the Terms and Conditions. You further confirm that all the information which you provide shall */}
                                    {/*      be true and accurate. Your use of the Service is for your own sole, personal use. You undertake not to authorise others to use your */}
                                    {/*      identity or user status, and you may not assign or otherwise transfer your user account to any other person or entity. When using */}
                                    {/*      the Service you agree to comply with all applicable laws of your country. Besides, you will also be bound by applicable laws, rules */}
                                    {/*      and regulations of the country of origin of the HOLO TECH Ltd. where it is registered to the extent it is applicable and consistent */}
                                    {/*      with the laws and regulations of your local territorial jurisdiction in pursuant to the bilateral treaty, international business */}
                                    {/*      and commercial laws as formulated from time to time.<br/>*/}
                                    {/*      You may only access the Service using authorised and legal means. It is your responsibility to check and ensure that you download */}
                                    {/*      the correct Software for your device. The HOLO TECH Ltd. is not liable if you do not have a compatible handset or if you have */}
                                    {/*      downloaded the wrong version of the Software for your handset. The HOLO TECH Ltd. reserves the right to terminate this Agreement */}
                                    {/*      should you be using the Service with an incompatible or unauthorised device or for purposes other than which the Application or */}
                                    {/*      Software is intended to be used.*/}
                                    {/*        <ul>*/}
                                    {/*        <b>By using the Service, you represent, warrant, undertake and agree that:</b>*/}
                                    {/*          <li>*/}
                                    {/*          You possess a valid driver’s licence and are authorised to operate a motor vehicle and have all the appropriate licences, */}
                                    {/*          approvals and authority to provide transportation for hire to third parties in the jurisdiction in which you use the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You own, or have the legal right and authority to operate, the vehicle which you intend to use when accepting passengers or */}
                                    {/*          customers, and such vehicle is in good operating condition and meets the industry safety standards for vehicles of its kind;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You have a valid policy of liability insurance (in industry-standard coverage amounts) for the operation of your motor */}
                                    {/*          vehicle/passenger vehicle and/or business insurance to cover any anticipated losses related to the operation of a */}
                                    {/*          taxi/passenger delivery service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall forthwith provide to the HOLO TECH Ltd. such information and identity documents, including but not limited to National */}
                                    {/*          ID Card and Driver’s Licences, as reasonably requested by the HOLO TECH Ltd.;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall be solely responsible for any and all claims, judgments and liabilities resulting from any accident, loss or damage */}
                                    {/*          including, but not limited to personal injuries, death, total loss and property damages which is due to or is alleged to be a */}
                                    {/*          result of the passenger transport and/or delivery service howsoever operated;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall obey all local laws related to the operation of passenger transport and/or delivery services and will be solely */}
                                    {/*          responsible for any violations of such local laws;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use the Service for lawful purposes;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use the Service for the purpose for which it is intended to be used;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Application for sending or storing any unlawful material or for fraudulent purposes;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Application and/or the Software to cause nuisance, annoyance, inconvenience or make fake bookings;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Service, Application and/or Software for purposes other than obtaining the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall not contact the Customers for purposes other than the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not impair the proper operation of the network;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not try to harm the Service, Application and/or the Software in any way whatsoever;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use the Software and/or the Application for your own use and will not resell it to a third party;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will keep secure and confidential your account password or any identification we provide you which allows access to the Service;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will provide us with whatever proof of identity the HOLO TECH Ltd. may reasonably request or require;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You agree to provide accurate, current and complete information as required for the Service and undertake the responsibility to */}
                                    {/*          maintain and update your information in a timely manner to keep it accurate, current and complete at all times during the term */}
                                    {/*          of the Agreement. You agree that the HOLO TECH Ltd. may rely on your information as accurate, current and complete. You */}
                                    {/*          acknowledge that if your information is untrue, inaccurate, not current or incomplete in any respect, the HOLO TECH Ltd. has */}
                                    {/*          the right but not the obligation to terminate this Agreement and your use of the Service at any time with or without notice;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will only use an access point or data account (AP) which you are authorised to use;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall not employ any means to defraud the HOLO TECH Ltd. or enrich yourself, through any means, whether fraudulent or */}
                                    {/*          otherwise, whether or not through any event, promotion or campaign launched by the HOLO TECH Ltd. to encourage new subscription */}
                                    {/*          or usage of the Service by new or existing passengers;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You will not use the Service or any part thereof for carrying contraband items as may be listed publicly and updated by the */}
                                    {/*          HOLO TECH Ltd. from to time and if, in the event that you display suspicious behaviour, you will fully comply with the request */}
                                    {/*          of the third-party service provider, any government authority and / or law enforcement, to inspect any bags and / or items you */}
                                    {/*          are carrying with you which may or may not be readily visible;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You are aware that when responding to Passengers’ or Customers’ requests for transportation services, standard telecommunication */}
                                    {/*          charges will apply and which shall be solely borne by you;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You shall not impair or circumvent the proper operation of the network which the Service operates on;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You agree that the Service is provided on a reasonable effort basis;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          You unconditionally agree to assume full responsibility and liability for all loss or damage suffered by yourself, the */}
                                    {/*          Customers or Passengers, the HOLO TECH Ltd., Merchant and/or any third party as a result of any breach of the Terms and */}
                                    {/*          Conditions by you.*/}
                                    {/*          </li>*/}
                                    {/*        </ul>*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>LICENCE GRANT & RESTRICTIONS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The HOLO TECH Ltd. and its licensors, where applicable, hereby grants you a revocable, non-exclusive, non- transferable, */}
                                    {/*      non-assignable, personal, limited licence to use the Application and/or the Software, solely for your own personal purpose, */}
                                    {/*      subject to the terms and conditions of this Agreement. All rights not expressly granted to you are reserved by the HOLO TECH Ltd. */}
                                    {/*      and its licensors.*/}
                                    {/*      <ul>*/}
                                    {/*          <b>You shall not:</b>*/}
                                    {/*          <li>Licence, sublicense, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any */}
                                    {/*            third party the Application and/or the Software in any way;*/}
                                    {/*          </li>*/}
                                    {/*          <li>Modify or make derivative works based on the Application and/or the Software;</li>*/}
                                    {/*          <li>*/}
                                    {/*          Create internet “links” to the Application or “frame” or “mirror” the Software on any other server or wireless or internet-based */}
                                    {/*          device;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*            <ul>*/}
                                    {/*            Reverse engineer or access the Software in order to -*/}
                                    {/*              <li>build a competitive product or service</li>*/}
                                    {/*              <li>build a product using similar ideas, features, functions or graphics of the Application and/or the Software, or</li>*/}
                                    {/*              <li>copy any ideas, features, functions or graphics of the Application and/or the Software</li>*/}
                                    {/*            </ul>                            */}
                                    {/*          </li>*/}
                                    {/*        <li>*/}
                                    {/*        Launch an automated program or script, including, but not limited to, web spiders, web crawlers, web robots, web ants, web */}
                                    {/*        indexers, bots, viruses or worms, or any program which may make multiple server requests per second, or unduly burdens or hinders */}
                                    {/*        the operation and/or performance of the Application and/or the Software;*/}
                                    {/*        </li>*/}
                                    {/*        <li>*/}
                                    {/*        Use any robot, spider, site search/retrieval application, or other manual or automatic device or process to retrieve, index, */}
                                    {/*        “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents;*/}
                                    {/*        </li>*/}
                                    {/*        Post, distribute or reproduce in any way any copyrighted material, trademarks, or other proprietary information without obtaining */}
                                    {/*        the prior consent of the owner of such proprietary rights;*/}
                                    {/*        <li>*/}
                                    {/*        Remove any copyright, trademark or other proprietary rights notices contained in the Service.*/}
                                    {/*        </li>*/}
                                    {/*      </ul>*/}
                                    {/*      <ul>*/}
                                    {/*        <b>You may use the Software and/or the Application only for your personal, non-commercial purposes and shall not use the Software and/or the */}
                                    {/*          Application to:</b>*/}
                                    {/*        <li>Send spam or otherwise duplicative or unsolicited messages;</li>*/}
                                    {/*        <li>Send or store infringing, obscene, threatening, libellous, or otherwise unlawful or tortious material, including but not */}
                                    {/*          limited to materials harmful to children or violate of third party privacy rights;</li>*/}
                                    {/*        <li>Send material containing software viruses, worms, Trojan horses or other harmful computer code, files, scripts, agents or */}
                                    {/*          programs;</li>*/}
                                    {/*        <li>Interfere with or disrupt the integrity or performance of the Software and/or the Application or the data contained therein;</li>*/}
                                    {/*        <li>Attempt to gain unauthorised access to the Software and/or the Application or its related systems or networks; or</li>*/}
                                    {/*        <li>Impersonate any person or entity or otherwise misrepresent your affiliation with a person or entity to abstain from any */}
                                    {/*          conduct that could possibly damage the HOLO TECH Ltd.’s reputation or amount to being disreputable.</li>*/}
                                    {/*      </ul>*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>PAYMENT TERMS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      Any fees which the HOLO TECH Ltd. may charge you for the Service are due immediately and are non-refundable (“Service Fee”). */}
                                    {/*      This no-refund policy shall apply at all times regardless of your decision to terminate your usage, our decision to terminate or */}
                                    {/*      suspend your usage, disruption caused to the Service either planned, accidental or intentional, or any reason whatsoever. <br/>*/}
                                    {/*      For prepaid packages, you will pay before you use the software. For postpaid packages, you will pay the due before buying the next */}
                                    {/*      package. You understand the significance of this requirement and you acknowledge that you may be blacklisted for failure to comply */}
                                    {/*      with the aforesaid requirement and that your ability to use the Service shall be barred until due compliance is made in this */}
                                    {/*      regard. <br/>*/}
                                    {/*      The HOLO TECH Ltd. may, at its sole discretion, make promotional offers with different features and different rates to any of the */}
                                    {/*      Passengers or Customers whereby these promotional offers shall accordingly be honoured by you. The HOLO TECH Ltd. may determine or */}
                                    {/*      change the Service Fee as the HOLO TECH Ltd. deems in its absolute discretion as necessary or appropriate for the business.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>PAYMENT BY PASSENGER</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The Passenger or Customer may choose to pay for the Service by cash. <br/>*/}
                                    {/*      Any complaints that the Passenger or Customer shall have regarding the transportation provided by the driver shall be taken up by the */}
                                    {/*      Passenger or Customer with the driver directly. <br/>*/}
                                    {/*      The HOLO TECH Ltd. retains the right to suspend the processing of any transaction where it reasonably believes that the transaction */}
                                    {/*      may be fraudulent, illegal or involves any criminal activity or where it reasonably believes the Passenger or Customer to be in */}
                                    {/*      breach of the Terms and Conditions between the Passenger or Customer and the HOLO TECH Ltd.. In such an event, the driver shall */}
                                    {/*      not hold the HOLO TECH Ltd. liable for any withholding of, delay in, suspension of or cancellation of, any payment to the */}
                                    {/*      driver. <br/>*/}
                                    {/*      You ( Either As User or driver ), agree that you will cooperate in relation to any criminal investigation that is required and to */}
                                    {/*      assist the HOLO TECH Ltd. in complying with any internal investigations, instructions from the authorities or requirements of */}
                                    {/*      prevailing laws or regulations for the time being in force.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>TAXES</strong>*/}
                                    {/*      <div>*/}
                                    {/*      You agree that this Agreement shall be subject to all prevailing statutory taxes, duties, fees, charges and/or costs, however */}
                                    {/*      denominated, as may be in force and in connection with any future taxes that may be introduced at any point of time. You further */}
                                    {/*      agree to use your best efforts to do everything necessary and required by the relevant laws to enable, assist and/or defend the */}
                                    {/*      HOLO TECH Ltd. to claim or verify any input tax credit, set off, rebate or refund in respect of any taxes paid or payable in */}
                                    {/*      connection with the Services supplied under this Agreement.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>CONFIDENTIALITY</strong>*/}
                                    {/*      <div>*/}
                                    {/*      You shall maintain in confidence all information and data relating to the HOLO TECH Ltd., its services, products, business */}
                                    {/*      affairs, marketing and promotion plans or other operations of the HOLO TECH Ltd. and its associated companies which are disclosed */}
                                    {/*      to you by or on behalf of the HOLO TECH Ltd. (whether orally or in writing and whether before, on or after the date of this */}
                                    {/*      Agreement) or which are otherwise directly or indirectly acquired by you from the HOLO TECH Ltd., or any of its affiliated */}
                                    {/*      companies, or created in the course of this Agreement. You shall further ensure that you only use such confidential information */}
                                    {/*      in order to perform the Services, and shall not without the HOLO TECH Ltd.’s prior written consent, disclose such information to */}
                                    {/*      any third-party nor use it for any other purpose except for providing services under this agreement. You shall only disclose such */}
                                    {/*      information to such officers, employees and agents as need to know it to fulfil its obligations under this Agreement. <br/>*/}
                                    {/*      <ul>*/}
                                    {/*      <b>The above obligations of confidentiality shall not apply to the extent that you can show that the relevant information:</b>*/}
                                    {/*        <li>Was at the time of receipt already in the Recipient’s possession;</li>*/}
                                    {/*        <li>Is, or becomes in the future, public knowledge through no fault or omission of the Recipient;</li>*/}
                                    {/*        <li>Was received from a third-party having the right to disclose it; or*/}
                                    {/*        Is required to be disclosed by law.</li>*/}
                                    {/*      </ul>*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>PERSONAL DATA PROTECTION</strong>*/}
                                    {/*      <div>*/}
                                    {/*      You agree and consent to the HOLO TECH Ltd. using and processing your Personal Data for the Purposes and in the manner as identified */}
                                    {/*      hereunder.<br/>*/}
                                    {/*      For the purposes of this Agreement, “Personal Data” means information about you, from which you are identifiable, including but */}
                                    {/*      not limited to your name, identification card number, birth certificate number, passport number, nationality, address, telephone */}
                                    {/*      number, credit or debit card details, race, gender, date of birth, email address, any information about you which you have provided */}
                                    {/*      to the HOLO TECH Ltd. in registration forms, application forms or any other similar forms and/or any information about you that */}
                                    {/*      has been or may be collected, stored, used and processed by the HOLO TECH Ltd. from time to time and includes sensitive personal */}
                                    {/*      data such as data relating to health, religious or other similar beliefs. <br/>*/}
                                    {/*      The provision of your Personal Data is voluntary. However, if you do not provide the HOLO TECH Ltd. your Personal Data, your */}
                                    {/*      request for the Application may be incomplete and the HOLO TECH Ltd. will not be able to process your Personal Data for the */}
                                    {/*      Purposes outlined below and may cause the HOLO TECH Ltd. to be unable to allow you to use the Service.*/}
                                    {/*        <ul>*/}
                                    {/*          <b>The HOLO TECH Ltd. may use and process your Personal Data for business and activities of the HOLO TECH Ltd. which shall include, */}
                                    {/*            without limitation the following (“the Purpose”):</b>*/}
                                    {/*          <li>*/}
                                    {/*          To perform the HOLO TECH Ltd.’s obligations in respect of any contract entered into with you;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To provide you with any services pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To process your participation in any events, promotions, activities, focus groups, research studies, contests, promotions, */}
                                    {/*          polls, surveys or any productions and to communicate with you regarding your attendance thereto;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          Process, manage or verify your application for the Service pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To validate and/or process payments pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To develop, enhance and provide what is required pursuant to the Terms and Conditions herein to meet your needs;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To process any refunds, rebates and/or charges pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To facilitate or enable any checks as may be required pursuant to the Terms and Conditions herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To respond to questions, comments and feedback from you;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To communicate with you for any of the purposes listed herein;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          For internal administrative purposes, such as auditing, data analysis, database records;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          For purposes of detection, prevention and prosecution of crime;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          For the HOLO TECH Ltd. to comply with its obligations under law;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To send you alerts, newsletters, updates, mailers, promotional materials, special privileges, festive greetings from the HOLO */}
                                    {/*          TECH Ltd., its partners, advertisers and/or sponsors;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To notify and invite you to events or activities organised by the HOLO TECH Ltd., its partners, advertisers, and/or sponsors;*/}
                                    {/*          </li>*/}
                                    {/*          <li>*/}
                                    {/*          To share your Personal Data amongst the companies within the HOLO TECH Ltd.’s group of companies comprising the subsidiaries, */}
                                    {/*          associate companies and/or jointly controlled entities of the holding HOLO TECH Ltd. of the group (“the Group”) and with the */}
                                    {/*          HOLO TECH Ltd.’s and Group’s agents, third party providers, developers, advertisers, partners, event companies or sponsors who */}
                                    {/*          may communicate with you for any reasons whatsoever.*/}
                                    {/*          </li>*/}
                                    {/*        </ul>*/}
                                    {/*        If you do not consent to the HOLO TECH Ltd. processing your Personal Data for any of the Purposes, please notify the HOLO TECH Ltd. */}
                                    {/*        using the support contact details as provided in the Application.<br/>*/}
                                    {/*        If any of the Personal Data that you have provided to us changes, for example, if you change your e- mail address, */}
                                    {/*        telephone number, payment details or if you wish to cancel your account, please update your details by sending your request */}
                                    {/*        to the support contact details as provided in the Application. The HOLO TECH Ltd. will, to the best of our abilities, effect */}
                                    {/*        such changes as requested within fourteen (14) working days of receipt of such notice of change.<br/>*/}
                                    {/*        By submitting your information, you consent to the use of that information as set out in the form of submission and in this Agreement.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*    <strong>THIRD PARTY INTERACTIONS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      During the Service tenure, you may enter into correspondence with, provide services to, or participate in promotions of third */}
                                    {/*      party providers, advertisers or sponsors showing their goods and/or services through the Service, Software and/or the Application. */}
                                    {/*      Any such activity, and any terms, conditions, warranties or representations associated with such activity are solely between you */}
                                    {/*      and the applicable third-party. The HOLO TECH Ltd. and its licensors shall have no liability, obligation or responsibility for any */}
                                    {/*      such correspondence, purchase, transaction or promotion between you and any such third- party. The Group does not endorse any */}
                                    {/*      applications or sites on the Internet that are linked through the Service, Application and/or the Software, and in no event shall */}
                                    {/*      the HOLO TECH Ltd., its licensors or the Group be responsible for any content, products, services or other materials on or */}
                                    {/*      available from such sites or third-party providers. The HOLO TECH Ltd. provides the Service to you pursuant to the Terms and */}
                                    {/*      Conditions. You recognize, however, that certain third-party providers of transportation, goods and/or services may require your */}
                                    {/*      agreement to additional or different terms and conditions prior to your use of or access to such goods or services, and the HOLO */}
                                    {/*      TECH Ltd. is not a party to and disclaims any and all responsibility and/or liability arising from such agreements between you and */}
                                    {/*      the third-party providers.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>INDEMNIFICATION</strong>*/}
                                    {/*      <div>*/}
                                    {/*        {" "}*/}
                                    {/*        By agreeing to this Agreement for providing the Service, you agree that you shall defend, indemnify and hold the HOLO TECH Ltd., */}
                                    {/*        its licensors and each such party’s parent organisations, subsidiaries, affiliates, officers, directors, members, employees, */}
                                    {/*        attorneys and agents harmless from and against any and all claims, costs, damages, losses, liabilities and expenses */}
                                    {/*        (including attorneys’ fees and costs) arising out of or in connection with:*/}
                                    {/*      </div>*/}
                                    {/*      <ul>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your violation or breach of any term of these Terms and Conditions or any applicable law or regulation, including any local */}
                                    {/*        laws or ordinances, whether or not referenced herein; */}
                                    {/*        </li>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your violation of any rights of any third party, including, but not limited to passengers of your vehicle or the vehicle that you */}
                                    {/*        have control over, other motorists, and pedestrians, as a result of your own interaction with any third party; */}
                                    {/*        </li>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your use (or misuse) of the Application and/or Software; and*/}
                                    {/*        </li>*/}
                                    {/*        <li dir="ltr">*/}
                                    {/*        Your ownership, use or operation of a motor vehicle or passenger vehicle, including your carriage of Passengers or Customers who */}
                                    {/*        have procured your transportation services via the Service, or of their goods.*/}
                                    {/*        </li>*/}
                                    {/*      </ul>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>DISCLAIMER OF WARRANTIES</strong>*/}
                                    {/*      <div>*/}
                                    {/*     <ul>   */}
                                    {/*     THE HOLO TECH Ltd. MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, TIMELINESS, QUALITY, SUITABILITY, */}
                                    {/*     AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICES, APPLICATION AND/OR THE SOFTWARE. THE HOLO TECH Ltd. DOES NOT REPRESENT OR */}
                                    {/*     WARRANT THAT :*/}
                                    {/*      <li>THE USE OF THE SERVICE, APPLICATION AND/OR THE SOFTWARE WILL BE SECURE, TIMELY, UNINTERRUPTED OR ERROR-FREE OR OPERATE IN */}
                                    {/*        COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA </li>                         */}
                                    {/*      <li>THE SERVICE WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS </li>                         */}
                                    {/*      <li>ANY STORED DATA WILL BE ACCURATE OR RELIABLE </li>                         */}
                                    {/*      <li>THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIALS PURCHASED OR OBTAINED BY YOU THROUGH THE APPLICATION */}
                                    {/*        WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS </li>                         */}
                                    {/*      <li>ERRORS OR DEFECTS IN THE APPLICATION AND/OR THE SOFTWARE WILL BE CORRECTED OR </li>                         */}
                                    {/*      <li>THE APPLICATION OR THE SERVER(S) THAT MAKE THE APPLICATION AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR </li>                         */}
                                    {/*      <li>THE APPLICATION AND/OR THE SOFTWARE TRACKS YOU OR THE VEHICLE USED BY THE TRANSPORTATION PROVIDER. </li>                         */}
                                    {/*      </ul>*/}
                                    {/*      THE SERVICE IS PROVIDED TO YOU STRICTLY ON AN “AS IS” BASIS. ALL CONDITIONS, REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, */}
                                    {/*      IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR */}
                                    {/*      PURPOSE, OR NON-INFRINGEMENT OF THIRD PARTY RIGHTS, ARE HEREBY EXCLUDED AND DISCLAIMED TO THE HIGHEST AND MAXIMUM EXTENT. <br/>*/}

                                    {/*      THE HOLO TECH Ltd. MAKES NO REPRESENTATION, WARRANTY, OR GUARANTEE AS TO THE RELIABILITY, SAFETY, TIMELINESS, QUALITY, SUITABILITY */}
                                    {/*      OR AVAILABILITY OF ANY SERVICES, INCLUDING BUT NOT LIMITED TO THE TRANSPORTATION SERVICES PROVIDED BY YOU TO CUSTOMERS OR PASSENGERS */}
                                    {/*      THROUGH THE USE OF THE SERVICE, APPLICATION AND/OR THE SOFTWARE. YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF SUCH */}
                                    {/*      USE OF THE SERVICES REMAINS SOLELY AND ABSOLUTELY WITH YOU AND YOU SHALL HAVE NO RECOURSE WHATSOEVER AGAINST THE HOLO TECH Ltd..*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>INTERNET DELAYS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      THE SERVICE, APPLICATION AND/OR THE SOFTWARE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF THE */}
                                    {/*      INTERNET AND ELECTRONIC COMMUNICATIONS INCLUDING THE DEVICE USED BY YOU BEING FAULTY, NOT CONNECTED, OUT OF RANGE, SWITCHED OFF */}
                                    {/*      OR NOT FUNCTIONING. THE HOLO TECH Ltd. IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, DAMAGES OR LOSSES RESULTING FROM SUCH */}
                                    {/*      PROBLEMS.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>LIMITATION OF LIABILITY</strong>*/}
                                    {/*      <div>*/}
                                    {/*      ANY CLAIMS AGAINST THE HOLO TECH Ltd. BY YOU SHALL IN ANY EVENT BE LIMITED TO THE AGGREGATE AMOUNT OF ALL AMOUNTS ACTUALLY PAID */}
                                    {/*      BY AND/OR DUE FROM YOU IN UTILISING THE SERVICE DURING THE EVENT GIVING RISE TO SUCH CLAIMS. IN NO EVENT SHALL THE HOLO TECH Ltd. */}
                                    {/*      AND/OR ITS LICENSORS BE LIABLE TO YOU OR ANYONE FOR ANY DIRECT, INDIRECT, PUNITIVE, ECONOMIC, FUTURE SPECIAL, EXEMPLARY, INCIDENTAL, */}
                                    {/*      CONSEQUENTIAL OR OTHER DAMAGES OR LOSSES OF ANY TYPE OR KIND (INCLUDING PERSONAL INJURY OF ANY KIND WHATSOEVER INCLUDING LOSS OF */}
                                    {/*      LIFE OR LIMBS OR SERIOUS HARM OF ANY KIND WHATSOEVER, EMOTIONAL DISTRESS AND LOSS OF DATA, GOODS, REVENUE, PROFITS, USE OR OTHER */}
                                    {/*      ECONOMIC ADVANTAGE). THE HOLO TECH Ltd. AND/OR ITS LICENSORS SHALL NOT BE LIABLE FOR ANY LOSS, DAMAGE OR INJURY WHICH MAY BE */}
                                    {/*      INCURRED BY OR CAUSED TO YOU OR TO ANY PERSON FOR WHOM YOU HAVE BOOKED THE SERVICE FOR, INCLUDING BUT NOT LIMITED TO LOSS, DAMAGE */}
                                    {/*      OR INJURY ARISING OUT OF, OR IN ANY WAY CONNECTED WITH THE SERVICE, APPLICATION AND/OR THE SOFTWARE, INCLUDING BUT NOT LIMITED TO */}
                                    {/*      THE USE OR INABILITY TO USE THE SERVICE, APPLICATION AND/OR THE SOFTWARE, ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY */}
                                    {/*      OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY CUSTOMER, PASSENGER OR THIRD */}
                                    {/*      PARTY APPLICATION AND/OR THE SOFTWARE, EVEN IF THE HOLO TECH Ltd. AND/OR ITS LICENSORS HAVE BEEN PREVIOUSLY ADVISED OF THE */}
                                    {/*      POSSIBILITY OF SUCH DAMAGES.*/}
                                    {/*      IN ANY EVENT, THE HOLO TECH Ltd. SHALL HAVE NO RESPONSIBILITY OR LIABILITY WHATSOEVER FOR ANY DAMAGES, INJURIES, LOSSES OF ANY KIND */}
                                    {/*      WHATSOEVER SUFFERED BY THE CUSTOMER OR PASSENGER, EITHER DIRECTLY OR INDIRECTLY, BECAUSE OF YOUR ACT OR OMISSION OR LACK OF CONTROL */}
                                    {/*      OVER THE VEHICLE OR AWARENESS OF THE ROAD OR ANY OTHER CAUSE THAT IS ATTRIBUTABLE TO YOU DURING THE COURSE OF THE SERVICE.SIMILARLY, */}
                                    {/*      AND IN ANY EVENT, THE HOLO TECH Ltd. SHALL NOT BE HELD RESPONSIBLE OR LIABLE IN ANY MANNER WHATSOEVER FOR ANY DAMAGE, INJURIES, */}
                                    {/*      LOSSES OF ANY KIND WHATSOEVER SUFFERED BY YOU, EITHER DIRECTLY OR INDIRECTLY, BY THE ACTION OR OMISSION OF A PASSENGER OR A CUSTOMER.*/}
                                    {/*       <br/> */}
                                    {/*       IN NO EVENT WHATSOEVER, SHALL THE HOLO TECH Ltd. BE HELD RESPONSIBLE OR LIABLE, IN ANY MANNER WHATSOEVER, FOR CRIMINAL */}
                                    {/*       INVESTIGATION BY POLICE OR OTHER LAW ENFORCEMENT AUTHORITIES, FOR YOUR ACTIONS OR INACTIONS NOR THE ACTIONS OR INACTIONS OF A */}
                                    {/*       PASSENGER OR CUSTOMER INCLUDING BUT NOT LIMITED TO, FOR YOUR BREACH OF YOUR REPRESENTATIONS, WARRANTIES AND ACKNOWLEDGEMENTS MADE */}
                                    {/*       THROUGHOUT THIS TERMS AND CONDITIONS AND SPECIFICALLY THOSE REPRESENTATIONS AND WARRANTIES MADE BY YOU IN PARAGRAPH 5 ABOVE.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>OPTIONAL INSURANCE PREMIUM</strong>*/}
                                    {/*      <div>*/}
                                    {/*      At your sole discretion, you may opt to reap the benefits of the HOLO TECH Ltd.’s good relation with insurance companies and */}
                                    {/*      secure an optional insurance coverage against damages to your vehicle or injuries to yourself and others, at such premium and */}
                                    {/*      conditions as the HOLO TECH Ltd. offers from time to time. In the event that you opt for such an option, the relevant insurance */}
                                    {/*      premium shall be forthwith paid to the HOLO TECH Ltd. by you in addition to the aforesaid Service Fees for the use of the HOLO */}
                                    {/*      TECH Ltd. 's Software, Application and / or Service.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>NOTICE</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The HOLO TECH Ltd. may give notice by means of a general notice on the Application; electronic mail to your email address in the */}
                                    {/*      records of the HOLO TECH Ltd., or by written communication sent by Registered mail or pre-paid post to your address in the record */}
                                    {/*      of the HOLO TECH Ltd.. Such notice shall be deemed to have been given upon the expiration of 48 hours after mailing or posting */}
                                    {/*      (if sent by Registered mail or pre-paid post) or 1 hour after sending (if sent by email). You may give notice to the HOLO TECH Ltd. */}
                                    {/*      (such notice shall be deemed given when received by the HOLO TECH Ltd.) by letter sent by courier or registered mail to the HOLO */}
                                    {/*      TECH Ltd. using the contact details as provided in the Application.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>ASSIGNMENT</strong>*/}
                                    {/*      <div>*/}
                                    {/*      The agreement as constituted by the terms and conditions as modified from time to time may not be assigned by you without the prior */}
                                    {/*      written approval of the HOLO TECH Ltd. but may be assigned without your consent by the HOLO TECH Ltd.. Any purported assignment by */}
                                    {/*      you in violation of this section shall be void.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*    <li>*/}
                                    {/*      <strong>MISCELLANEOUS</strong>*/}
                                    {/*      <div>*/}
                                    {/*      This Agreement shall be governed by law of your own country, without regard to the choice or conflicts of law provisions of any */}
                                    {/*      jurisdiction, and any disputes, actions, claims or causes of action arising out of or in connection with this Agreement or the */}
                                    {/*      Service shall be subject to the exclusive jurisdiction of the courts of your own country subject to the exhaustion of mandatory */}
                                    {/*      adherence and compliance of procedure for Arbitration provided hereinafter first. Besides, you will also be bound by applicable */}
                                    {/*      laws, rules and regulations of the country of origin of the HOLO TECH Ltd. where it is registered to the extent it is applicable */}
                                    {/*      and consistent with the laws and regulations of your local territorial jurisdiction in pursuant to the bilateral treaty, */}
                                    {/*      international business and commercial laws.*/}
                                    {/*      <br/>*/}
                                    {/*      Furthermore, in the event that the law in your own Country does not allow jurisdiction to be that of the courts of country of */}
                                    {/*      origin of the HOLO TECH Ltd. where it is registered provided that where judgement of such court is unenforceable in your own country */}
                                    {/*      and territory , unresolved disputes shall be referred to the Bangladesh International Arbitration Centre (“BIAC”), in accordance */}
                                    {/*      with the Rules of the BIAC as modified or amended from time to time (the “Rules”) by a sole arbitrator appointed by the mutual */}
                                    {/*      agreement of the parties (the “Arbitrator”). If parties are unable to agree on an arbitrator, the Arbitrator shall be appointed */}
                                    {/*      by the President of BIAC in accordance with the Rules.*/}
                                    {/*      <br/>*/}
                                    {/*      For the purpose of this agreement and the provision of Arbitration provided hereinbefore, the seat and venue of the arbitration */}
                                    {/*      shall be at Dhaka, in the English language and the fees of the Arbitrator shall be borne equally by the parties, provided that the */}
                                    {/*      Arbitrator may require that such fees be borne in such other manner as the Arbitrator determines is required in order for this */}
                                    {/*      arbitration clause to be enforceable under applicable law.*/}
                                    {/*      <br/>*/}
                                    {/*      Provided that, at the discretion of the HOLO TECH Ltd. the Arbitrator as well as place of Arbitration can be changed for effective */}
                                    {/*      and prompt resolve of issues, disputes etc. under this agreement.*/}
                                    {/*      <br/>*/}
                                    {/*      No joint venture, partnership, employment, or agency relationship exists between you, the HOLO TECH Ltd. or any third-party */}
                                    {/*      provider as a result of this Agreement or use of the Service.*/}
                                    {/*      <br/>*/}
                                    {/*      If any provision of the Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining */}
                                    {/*      provisions shall be enforced to the fullest extent under law. This shall, without limitation, also apply to the applicable law */}
                                    {/*      and jurisdiction as stipulated above.*/}
                                    {/*      <br/>*/}
                                    {/*      The failure of the HOLO TECH Ltd. to enforce any right or provision in the Agreement shall not constitute a waiver of such right */}
                                    {/*      or provision unless acknowledged and agreed to by the HOLO TECH Ltd. in writing. The Agreement comprises the entire agreement */}
                                    {/*      between you and the HOLO TECH Ltd. and supersedes all prior or contemporaneous negotiations or discussions, whether written or */}
                                    {/*      oral (if any) between the parties regarding the subject matter contained herein.*/}
                                    {/*      <br/>*/}
                                    {/*      You hereby agree that the HOLO TECH Ltd. is entitled to terminate this Agreement immediately in the event that you are found to */}
                                    {/*      be in breach of any of the terms stipulated in this Agreement. For the avoidance of doubt, the termination of this Agreement shall */}
                                    {/*      not require the HOLO TECH Ltd. to compensate, reimburse or cover any cost incurred by you, including but not limited to the credit */}
                                    {/*      reserved with the HOLO TECH Ltd. or any other monies paid to the HOLO TECH Ltd. in the course of performing your obligations under */}
                                    {/*      this Agreement.*/}
                                    {/*      </div>*/}
                                    {/*    </li>*/}
                                    {/*  </ol>*/}
                                    {/*  <hr />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterBlack/>
            </>
        );
    }
}

export default Terms;
