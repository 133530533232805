import React, { Component } from "react";
import { Link } from "react-router-dom";

import FooterBlack from "../components/footerblack";
import HeaderBlack from "../components/headerblack";

import loadjs from "loadjs";
// import Preloader from "../components/preloader";
import MobileNav from "../components/mobilenav";

class About extends Component {
  componentDidMount() {
    loadjs("./js/main.js");

    loadjs("./js/plugins.js");
  }
  render() {
    return (
      <>
        <HeaderBlack />
        <MobileNav />
        <div className="ltn__utilize-overlay" />
        {/* BREADCRUMB AREA START */}
        <div
          className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
          data-bg="img/bg/dhaka.jpeg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      Welcome to Holo
                    </h6>
                    <h1 className="section-title white-color">About Us</h1>
                  </div>
                  <div className="ltn__breadcrumb-list">
                    <ul>
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li>About</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* BREADCRUMB AREA END */}
        {/* ABOUT US AREA START */}
        <div className="ltn__about-us-area pt-120-- pb-120">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 align-self-center">
                <div className="about-us-img-wrap about-img-left">
                  <img src="img/biker.jpeg" alt="About Us" />
                  <div className="about-us-img-info about-us-img-info-2">
                    <div className="about-us-img-info-inner">
                      <h1>
                        We value
                        <span />
                      </h1>
                      <h6>our users</h6>
                      {/* <span class="dots-bottom"></span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      About Us
                    </h6>
                    <h1 className="section-title">
                      Your Satisfaction Is Our First &amp; Main Priority
                      <span>.</span>
                    </h1>
                    <p>
                      We're trying to solve the existing cultural problems of
                      our ride sharing industry.
                    </p>
                  </div>
                  <p>
                    Ridesharing is not a new concept not only in Bangladesh but
                    also all over the world. It has been a very popular and
                    convenient way of commuting in urban areas in recent years.
                    However, it is natural that the exact same service might
                    vary from place to place, from culture to culture. The
                    concept of sharing vehicles only by words of mouth has been
                    carried out in this country even before digital ride-sharing
                    was introduced in the western world. That makes us
                    fundamentally different from them, which results in
                    occasional conflicts of interest in adapting the typical
                    digital ride-sharing system. We opt to combine the benefits
                    of the app-based digital ride-sharing systems with the
                    practices we are familiar with for decades, hence reducing
                    the gap between them as much as possible.{" "}
                  </p>
                  <p>
                    Although the concept of digital ride-sharing came from
                    sharing someone's own vehicle while commuting to their own
                    routes for some extra monetary benefits, it has become a
                    potential occupation for a huge number of youths in our
                    country. Ride sharing can be a great source of income in a
                    country like ours, where a huge number of people have to
                    find a convenient way to commute to their workplaces and
                    institutions every day. HOLO aims to aid these youths to
                    take this opportunity in a more favorable way. It is not
                    uncommon to find disagreement between the two ends of this
                    service due to mismatched destination preferences or
                    personal preferences of payments, and our goal is to reduce
                    the rate of these disagreements and ensure maximum benefits
                    for both sides.
                  </p>
                  <p>
                    Another inspiration behind the journey of HOLO is to
                    simplify the process for people who are not comfortable with
                    using extremely cybernated procedures. We understand highly
                    sophisticated digital systems might turn into overwhelming,
                    sometimes frightening to people who have just got access to
                    smartphones in recent years, for example, the elderly
                    population. Despite age, gender or ethnicity, everyone has
                    the need for commutation and we believe it is our duty to
                    ensure the benefits of technology to every single person for
                    this basic need. Wouldn't it be great if use an app that
                    makes your ride-sharing experience as simple and easy as
                    calling a rickshaw?
                  </p>
                  <div className="btn-wrapper">
                    <Link
                      to="/service"
                      className="theme-btn-3 btn btn-effect-4"
                    >
                      OUR SERVICES
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="call-to-action-area call-to-action-5 bg-image bg-overlay-theme-90 pt-40 pb-25"
          data-bg="img/bg/13.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="call-to-action-inner call-to-action-inner-5 text-center">
                  <h2 className="white-color text-decoration">
                    24/7 Availability
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ABOUT US AREA END */}
        <FooterBlack />
      </>
    );
  }
}

export default About;
