import React from "react";
import { Link } from "react-router-dom";
import FooterWhite from "../components/footerwhite";
import HeaderWhite from "../components/headerwhite";
// import FontAwesomeIcon from 'fontawesome';
import loadjs from "loadjs";
import MobileNav from "../components/mobilenav";
import axios from "axios";

const rider_url = "https://api.holoapp.tech/rides/public/get-public-ride-count";

const user_url =
  "https://api.holoapp.tech/accounts/public/get-total-user-count";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      riders: null,
      users: null,
    };
  }

  componentDidMount() {
    loadjs("./js/plugins.js");
    loadjs("./js/main.js");

    const requestOne = axios.get(rider_url);
    const requestTwo = axios.get(user_url);

    axios
      .all([requestOne, requestTwo])
      .then(
        axios.spread((...responses) => {
          const responseOne = responses[0];
          const responseTwo = responses[1];

          this.setState({
            riders: responseOne.data.count,
            users: responseTwo.data.count,
          });
        })
      )
      .catch((error) => {
        if (error.code === "ECONNABORTED") return "timeout";
        else throw error;
      });
  }

  render() {
    return (
      <>
        <HeaderWhite />

        <MobileNav />
        <div className="ltn__utilize-overlay" />

        <div className="ltn__slider-area ltn__slider-3  section-bg-1">
          <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3">
              <div className="ltn__slide-item-inner">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info" style={{ width: "50%" }}>
                        <div className="slide-item-info-inner ltn__slide-animation">
                          <div className="slide-video mb-50" style={{ width: "80%" }}>
                            {/*<a*/}
                            {/*  className="ltn__video-icon-2 ltn__video-icon-2-border"*/}
                            {/*  href="https://youtu.be/wYSTa9J6fHk"*/}
                            {/*  data-rel="lightcase:myCollection"*/}
                            {/*>*/}
                            <a  href="https://www.youtube.com/embed/wYSTa9J6fHk"

                                    className="ltn__video-icon-2 ltn__video-icon-2-border"
                                   data-rel="lightcase:myCollection"
                            >
                              <i className="fa fa-play" />
                            </a>
                          </div>
                          <h1 className="slide-title animated ">
                            <div>
                              Digitizing the traditional
                              ride-sharing practices for  
                              digital Bangladesh
                            </div>
                          </h1>
                          <h6 className="slide-sub-title animated">
                            {" "}
                            Introducing a digital revolution that cares for the
                            needs of both the ends alike.
                          </h6>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/service"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              Find The Apps
                            </Link>
                            <Link
                              to="/about"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img">
                        <img src="img/city.jpeg" alt="#" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3">
              <div className="ltn__slide-item-inner  text-right">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12 align-self-center">
                      <div className="slide-item-info" style={{ width: "50%" }}>
                        <div className="slide-item-info-inner ltn__slide-animation" style={{ width: "80%", marginLeft: "20%" }}>
                          <h6 className="slide-sub-title ltn__secondary-color animated">
                            Simplify Your Experience
                          </h6>
                          <h1 className="slide-title animated ">
                            Download The App & SIMPLIFY Your
                            Ride Sharing Experience
                          </h1>
                          <div className="slide-brief animated">
                            <p>Click the button to get the app</p>
                          </div>
                          <div className="btn-wrapper animated">
                            <Link
                              to="/service"
                              className="theme-btn-1 btn btn-effect-1"
                            >
                              Find The Apps
                            </Link>
                            <Link
                              to="/about"
                              className="btn btn-transparent btn-effect-3"
                            >
                              LEARN MORE
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="slide-item-img slide-img-left">
                        <img src="img/city.jpeg" alt="#" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__feature-area section-bg-1 pt-70 pb-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 ">
                <div className="ltn__feature-item ltn__feature-item-5 section-bg-2 text-center">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="fas fa-hand-holding-usd" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h2>
                      <Link to="/service">No Worry about Commission</Link>
                    </h2>
                    <p>
                      Buy prepaid packages to enjoy unlimited rides and find
                      your ride partners without sharing percentages of your
                      income from every ride. Pay once and enjoy the benefits as
                      much as you want for the entire package. No hidden
                      charges, no commissions, just a small and affordable
                      service charge is required.
                    </p>
                  </div>
                  <div className="btn-wrapper">
                    <Link
                      to="/service"
                      className="btn btn-white btn-effect-4 btn-full-width"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <br />
                <br />
                <br />
              </div>
              <div className="col-lg-4">
                <div
                  className="ltn__feature-item ltn__feature-item-5 bg-overlay-theme-90 text-color-white text-center"
                  data-bg="img/product/1.png"
                >
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="fas fa-route" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h2>
                      <Link to="/service" className="white-color-im">
                        Unique Destination Matching
                      </Link>
                    </h2>
                    <p style={{ paddingBottom: "58px" }}>
                      Who wants to be rejected? We certainly know how annoying
                      that can be, therefore HOLO tries best to match you with a
                      rider who is already willing to travel to the direction of
                      your intended location, and hence less chance of ride
                      rejection.
                    </p>
                  </div>
                  <div className="btn-wrapper">
                    <Link
                      to="/service"
                      className="btn btn-white btn-effect-3 btn-full-width"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
                <br />
                <br />
                <br />
              </div>
              <div className="col-lg-4">
                <div className="ltn__feature-item ltn__feature-item-5 white-bg text-center">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="far fa-handshake" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h2>
                      <Link to="/service">Direct Payment for the Riders</Link>
                    </h2>
                    <p>
                      We prioritize the need of receiving your payments
                      instantly. That being the case, the transaction of fare is
                      totally in between you and your ride partner, no third
                      party is involved at all. Receive the money directly from
                      your passenger in whichever method you prefer, cash or any
                      of your own mobile finance accounts.
                    </p>
                  </div>
                  <div className="btn-wrapper">
                    <Link
                      to="/service"
                      className="btn btn-white btn-effect-3 btn-full-width"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
            </div>
            <br />
            <br />
            <br />
            <div className="row justify-content-center">
              <div className="col-lg-4">
                <div className="ltn__feature-item ltn__feature-item-5 white-bg text-center">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="fas fa-money-bill-wave" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h2>
                      <Link to="/service">Pay as You Go</Link>
                    </h2>
                    <p style={{ paddingBottom: "174px" }}>
                      If you are planning to try this app as your go-to
                      motorbike ride share service providing method, you can
                      avail yourself of the opportunity in exchange for only BDT
                      10 as a service charge per ride, you heard that right, no
                      percent fee per ride is needed!
                    </p>
                  </div>

                  <div className="btn-wrapper">
                    <Link
                      to="/service"
                      className="btn btn-white btn-effect-3 btn-full-width"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>{" "}
                <br />
                <br />
                <br />
              </div>

              <div className="col-lg-4">
                <div className="ltn__feature-item ltn__feature-item-5 section-bg-2 text-center">
                  <div className="ltn__feature-icon">
                    <span>
                      <i className="fas fa-rocket" />
                    </span>
                  </div>
                  <div className="ltn__feature-info">
                    <h2>
                      <Link to="/service">Instant Ride</Link>
                    </h2>
                    <p>
                      Despite knowing the dangers of having no records of
                      contract trips, this method has been mainly practiced in
                      Bangladesh for its flexibility. For the first time in
                      Bangladesh, HOLO is digitizing this traditional method of
                      contract trips preserving all the necessary data of the
                      trips. Call a biker on the road as you always do, ask them
                      to use the app, and enjoy your trip with the full freedom
                      of a contract ride with an additional feeling of safety
                      and respect for the traffic laws.
                    </p>
                  </div>
                  <div className="btn-wrapper">
                    <Link
                      to="/service"
                      className="btn btn-white btn-effect-4 btn-full-width"
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__about-us-area pt-115 pb-95">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 align-self-center">
                <div className="about-us-info-wrap">
                  <div className="section-title-area ltn__section-title-2">
                    <h6 className="section-subtitle ltn__secondary-color">
                      About Us
                    </h6>
                    <h1 className="section-title">
                      Your Satisfaction Is Our First Priority<span>.</span>
                    </h1>
                    <p>
                      We're trying to solve the existing cultural problems of
                      our ride sharing industry.
                    </p>
                  </div>
                  <div className="about-us-info-wrap-inner about-us-info-devide">
                    <p>
                      A significant amount of rides get dropped due to reasons
                      like destination disagreements. We are here to ensure a
                      smooth journey to you where the ride won't get rejected!
                    </p>
                    <div className="list-item-with-icon">
                      <ul>
                        <li>
                          <Link to="/contact">24/7 Online Support</Link>
                        </li>
                        <li>
                          <Link to="/service">Easy To Use Apps</Link>
                        </li>
                        <li>
                          <Link to="/about">Find Ride On The Go</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 align-self-center">
                <img src="img/Holo-Logo-Black.png" alt="Icon" />
              </div>
              {/*<div className="col-lg-5 align-self-center">*/}
              {/*  <div className="get-a-quote-wrap">*/}
              {/*    <h2>Earn with us as an agent</h2>*/}
              {/*    <form action="#" className="get-a-quote-form">*/}
              {/*      <div className="input-item input-item-name ltn__custom-icon">*/}
              {/*        <input type="text" placeholder="Enter your name" />*/}
              {/*      </div>*/}
              {/*      <div className="input-item input-item-email ltn__custom-icon">*/}
              {/*        <input type="text" placeholder="Enter your address" />*/}
              {/*      </div>*/}

              {/*      <div className="btn-wrapper mt-0">*/}
              {/*        <button*/}
              {/*          type="submit"*/}
              {/*          className="btn theme-btn-1 btn-effect-1 text-uppercase"*/}
              {/*        >*/}
              {/*          Sign Up*/}
              {/*        </button>*/}
              {/*      </div>*/}
              {/*    </form>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>

        <div
          className="ltn__counterup-area  bg-overlay-theme-black-80 pt-115 pb-70"
          data-bg="img/bg/5.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="section-title-area ltn__section-title-2">
                  <h6 className="section-subtitle white-color">activity</h6>
                  <h1 className="section-title white-color">
                    It’s Our Journey<span>.</span>
                  </h1>
                </div>
              </div>
              {/*<div className="col-lg-2 col-md-3 col-sm-6 align-self-center">*/}
              {/*  <div className="ltn__counterup-item-3 text-color-white">*/}
              {/*    <h1>*/}
              {/*      <span className="counter">733</span>*/}
              {/*      <span className="counterUp-icon">+</span>*/}
              {/*    </h1>*/}
              {/*    <h6>Active Riders</h6>*/}
              {/*  </div>*/}
              {/*</div>*/}
              <div className="col-lg-2 col-md-3 col-sm-6 align-self-center">
                <div className="ltn__counterup-item-3 text-color-white">
                  <h1>
                    <span className="counter">{this.state.users}</span>
                    {/*<span className="counterUp-letter">K</span>*/}
                    {/*<span className="counterUp-icon">+</span>*/}
                  </h1>
                  <h6>Users</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-sm-6 align-self-center">
                <div className="ltn__counterup-item-3 text-color-white">
                  <h1>
                    <span className="counter">{this.state.riders}</span>
                    {/*<span className="counterUp-icon">+</span>*/}
                  </h1>
                  <h6>Completed Rides</h6>
                </div>
              </div>
              {/*<div className="col-lg-2 col-md-3 col-sm-6 align-self-center">*/}
              {/*  <div className="ltn__counterup-item-3 text-color-white">*/}
              {/*    <h1>*/}
              {/*      <span className="counter">21</span>*/}
              {/*      <span className="counterUp-icon">+</span>*/}
              {/*    </h1>*/}
              {/*    <h6>Districts</h6>*/}
              {/*  </div>*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        <div className="ltn__brand-logo-area ltn__brand-logo-1 pt-80 pb-110 plr--9">
          <div className="container-fluid">
            <h1 className="section-title" style={{ textAlign: "center" }}>
              Our Partners
            </h1>
            <br />
            <div className="row ltn__brand-logo-active">
              <div className="col-lg-12">
                <div className="ltn__brand-logo-item">
                  <a
                    href="https://www.sslwireless.com/"
                    title="SSL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="img/partners/ssl-wireless-logo.png"
                      alt="Brand Logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ margin: 50}}>
          <img src="img/footer/ssl1.jpg"/>
        </div>
        <FooterWhite />
        {/*/!* preloader area start *!/*/}
        {/*<div className="preloader" id="preloader">*/}
        {/*  <div className="preloader-inner">*/}
        {/*    <div className="spinner">*/}
        {/*      <div className="dot1" />*/}
        {/*      <div className="dot2" />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </>
    );
  }
}
export default Home;
