
import React, { Component } from "react";
import "./App.css";
import Home from "./pages/home";
import About from "./pages/about";
import News from "./pages/news";
import Contact from "./pages/contact";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Map from "./pages/map";
import Terms from "./pages/terms";
import TermsBangla from "./pages/termsBangla";
import Service from "./pages/service";
import Offer from "./pages/offer";
import More from "./pages/more";
import Faq from "./pages/faq";
import Instructions from "./pages/instructions";
import InstructionsBangla from "./pages/instructionsBangla";
import Privacy from "./pages/privacy";
import PrivacyBangla from "./pages/privacyBangla";
import Delete from "./pages/delete";


class App extends Component {


  render() {
    return (
      <div className="body-wrapper">
        <BrowserRouter>

              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route path="/about">
                  <About />
                </Route>
                <Route path="/news">
                  <News />
                </Route>
                <Route path="/contact">
                  <Contact />
                </Route>
                <Route path="/FAQ">
                  <Faq />
                </Route>
                <Route path="/instructions">
                  <Instructions />
                </Route>
                <Route path="/instructionsBangla">
                  <InstructionsBangla />
                </Route>
                <Route path="/locations">
                  <Map />
                </Route>
                <Route path="/terms">
                  <Terms/>
                </Route>
                <Route path="/termsBangla">
                  <TermsBangla/>
                </Route>
                <Route path="/privacy">
                  <Privacy/>
                </Route>
                <Route path="/privacyBangla">
                  <PrivacyBangla/>
                </Route>
                <Route path="/offers">
                  <Offer />
                </Route>
                <Route path="/service-area">
                  <Service />
                </Route>
                <Route path="/service">
                  <More />
                </Route>
                <Route path="/delete-account">
                  <Delete />
                </Route>
              </Switch>
            </BrowserRouter>
      </div>
    );
  }
}

export default App;
